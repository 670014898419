.accounts {
    .App {
        background-image: none;
    }
}

.account-full-part {
    float: left;
    width: 100%;

    .account-normal-part {
        max-width: 1500px;
        margin: 20px auto;

        .account-left-box {
            position: relative;
            top: -150px;
        }

        .tab-content {
            float: left;
            width: 80%;
            background: white;
            display: flex;
            height: 100%;
            border-radius: 10px;
            border: 1px solid #DFDFDF;
            padding: 20px;
            height: auto;
            min-height: 380px;
            @media @tablet, @laptopL {
                width: 65%;
            }
            .tab-pane {
                float: left;
                width: 100%;
            }

            h3 {
                font-size: 26px;
            }

            .login-input-box {
                div {
                    margin-bottom: 20px;
                }

                .password-input {
                    >div {
                        float: left;
                        width: auto;
                    }
                }

                .kampanya-checkbox {
                    display: flex;

                    input {
                        border-radius: 10px !important;
                        width: 30px;
                        height: 30px;
                        background-color: white !important;
                        border: 1px solid #707070 !important;

                        &:focus {
                            box-shadow: none !important;
                        }

                        &:checked {
                            background-color: #F6F6F6 !important;
                            border: 1px solid #F6F6F6 !important;
                            box-shadow: none !important;
                            background-blend-mode: difference;
                        }
                    }

                    button {
                        margin-bottom: 20px;
                    }
                }

                input {
                    width: 100%;
                    padding: 5px;
                }
            }

            form {
                a {
                    float: left;
                    width: 100%;
                    text-align: right;
                }

                .sginup-camps {
                    background: none;
                    width: auto;
                    float: left;
                    color: black;
                    display: block;
                    border: none;
                    padding: 0;
                    padding-left: 10px;
                    height: auto;

                    &:hover,
                    &:focus,
                    &:focus-visible {
                        border: none;

                    }
                }

                button {
                    background: @yesil;
                    border-color: @yesil;
                    width: 50%;
                }
            }


        }

        .user-info-grid-part {
            position: relative;
            top: 160px;
            width: calc(20% - 20px);
            padding: 26px;
            padding-bottom: 0;
            overflow: hidden;
            @media @tablet, @laptopL {
                width: calc(35% - 20px);
            }
            @media @laptop1280 {
                top: 190px;
            }

            h3 {
                font-size: 18px;
                font-weight: 500;
            }

            p {
                font-size: 13px;
                color: #808080;
            }

            button {
                background: #808080;
                border-color: #808080;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .contact-tabs-box {
            margin-bottom: 0 !important;
            float: left;
            width: calc(20% - 20px);
            margin-right: 20px;
            border-radius: 10px;
            background: white;
            padding: 10px;
            border: 1px solid #DFDFDF;
            flex-direction: column;
            @media @tablet, @laptopL {
                width: calc(35% - 20px);
            }
            li {
                float: left;
                padding-bottom: 5px;
                width: 100%;
                border-bottom: 1px solid #efefef;

                &:first-child {
                    margin-top: 160px;

                    @media @laptop1280 {
                        margin-top: 190px;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            button {
                color: #9c9c9c;
                border: none;

            }

            button[aria-selected="true"] {
                color: black;
                border: none;
                display: flex;
                float: left;
                justify-content: space-between;
                width: 100%;

                &:after {
                    background-image: url('../../images//acordionIconYesil.svg');
                    width: 36px;
                    height: 25px;
                    background-size: contain;
                    content: '';
                    background-repeat: no-repeat;
                    background-position: right;
                }

                &:focus {
                    box-shadow: none;
                }

                &:focus-visible {
                    box-shadow: none;
                }
            }
        }

        .order-acordion-container {
            .accordion-header {
                button {
                    flex-direction: column;

                    &:after {
                        content: none;
                    }

                    &:before {
                        flex-shrink: 0;
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-left: auto;
                        content: '';
                        background-image: url('../../images//acordionIcon.svg');
                        background-repeat: no-repeat;
                        background-size: 1.25rem;
                        transition: transform 0.2s ease-in-out;
                    }

                    .order-status-box {
                        display: flex;
                        justify-content: space-between;
                        float: left;
                        width: 100%;

                        .order-image-box {
                            flex-grow: 1;
                            max-width: 220px;
                            overflow: hidden;

                            ul {
                                display: flex;
                                padding: 0;

                                li {
                                    list-style: none;
                                    padding-left: 10px;

                                    &:nth-child(n) {
                                        margin-left: -15%;
                                    }

                                    &:first-child {
                                        margin-left: 0;
                                        padding-left: 0;
                                        display: flex;
                                    }

                                    img {
                                        height: 64px;
                                        width: 64px;
                                        border-radius: 100px;
                                    }


                                }
                            }
                        }

                        .order-number,
                        .order-date,
                        .order-state {
                            span {
                                font-size: 14px;

                                &:first-child {
                                    font-size: 16px;
                                    color: #666666;
                                    padding-bottom: 10px;
                                }

                                float: left;
                                width: 100%;
                            }
                        }

                        .order-state {
                            .span {

                                color: #1B971C;
                            }

                            .siparis-saati {
                                color: black;
                            }

                            .iptal-edildi {
                                color: #ca0000;
                            }

                        }
                    }

                    .order-button-box {
                        float: left;
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        button {
                            margin-right: 10px;
                            background: #F8F7F7;
                            border-color: #E3E3E3;
                            color: #5D5D5D;
                            font-size: 13px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &:hover,
                            &:focus {
                                border-color: #1B971C;
                                color: #1B971C;
                            }
                        }
                    }
                }

                button[aria-expanded="true"] {
                    background: white;
                    border-color: white;

                    &:focus {
                        background: white;
                        color: black;
                        border-color: white;
                    }

                    &:focus-visible {
                        border-color: white;
                    }

                    span {
                        color: black;
                    }

                    &::before {
                        -ms-transform: rotate(90deg);
                        /* IE 9 */
                        transform: rotate(90deg);
                    }
                }
            }

            .accordion-body {
                .order-product-box {
                    float: left;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    border-bottom: 1px solid gainsboro;
                    padding: 20px 0;
                    align-items: center;

                    @media @small {
                        flex-direction: column;
                    }

                    &:first-child {
                        margin-top: 20px;
                    }

                    .order-product-image {
                        flex-basis: 30%;

                        img {
                            height: 100px;
                            width: auto;
                            border-radius: 10px;

                        }
                    }

                    .indirimli-fiyat {
                        flex-basis: 30%;
                        text-decoration: line-through;
                        color: #707070;
                        text-align: center;
                    }

                    .product-code {
                        flex-basis: 35%;

                        @media @small {
                            text-align: center;
                        }

                        span {
                            &:first-child {
                                color: #666666;
                            }

                            float: left;
                            width: 100%;
                        }
                    }

                    .oder-product-price {
                        text-align: center;
                        flex-basis: 35%;

                        span {
                            &:first-child {
                                float: left;
                                width: 100%;
                                color: @yesil;
                                font-size: 18px;
                            }

                            &:last-child {
                                font-size: 12px;
                                color: #b1b1b1;
                            }
                        }
                    }
                }

                .order-address {
                    float: left;
                    width: 100%;

                    @media @small {
                        display: flex;
                        flex-direction: column-reverse;
                    }

                    .adresler {
                        float: left;
                        width: 50%;
                        font-style: normal;
                        line-height: 19px;
                        word-break: break-word;
                        color: #484848;
                        font-size: 14px;

                        @media @small {
                            width: 100%;
                        }

                        h4 {
                            color: #484848;
                            font-size: 20px;
                            font-weight: 600;
                            margin-bottom: 24px;
                            margin-top: 0;
                        }

                        .teslimat-adresi {
                            float: left;
                            width: 100%;
                            margin-bottom: 20px;
                            font-size: 12px;
                        }

                        .fatura-adresi {
                            float: left;
                            font-size: 12px;
                            width: 100%;
                        }
                    }

                    .odeme-bilgileri {
                        float: left;
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        @media @small {
                            width: 100%;
                        }

                        h4 {
                            color: #484848;
                            font-size: 20px;
                            font-weight: 600;
                            margin-bottom: 24px;
                            margin-top: 0;
                            width: 70%;
                            text-align: left;
                        }

                        ul {
                            width: 70%;
                            background: #EFEFEF;
                            padding: 20px;
                            border-radius: 10px;

                            @media @small {
                                width: 100%;
                            }

                            li {
                                list-style: none;
                                display: flex;
                                justify-content: space-between;
                                font-size: 15px;
                                color: #4A4A4A;

                                &:last-child {
                                    padding-top: 10px;
                                    margin-top: 10px;
                                    border-top: 1px solid gainsboro;

                                    span {
                                        &:last-child {
                                            font-weight: 500;
                                        }

                                    }
                                }

                                .kargo-indirim {
                                    text-decoration: line-through;
                                }

                                .siparis-indirimi {
                                    color: @yesil;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .address-list-box {
            .address-list {
                background: white;
                border: 1px solid #efefef;
                margin: 20px 0;
                border-radius: 10px;
                box-shadow: 0 0 3px #efefef;
                display: flex;
                align-items: center;
                min-height: 120px;
                justify-content: space-between;

                .location-box {
                    float: left;
                    width: 10%;
                    padding-left: 10px;
                }

                .address-box {
                    float: left;
                    width: 90%;
                    padding-left: 1rem;

                    p {
                        margin-bottom: 0;

                        &:first-child {
                            color: #7B7B7B;
                        }

                        &:last-child {
                            font-size: 14px;
                        }
                    }

                }

                h4 {
                    color: #484848;
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    margin-top: 0;
                }

                .button-box {
                    display: flex;

                    button {
                        margin-right: 20px;
                        background: #F2F2F2;
                        border-color: #F2F2F2;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .no-favorite {
            float: left;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .favorite-product {
            float: left;
            width: 100%;

            a {
                float: left;
                width: calc(33.33% - 15px);
                margin-right: 10px;
                color: black;
                text-decoration: none;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    max-height: 320px;
                    border-radius: 10px 10px 0 0;
                    border: 1px solid gainsboro;
                }

                h4 {
                    color: #484848;
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 24px;
                    margin-top: 0;
                }

                .price-and-delete-button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;

                    p {
                        margin-bottom: 0;
                    }

                    button {
                        background: #F6F6F6;
                        border-color: #F6F6F6;
                    }

                }
            }
        }

        .new-address-button {
            float: left;
            width: 100%;
            padding: 5px 5px;
            margin-bottom: 20x;

            button {
                float: right;
                box-shadow: none;
                background: @yesil;
                border: @yesil;
            }
        }
    }
}

.address-edit-input-box {
    @media @small {
        padding: 10px;
    }

    .address-edit-input {
        margin-bottom: 20px;
        width: 33.333%;
        float: left;

        @media @small,
        @small-big {
            width: 50%;
            margin-bottom: 10px;
            padding: 5px;
        }

        @media @small {
            width: 100%;
        }

        &:nth-child(2) {
            width: 50%;

            @media @small {
                width: 100%;
            }
        }

        &:nth-child(7) {
            width: 33%;

            @media @small,
            @small-big {
                width: 50%;
            }

            @media @small {
                width: 100%;
            }
        }

        &:nth-child(8) {
            width: 100%;
        }

        input {
            width: 100%;
            padding: 10px;
            float: left;

        }

        select {
            width: 100%;
            padding: 10px;
        }
    }

    .address-edit-input.ceptel {
        width: 33%;

        @media @small,
        @small-big {
            width: 50%;
        }

        @media @small {
            width: 100%;
        }

        input {
            background-image: url('../../images/trFlag.svg');
            background-repeat: no-repeat;
            background-size: 30px;
            background-position: left;
            background-origin: content-box;
            text-indent: 40px;
        }
    }

    .kurumsal-address {
        .address-edit-input {
            width: calc(50% - 20px);
            margin-right: 20px;

            @media @small {
                width: 100%;
                margin: 0;
            }
        }
    }


}

.address-edit-input-checkbox {
    >div {
        float: left;
        min-width: 200px;
        padding: 20px;
        padding-top: 0;
        padding-left: 0;
        display: flex;
        align-items: center;

        @media @small {
            padding-bottom: 0;
        }

        input {
            border-radius: 20px !important;
            width: 30px;
            height: 30px;
            background-color: white !important;
            border: 1px solid #707070 !important;

            &:focus {
                box-shadow: none !important;
            }

            &:checked {
                background-color: @yesil !important;
                border: 1px solid @yesil !important;
                box-shadow: none !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
            }
        }

        label {
            height: 40px;
            padding-left: 10px;
            display: flex;
            align-items: center;
            color: #5B5B5B !important;
        }
    }

}

.address-edit-input-addrescheckbox {

    >div {
        float: left;
        min-width: 200px;
        padding: 20px;
        padding-left: 0;
        padding-top: 0;
        display: flex;

        input {
            border-radius: 10px !important;
            width: 30px;
            height: 30px;
            background-color: white !important;
            border: 1px solid #707070 !important;

            @media @small {
                width: 100px;
            }

            &:focus {
                box-shadow: none !important;
            }

            &:checked {
                background-color: #F6F6F6 !important;
                border: 1px solid #F6F6F6 !important;
                box-shadow: none !important;
                background-blend-mode: difference;
            }
        }

        .form-check-label {
            color: #666666 !important;
            font-weight: 600;
            font-size: 16px;

        }

        label {
            color: #666666;
            margin-bottom: 0;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
    }

}

.toast {
    position: absolute;
    top: 150px;
    right: 0;

    .toast-header {
        justify-content: space-between;

        small {
            color: #e23e41;
        }
    }
}

.form-title {
    color: #243181;
    font-weight: 600;
}

.modal-body {
    .cargo-info {
        display: flex;
        justify-content: space-between;
        background: #e0ffe5;
        padding: 10px;
        border-radius: 10px;
        align-items: center;
        margin-bottom: 20px;

        span {
            color: #777777;

            &:last-child {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: #777777;
            }
        }
    }

    .cargo-hareketleri {
        ul {

            li {
                list-style: none;
                display: flex;
                text-align: left;
                justify-content: flex-end;
                padding: 3px;

                &:nth-child(2n) {
                    background: #ececec;
                }

                span {
                    width: 50%;

                    &:nth-child(2n) {
                        text-align: justify;
                        padding-left: 80px;
                    }
                }
            }
        }
    }
}

.modal-dialog-centered {
    form {
        button {
            float: right;
            margin-top: 10px;
            width: 25%;
            background: @yesil;
            border-color: @yesil;

            &:focus {
                box-shadow: none;
            }
        }
    }

}

.comments-input-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .sipariskalemleri-edit-input {
        margin-top: 10px;

        select {
            width: 100%;
            height: 40px;
            padding: 10px;
        }

    }
    textarea.form-control{

        margin: 0px !important;
   }
}


#bank-data-content {
    iframe {
        width: 100%;
        height: auto;
        min-height: 500px;

        @media @small,
        @small-big {
            min-width: fit-content;
        }
    }
}

.delete-address-modal {
    .modal-dialog {
        max-width: 320px !important;

    }
}

.login-failed {
    li {
        float: left;
        width: calc(50% - 10px);
        margin: 5px;
        text-align: center;
        border-radius: 10px;

        &:first-child {
            display: none;
        }

        &:last-child {
            //margin-right: 0;
            position: absolute;
            bottom: -270px !important;
            right: 0;
            width: auto;

            @media @small {
                bottom: -250px !important;
            }
        }
    }
}

#login-or-sginup-tab-uyeliksiz {
    background: #ffffff;
    border: none;
}

.pointBox {
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #f7f4f4;
        background-clip: border-box;
        --mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50%/30.00px 100%;
        -webkit-mask: var(--mask);
        mask: var(--mask);
        padding: 15px 0px;
        border: none;
        margin: 1rem auto;
    }
}