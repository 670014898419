.dropdown-menu .popover-body {
    width: 300px !important; 
}
.dropdown-menu .popover-body ul li {  
    border-bottom: 1px solid #ecdec9;
    width: 100% !important;
    margin-bottom: 5px;
    list-style: none;
    margin-bottom: 10px;
    float: left;
    padding-bottom: 5px !important;
    padding-left: 13px !important;
}

.header-full-part {
    float: left;
    .header-normal-part {
        max-width: 1500px;
        width: 95%;
        height: 130px;
        
        @media @small,@small-big {
            display: none;
        }
        @media @tablet,@laptopL,@big-definition-monitor{
            max-width: unset;
            height: auto;
        }
        .row {
            justify-content: flex-end;
        }
    }
   
    .header-category-list {
        float: right;
        width: 100%;
        z-index: 999;
        
        .row {
            justify-content: space-between;
            @media @laptop1366 {
                position: relative;
            }
        }
        .logo-part {
            float: left;
            position: relative;
            bottom: 55px;
            // width: auto;
            padding: 0;
           
            @media @laptop1920  {
                position: absolute;
                top: 10px;
                height: 120px;
            }
            @media @laptop1366 { 
                top: -45px; 
                position: absolute; 
                height: 120px;
            }
            @media @laptop1366 {
                left: 0;
            }
            @media @laptop1280 {
                width: 10%;
                padding: 5px;
                top:-65px;
            }
            @media @tablet {
                width: 10%;
                position: absolute;
                top: 10px;
                left: 15px;
                height: 100px;
            }
            @media @laptopL  {
                position: absolute;
                top: 10px !important;
                height: 120px;
            }
            img {
                max-width: 95%;
            }
        }
        .menu-part {
            float: right;
            width: auto;
            @media @laptop1280,@laptopL {
                width: 90%;
                display: flex;

            }
            @media @laptop1920 {
                float: right;
                width: calc(100% - 140px);
                margin-left: 140px;
            }
            
            @media @tablet {
                width: 100%;
                font-size: 10px;
            }
            @media @tablet,@laptopL{
                width: 100%;
                margin-top: 40px;
            }
            @media @laptop1366 {
                position: absolute;
                right: 0;
                padding: 0;
            }
            a {
                @media @laptop1280 {
                    font-size: 12px;
                }
                @media @laptopL {
                    font-size: 10px !important;
                }
                @media @laptop1366 {
                    font-size: 11px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1199px) {
                    font-size: 11px;
                }
                
            }
        }
        ul {
            float: right;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 0;
            @media @tablet {
                width: 90%;
            }
            @media @tablet,@laptopL{
                width: 100%;
                margin-bottom: 10px;
             
            }
            li{
                list-style: none;
                padding-right: 10px;
                float: left;
                display: flex;
                align-items: center;
                font-size: 12px;
                width: auto;

                @media @laptop1366 {
                    padding: 0;
                    overflow: hidden;
                }
                @media @laptop1280,@laptopL {
                    padding: 0;
                     font-size: 12px;
                     height: auto;
                }
                @media @tablet {
                    padding: 0;
                    font-size: 10px;
                }

              
            }
            .hesabim-li , .sepatim-li{
                padding-right: 0;
                font-size: 12px;
                img{
                    @media @laptop1280,@laptopL {
                        
                     }
                     @media @tablet {
                        width: 28px;
                     }
                    
                    @media @laptop1366 {
                        width: 28px;
                    }
                    @media only screen and (min-width: 992px) and (max-width: 1199px) {
                        width: 28px;
                    }
                }
       
            }
            .sepatim-li {
                @media @laptop1280,@laptopL {
                    
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }
            a {
                color: black;
                text-decoration: none;
                font-weight: bold;
                padding: 0 5px;
                &:hover {
                    color: @yesil !important;
                }
                @media @laptop1280,@laptopL{
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                @media @tablet {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                @media @laptop1920 {
                    font-size: 12px;
    
                }
            }
        
        }
      
        .header-name-box{
            float: left;
            padding-left: 10px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            @media @laptop1280,@laptopL,@tablet {
                height: auto;
                padding: 0;
                text-align: center;
                justify-content: flex-start;
                min-height: 40px;
             }
             @media @tablet {
                height: auto;
                padding: 0;
             }
             button {
                background: no-repeat;
                border: none;
                color: black;
                font-size: 12px;
                padding-left: initial;
                font-weight: 700;
            }
             button {
                @media @laptop1280,@laptopL {
                    padding: 0;
                    font-size: 12px;
                 }
                 @media @tablet {
                    padding: 0;
                    font-size: 12px;
                 }
                
                @media @laptop1366 {
                    font-size: 11px;
                }
                @media @laptopL {
                    font-size: 10px;
                }
             }
            p {
                margin: 0;
            }
            .uye-ol-span {
                @media @laptop1280,@tablet{
                    font-size: 12px;
                }
                @media @laptopL {
                    font-size: 10px;
                }
            }
           

        }
        .header-icon-box{
            float: left;
            height: 80px;
            display: flex;
            padding: 0;
            align-items: center;
            @media @laptop1280,@laptopL {
                height: auto;
                position: relative;

             }
             @media @tablet {
                height: auto;
             }
             img {
                 @media @laptop1280,@laptopL{
                     height: 30px;
                  }
                  @media @tablet {
                    height: 20px;
                 }
             }
            .basket-total-items {
                position: relative;
                background: #E23F38;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                text-align: center;
                left: 50px;
                bottom: 13px;
                color: white;
                font-size: 13px;
                @media @laptop1280,@laptopL {
                    left: 20px;
                    position: absolute;
                 }
                 @media @tablet ,@small, @small-big 
                {
                    width: 14px;
                    height: 16px;
                    left: 30px;
                    bottom: 6px;
                    font-size: 10px;
                 }
                 
            }
        }
    }
}

.header-top-full-part {
    float: left;
    width: 100%;
    border-bottom: 1px solid gainsboro;

    .header-top-normal-part {
        max-width: 1500px;
        @media @big-definition-monitor {
            max-width: fit-content;
            width: 95%;
        }
        .header-grid-part {
            float: left;
            width: 100%;
            margin-top: 20px;
            ul {
                float: right;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                li{
                    list-style: none;
                    padding: 0 10px;
                    float: left;
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    width: auto;
                    @media @tablet {
                        padding: 0;
                        font-size: 13px;
                    }
                }
                a {
                    color: #222222 !important;
                    text-decoration: none;
                    padding: 0 5px;
                    font-family: PaulSlab-bold;
                    @media @laptop1366 {
                        font-size: 12px;
                    }
                    @media only screen and (min-width: 992px) and (max-width: 1199px) {
                        font-size: 12px;
                    }
                }
                a.satist{
                    color: #de1f19 !important;
                    font-weight: bold;
                }
                .tel-box {
                    border: 1px solid black;
                    border-radius: 10px;
              
                    padding: 0 5px;
                    height: 30px;
                    .tel-text-box {
                        height: 25px;
                        padding: 0 5px;
                        border-right: 1px solid black;
                        float: left;
                        font-family: PaulSlab-Regular;

                        img {
                            padding: 0 5px;
                            @media @laptop1366 {
                                padding: 7px 5px;
    width: 22px;
                            }
                            @media only screen and (min-width: 992px) and (max-width: 1199px) {
                                padding: 7px 5px;
                                width: 22px;
                            }
                        }
                        span {
                            height: 25px;
    
                        }
                    }
                }
            }
           
        }
        .header-grid-part-30 {
            position: relative;
            float: right;
            width: auto;
            @media only screen and (max-width: 1199px) {
                display: none !important;
            }
            .input-group-text { 
                    font-size: 0.9rem; 
                    background-color: #fdfdfd;
                    border: 1px solid #eeeeee; 
                 
            }
            .search-result {
                display: none;
                position: absolute;
                top: 35px;
                left: 0px;
                width: 100%;
                background-color: white;
                border: 1px solid gainsboro;
                border-radius: 10px;
                padding: 10px;
                max-height: 300px;
                overflow-y: auto;
            } 
        }
        .header-grid-part-70 {
            position: relative;
            float: right;
            width: auto;
            .header-grid-part ul a { 
                @media @laptop1366 {
                    font-size: 0.8rem;
                } 
                @media @laptop1366 {
                    font-size: 0.8rem;
                }
                @media @laptopL {
                    font-size: 0.8rem;
                }
                @media @laptop1280 {
                    font-size: 0.8rem;
                }
                @media @tablet,@small,@small-big {
                    font-size: 0.8rem;
                }
            }
        }
    }
}
.popover {
    border-color: gainsboro !important;
    max-width: 450px !important;
    .popover-header {
        background:none !important;
        border-color: gainsboro !important;
       
    }
    .popover-body {
        padding: 10px;
        float: left;
        width: 100%;
        .cart-product-list-box {
            max-height: 300px;
            overflow: auto;
        }
        .cart-product-box {
            float: left;
            width: 100%;
            display: flex;
            margin-bottom: 20px;
            justify-content: space-between;
            border-bottom: 1px solid gainsboro;
            padding-bottom: 10px;

            &:nth-child(2n) {
                border-bottom: 0;
            }
            .product-image-box {     
                img {
                    height: 64px;
                    border-radius: 10px;
                }
            }
            .product-unit-dec {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                button {
                    background: none;
                    border: none;
                    img {
                        height: 20px;
                    }
                }
                .numbers-row {
                    background: #F9F6F5;
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 10px;
                    .dec {
                      float: left;
                      width: 30%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border: none;
                      border-right: 1px solid #f3f3f3;
                      height: 50px;
                      background: #F9F6F5;
                      border-radius: 10px 0 0 10px;
                    }
                    .inc {
                      float: left;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 30%;
                      border: none;
                      border-left: 1px solid #f3f3f3;
                      height: 50px;
                      background: #F9F6F5;
                      border-radius: 0 10px 10px 0px;
                    }
                    span {
                      color: #343132;
                      text-align: center;
                    }
                    .input-are{
                      max-width:40%;
                      float: left;
                      height: 40px;
                      .productQuantityTextBox{
                        float: left;
                        width: 100%;
                        height: 26px;
                        background: #F9F6F5;
                        text-align: center;
                        border: none;
                      }
                      span {
                        float: left;
                        width: 100%;
                        text-align: center;
                      }
                    }
                  }
            }
            .produt-desc-price-box {
                padding: 0 10px;
                h6 {
                    margin-bottom: 0;
                }
                span {
                    float: left;
                    width: 100%;
                    font-size: 13px;
                    color: #A0A0A0;
                    &:last-child {
                        color: @yesil;
                        font-weight: 600;
                        font-size: 16px;
                    }
                   
                }
            }
        }
        .cargo-and-discount-box {
            float: left;
            width: 100%;
            margin: 10px 0;
            border-top: 1px solid gainsboro;
            border-bottom: 1px solid gainsboro;
            .span-box {
                display:flex;
                padding: 5px 0;
                font-size: 16px;
                justify-content: space-between;
                float: left;
                width: 100%;
            }
        }
        .cart-button-box {
            float: left;
            width: 100%;
         
            span {
                font-size: 20px;
                color: @yesil;
                &:first-child {
                    display: flex;
                    justify-content: space-between;
                }
            }
            p {
                margin: 10px 0;
                font-size: 16px;
            }
            .button-boc {
                display: flex;
                justify-content: space-between;
                padding: 10px 0;
                a {
                    color: white !important;
                    text-decoration: none;
                    width: 40%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 17px;
                    border-radius: 10px;
                    &:first-child {
                        background: #313131;
                        
                    }
                    &:last-child {
                        background-color: #23BE72;
                    }
                }
            }
        }
    }
}
.search-result {
   padding: 20px;
   background: white;
   float: left;
   width: 98%;
    min-height: 250px;
    position: relative;
    z-index: 999999;
    left: 3px;
    max-height: 427px;
    overflow: auto;
    box-shadow: 0px 0px 8px 2px #e6e6e6;
    overflow-y: scroll;
    top:-10px;
   .product-list {
       width: calc(50% - 5px);
       float: left;
       margin-right: 10px;
       box-shadow: 0 0 3px gainsboro;
       margin-bottom: 20px;
       border-radius: 10px;
       z-index: 999999;
       &:nth-child(2n) {
           margin-right: 0;
       }
       h6, p{
           margin-bottom: 0;
           padding: 5px 10px;
           
       }
       h6 {
           font-size: 14px;
           min-height: 50px;
           max-height: 50px;
           overflow: hidden;
       }
       p{
           color: @yesil;
           font-weight: 600;
       }
       img {
           border-radius: 10px 10px 0 0;
           width: 100%;
           height: 90px;
           object-fit: contain;
       }
       a {
           text-decoration: none;
           float: left;
           width: 100%;
       }
   }
}
.mobile-menu-full-part {

    .header-mobile-top-part{
        display: flex;
        justify-content: space-between;
        
        a {
            z-index: 9999;

            text-decoration: none;
            font-size: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            &:first-child{
                margin-top: 0;
            }
            span {
                &.login-span {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        a {
            img {
                height: 80px;
                margin-top: 10px;
            }
        }
    }
    .basket-total-items {
        position: absolute;
        background: #E23F38;
        border-radius: 49%;
        text-align: center;
        color: white;
        width: 18px;
        height: 20px;
        top: 10px;
        font-size: 13px;
        right: 8px;
 
    }
    .search-menu-part {
        display: flex;
        margin: 10px 0;
        z-index: 9;
        .mobile-menu-button {
            background: white;
            color: black;
            border-color: #979797;
            min-width: 30%;
            padding: 5px;
            display: flex;
            align-items: center;
            margin-right: 10px;
            border-radius: 7px;
            color: #99999E;
            font-size: 12px;
            &:focus {
                box-shadow: none;
                background: white;
                border-color: #979797;
                color: black;
            }
            svg{
                margin-right: 10px;
            }
        }
        .input-group {
            border-radius: 7px;
           input {
                background-color: white !important;
                border-radius: 7px;
                font-size: 13px !important;
                &:focus {
                    
                }
           } 
           span {
               background: #E8E8E8;
               color: #99999E;
               border: none;
           }
        }
    }

}
.offcanvas-start {
    width: 85% !important;
    height: 100vh; 
    .offcanvas-body {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        ul {
            padding: 0;
            a {
                text-decoration: none;
            }
            button {
                background: none;
                color: black;
                border: none;
                 padding:0;
                 width: 100%;
                 text-align: left;
                 &:focus {
                     box-shadow: none;
                 }
            }
            li {
                list-style: none;
                display: flex;
                justify-content: space-between;
                line-height: 45px;
                border-bottom: 1px solid gainsboro;
                margin-bottom: 10px;
                align-items: center;
                padding-bottom: 5px;
                font-size: 15px;
              
                &:after {
                    content: '';
                    background-image: url('../../images//acordionIcon.svg');
                    background-size: 32px 32px;
                    width: 32px;
                    height: 32px;
                }
              
                &.active {
                    button {
                        color: #9e9e9e;
                    }
                    > div {
                        padding-left: 10px;
                        float: left;
                        width: 100%;
                        margin-top: 10px;
                    }
                
                }
           
            }
            .tumunu-incele {
                background: #ececec;
                padding: 5px 20px;
                border-radius: 5px;
                text-align: left;
                float: left;
                width: 100%;
                &:hover {
                    p {
                        padding-right: 25px;
    
                    }
                }
                p {
                    margin-bottom: 0;
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    transition: 0.5s;
                    &:after { 
                        content: '\00bb';
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        right: -20px;
                        transition: 0.5s;
                    }
                }
            }
        }
        .mobile-phone {
            display: flex;
            justify-content: space-between;
            background: #f1f1f1;
            padding: 15px;
            margin-top: auto;
            margin-bottom: 60px;
            border-radius: 10px;
            a {
                text-decoration: none;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 15px;
                }
            }
        }
        .login-and-order-grid {
            display: flex;
            justify-content: space-between;
            background: #f3f3f3;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            a {
                text-decoration: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
            }
            svg {
                width: 32px;
                height: 32px;
                circle ,ellipse{
                    fill: #b3b3b3;
                }
            }
            span {
                text-align: center;

            }
            .header-name-box {
                p {
                    margin-bottom: 0;
                    text-align: center;
                }
                span {
                    font-size: 14px;
                    color: #3e3e3e;
                }
            }
        }
    }

}
.modal-backdrop.show{
    opacity: 0;
}
.offcanvas.show {
    transform: none;
    z-index: 999999;
}
.offcanvas-header{
    background: #fbfbfb;
    padding: 10px;
    a {
        img {
            height: 70px;
        }
    }
}

.dropdown-menu {
    max-width: 501px !important;
    .popover-body {
        width: 500px;
        background: white;
        ul {
            float: left;
            width: 100%;
            padding: 0;
        
            li {
                list-style: none;
                margin-bottom: 10px;
                float: left;
                width: 50%;
                img {
                    height: 150px;
                    border-radius: 10px;
                }
                a {
                    float: left;
                    width: 100%;
                    text-decoration: none;
                    padding-top: 5px;
                }
            }
        }
        .tumunu-incele {
            background: #ececec;
            padding: 10px 30px;
            border-radius: 5px;
            text-align: left;
            float: left;
            &:hover {
                p {
                    padding-right: 25px;

                }
            }
            p {
                margin-bottom: 0;
                cursor: pointer;
                display: inline-block;
                position: relative;
                transition: 0.5s;
                &:after { 
                    content: '\00bb';
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    right: -20px;
                    transition: 0.5s;
                }
            }
        }
       
    }
}
.popover-ara-toplam {
    display: flex;
    justify-content: space-between;
    float: left;
    width: 100%;
    span {
        font-size: 16px;
        &:last-child {
            color: @yesil;
            font-weight: 600;
        }
    }
}
.cargo-mesaj {
    color: #e34541;
    font-weight: 500;
}