.checkout {
    .App {
        background-image: none;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    .globalClass_5c43, jdiv {
        display: none !important;
    }
    &.uyelik, &.login, &.forgot-password, &.sginup{
        // background-image: url('../../images/loginbg.jpg');
        // background-repeat: no-repeat;
        // background-size: cover;
        .checkout-header-part {
             .tab-content {
                 background: none !important;
                 .tab-content {
                     background: white !important;
                 }
            }
        }
    }

    &.modal-open {
        .modal {
            // display: flex !important;
            // align-items: center;
            .modal-header {
                button {
                    margin-left: 25px;
                }
            }
            .modal-footer {
                button {
                    width: 35%;
                }
            }
        }
        .modal-body {
            .address-edit-input-box {
                .address-edit-input {
                    margin-bottom: 20px;
                    width: 33.333%;
                    float: left;
                   @media @small,@small-big {
                       width: 50%;
                       margin-bottom: 10px;
                       padding: 5px;
                   }
                    &:nth-child(2) ,&:nth-child(3){
                        width: 50%;
                        
                    }
                    &:nth-child(7) {
                        width: 33%;
                        @media @small,@small-big {
                            width: 50%;
                        }
                    }
                    &:nth-child(8) {
                        width: 100%;
                    }
                }
                .kurumsal-address{
                    .address-edit-input {
                        width: calc(50% - 10px);
                        margin-right: 20px;
                        @media @small {
                            width: 100%;
                            margin: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                textarea.form-control{

                        margin: 0px !important;
                }
            
            
            }
            button {
                @media @small,@small-big {
                    width: 100% !important;
                }
            } 
        }
    }
    .tab-container-part {
        background: white;
        border-radius: 8px;
        width: 42% !important;
        @media @small,@small-big {
            width: 100% !important;
            padding: 0 !important;
        }
        button.nav-link {
            margin: 0 auto !important;
        }
        .tab-content {
            button {
                margin: 20px 0 !important;
            }
            button.sginup-camps
            { 
                    margin: 0px !important; 
            }
        }
    }
    .login-tab-container {
        width: 90% !important;
        margin:  0 auto;
        border-bottom: none;
     
        li {
           
            &:nth-child(2) {
                background-color: #e5e5e5 !important;
                margin: 5px 0 !important;
                width: 50% !important;
                border-radius: 8px 0 0 8px!important;
                padding: 3px;
            }
            &:nth-child(3) {
                background-color: #e5e5e5 !important;
                margin: 5px 0 !important;
                width: 50% !important;
                border-radius: 0 8px 8px 0!important;
                padding: 3px;
            }
            &:first-child {
                display: block !important;
                float: left;
                padding: 0;
                margin: 0;
                width: 100% !important;
                span {
                    color: #E13F3F;
                }
                button {
                    background: white !important;
                    border-radius: 0;
                }
            }
            button {
                background: none !important;
                border-color: #e5e5e5 !important;
                border-radius: 8px;
                color: #444444 !important;
                &.active {
                    background: white !important;
                    color: #444444 !important;
                }
            }
        }
    }

    .checkout-full-part {
        .checkout-normal-part{
            max-width: 1500px;
            .checkout-header-part {
               float: left;
               width: 100%;
               a {
                float: left;
                margin-top: 20px;
                    @media @small {
                        text-align: center;
                        float: left;
                        width: 100%; 
                    }
                    img {
                        height: 110px;
                        @media @small {
                            height: 105px;
                            padding: 10px;
                        }
                    }
               }
              
                .wizard-nav-part {
                    align-items: center;
                    float: left;
                    height: 140px;
                    border-bottom: 0;
                    padding-left: 25px;
                    @media @small,@small-big {
                        height: auto;
                    }
                    @media @small,@small-big{
                        width: 100%;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        overflow: scroll;
                        overflow-y: hidden;
                        border-radius: 10px 10px 0 0;
                    }
                    .nav-item{
                        @media @small,@small-big{
                            min-width: fit-content;
                            height: 41px;
                        }
                        
                    }
                    .nav-item {
                        flex-basis: auto;
                        padding-left: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media @small,@small-big {
                            padding-left: 5px;
                        }
                        button {
                            background: none;
                            display: flex;
                            align-items: center;
                            padding: 0;
                            color: black;
                            background: none;
                           
                            &:focus {
                                background: none;
                                border: none;
                            }
                            &:after {
                                content: '';
                                background-image: url('../../images/arrow-right.svg');
                                width: 5px;
                                height: 10px;
                                float: right;
                                margin-left: 20px;
                            } 
                           
                        }
                        .nav-link.active {
                            background:none;
                            border: none;
                            color: red;
                        }
                        &:last-child {
                            button {
                                &:after {
                                    content: none;
                                }
                            }
                        }
                    }
                   
                }
                // .siparis-ozeti {
                //     position: absolute;
                //     background: white !important;
                //     height: auto !important;
                //     right: -360px;
                //     top: 0;
                    
                //     @media @tablet,@small,@small-big {
                //         position: relative;
                //         margin-left: 0;
                //         right: 0;
                //     }
                //     @media @laptopL {
                //         right: -310px;
                //         width: calc(46% - 14px) !important;
                //     }
                // }
                .select-adress-list {
                    float: left;
                    width: 70%;
                    margin-right: 15px;
                    border: 1px solid #f3f3f3;
                    border-radius: 10spx;
                    @media @small,@small-big,@tablet {
                        width: 100%;
                    }
                }
                .checkout-payment-button {
                    float: left;
                    margin-top: 10px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
                .new-address {
                    color: #e34541 !important;
                }
                .tab-content {
                    float: left;
                    width: 100%;
                    background: white;
                    padding: 20px;
                    border-radius: 8px;
                    margin: 20px auto 50px auto;
                    position: relative;
                    @media @small {
                        margin-bottom: 0;
                        padding: 5px;
                    }
                   
                    .payment-acordion {
                        float: left;
                        width: 70%;
                        margin-right: 15px;
                        @media @small, @small-big ,@tablet{
                            width: 100%;
                            margin-bottom: 20px;
                            margin-left: 0;
                        }
                    }
                    .siparis-ozeti {
                        background: white;
                        width: calc(30% - 15px);
                        display: inline-block;
                        background: #f9f9f9;
                        height: 100%;
                        padding: 15px;
                        border-radius: 10px;
                        @media @small, @small-big ,@tablet{
                            width: 100%;
                            margin-bottom: 20px;
                            margin-left: 0;
                        }
                        .order-product-price {
                            display: flex;
                            justify-content: space-between;
                            padding: 20px 0;
                            overflow: hidden;
                            border-bottom: 1px solid gainsboro;
                            border-top: 1px solid gainsboro;
                        }
                        .order-shipping-price {
                            display: flex;
                            justify-content: space-between;
                            padding: 20px 0;
                            overflow: hidden;
                            border-bottom: 1px solid gainsboro;
                            span {
                                span {
                                    &:first-child {
                                        color: #646464;
                                    }
                                 
                                    &:last-child, .cargo-note {     
                                        display: block;
                                        font-size: 13px;
                                    }
                                    
                                }
                            }
                            .cargo-bedava {
                                padding-left: 10px;
                                color: @yesil;
                                font-weight: 500;
                            }
                            .cargo-note {
                                display: block;
                                font-size: 13px;
                                
                            }
                            .cargo-mesaj {
                                color: #e34541;
                                font-weight: 500;
                            }
                            .cargo-price {
                                width: 20%;
                                text-align: end;
                            }
                            
                        }
                        .kdv-total-price {
                            display: flex;
                            justify-content: space-between;
                            padding: 20px 0;
                            overflow: hidden;
                        }
                        .fixed-area {
                            display: flex;
                            background: #f7f7f7;
                            width: 100%;
                            align-items: center;
                            left: 0;
                            padding: 0 10px;
                            position: fixed;
                            border: 1px solid gainsboro;
                            justify-content: space-between;
                            bottom: 0px;
                            border-radius: 10px 10px 0 0;
                            z-index: 9;
                            a {
                                margin-top: 0;
                            }
                            span {
                                padding-right: 10px;
                                &:last-child {
                                    color: @yesil;
                                    font-family: 500;
                                }
                            }
                        }
                        a {
                            padding: 10px;
                            margin-top: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;
                            border: 1px solid black;
                            border-radius: 5px;
                        }
                        .order-chcekout-btn {
                            background: @yesil;
                            border-color: @yesil;
                            color: white !important;
                        }
                        h2 {
                            font-size: 26px;
                            @media @small,@small-big {
                                font-size: 22px;
                            }
                        }
                    }
                    .tab-content {
                        background: white;
                    }
                    button {
                        background-color: @yesil;
                        border-color: @yesil;
                        min-width: 20%;
                        width: auto;
                        @media @small {
                            width: 80%;
                            float: none !important;
                            margin: 20px 10%;
                        }
                        &:focus {
                            box-shadow: none;
                        }
                    }
                    h1 {
                        position: relative;
                        bottom: 0;
                        font-weight: normal;
                        font-size: 26px;
                    }
                    label {
                        margin: 15px 0;
                    }
                    .checkbox-desc {
                        margin-left: 15px;
                        label {
                            margin: 0;
                        }
                    }
                    .checkout-edit-input {
                        float: left;
                        width: 33.333%;
                        margin: 10px 0;
                        @media @small {
                            padding: 5px;
                            margin: 5px 0;
                        }
                        &:first-child , &:nth-child(2) {
                            width: 100%;
                        }
                        input {
                            width: 100%;
                            padding: 10px;
                               
                        }
                        select {
                            width: 100%;
                            padding: 10px;
                        }
                    }
                    .order-note-form {
                        float: left;
                        width: 100%;
                        margin-top: 20px;
                        border-top: 1px solid gainsboro;
                        padding: 20px 0;
                        .orderNote-input {
                            width: 45%;
                            @media @small, @small-big {
                                width: 100%;
                            }
                        }
                    }
                
                    .checkout-edit-input-addrescheckbox {
                        margin-bottom: 20px;
                        margin-top: 20px;
                        @media @small {
                            margin-top: 10px;
                        }
                        > div {
                           float: left;
                           min-width: 200px;
                           padding: 10px;
                           padding-top: 0;
                           display: flex;
                          
                            input {    
                                border-radius: 10px !important;
                                float: left;
                                width: 30px;
                                margin: 0;
                                height: 30px;
                                background-color: white !important;
                                border: 1px solid #707070 !important;
                                @media @small,@small-big {
                                    width: 80px;
                                    padding-right: 10px;
                                }
                                &:focus {
                                    box-shadow: none !important;
                                }
                                &:checked {
                                    background-color: #F6F6F6 !important;
                                    border: 1px solid #F6F6F6  !important;
                                    box-shadow: none !important;
                                    background-blend-mode: difference;
                                }
                            }
                            .form-check-label {
                                color: #666666 !important;
                                font-size: 16px;
                                height: 30px;
                                display: flex;
                                margin: 0 10px;
                                align-items: center;
                                @media @small {
                                    font-size: 14px;
                                    height: auto;
                                    margin: 0;
                                    padding-left: 10px;
                                    text-align: left;
                                }
                               
                            }
                        }
                        
                    }
                    .cartType-edit-input {
                        select {
                            height: 40px;
                            width: 100%;
                            padding: 10px;
                        }
                    }
                    .accordion-header {
                       
                        button {
                            // flex-direction: column;
                            background: white;
                            align-items: flex-start;
                            justify-content: space-between;
                            max-width: 100%;
                            width: 100%;
                            margin: 0;
                            flex-direction: column-reverse;
                            &:after {
                                flex-shrink: 0;
                                width: 2rem;
                                height: 2rem;
                                margin-left: auto;
                                content: '';
                                background-image: url('../../images//acordionIcon.svg');
                                background-repeat: no-repeat;
                                background-size: 2rem;
                                transition: transform 0.2s ease-in-out;
                               
                            } 
                            p{
                                color: #b3b3b3;
                                font-size: 14px;
                            }
                            img {
                                height: 32px;
                            }
                        }
                        .accordion-button:not(.collapsed)::after {
                            transform: rotate(90deg);
                        }
                    }
                    .accordion-body {
                        width: 100%;
                        @media @small,@small-big,@tablet {
                            width: 100%;
                        }
                    }
                    .checkout-edit-input-box {
                        justify-content: flex-start;
                        button {
                            width: 40%;
                            @media @small {
                                width: 80%;
                                margin: 20px 10%;
                                z-index: 9999;
                            }
                        }
                    }
                    .wire-transfer-edit-input-checkbox {
                        @media @small {
                            padding: 0;
                            
                        }
                        > div {
                           float: left;
                           position: relative;
                           min-width: 200px;
                           padding: 20px;
                           padding-top: 0;
                           width: 100%;
                           display: flex;
                           margin-bottom: 20px;
                           @media @small , @small-big{
                               padding: 0;
                               min-height: fit-content;
                               padding: 10px;
                           }
                            input {
                                border-radius: 7px !important;
                                width: 30px;
                                height: 30px;
                                border: 1px solid #707070 !important;
                                position: absolute;
                                width: 100%;
                                height: 160px;
                                background: transparent !important;
                                @media @small,@small-big {
                                    height: 100%;
                                    margin-bottom: 10px;
                                }
                                &:focus {
                                    box-shadow: none !important;
                                }
                                &:checked {
                                    background-color: @yesil !important;
                                    border: 2px solid @yesil  !important;
                                    box-shadow: none !important;
                                    // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
                                    background: transparent !important;
                                    background-image: none !important;
                                }
                            }
                            h4 {
                                font-size: 18px;
                                @media @small,@small {
                                    font-size: 16px;
                                }
                            }
                            label {
                                height: 40px;
                                display: flex;
                                float: left;
                                width: 100%;
                                height: auto;
                                align-items: flex-start;
                                color: #5B5B5B !important;
                                margin-top: 30px;
                                padding-left: 15px;
                                flex-direction: column;
                                @media @small,@small {
                                    font-size: 14px;
                                    margin: 0;
                                    padding-left: 5px;
                                }
                                @media @small {
                                    padding-left: 15px;
                                    padding-top: 20px;
                                }

                            }
                        }
                        
                    }
                    .checkout-address-button {
                        display: flex;
                        justify-content: flex-end;
                        button {
                            background: none;
                            color: black;
                            border: none;
                            text-align: end;
                        }
                        button:focus {
                            box-shadow: inset 0 0px 0 #ddd;

                        }
                       
                    }

                    .alert{
                        p {
                            margin-bottom: 0;
                            font-size: 14px;
                        }
                        button {
                            background-color: transparent;
                            width: auto;
                            min-width: unset;
                            padding: 15px;
                            margin-top: 5px !important;
                           background-color: transparent !important;
                           
                        }
                    }
                }
                .login-or-sginup-normal-part {
                    justify-content: center;
                    ul {
                        @media @small,@small-big,@tablet {
                            width: 100%;
                        }
                    }
                    .login-promotion-area {
                        background: white;
                        border-radius: 0 10px  10px 0px;
                    }
                    button {
                        background: white;
                        width: 100%;
                        border-color: white;
                       
                    }
                    .nav-link.active {
                        background: @yesil;
                        color: white;
                        border-color: @yesil;
                    }
                    .tab-content {
                        width: 100%;
                        margin: 0;
                        @media @small,@small-big,@tablet {
                            width: 100%;
                        }
                        button {
                            margin-top: 40px !important;
                            background: @yesil;
                            border-color: @yesil;
                        }
                        button.sginup-camps {
                            margin-top: 0px !important; 
                        }
                    }
                    .login-input-box {
                        button {
                            background: white;
                            border-color: white;
                        }
                        .campaign-input {
                            display: flex;
                            margin: 10px 0;
                            > div {
                                input {
                                    width: 26px;
                                    height: 26px;
                                }
                            }
                            button {
                                font-size: 14px;
                                text-align: left;
                                color: #444444;

                                @media @small,@small-big {
                                    margin-top: 0 !important;
                                    margin-bottom: 0 !important;
                                }
                                span {
                                    color: black;
                                    font-weight: bold;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    padding: 10px;
                }
            }
        }
    }

    .checkout-footer-full-part { 
        background-image: url('../../images/background.webp');
        margin-top: auto;
        float: left;
        width: 100%;
        .checkout-footer-normal-part  {
            max-width: 1500px;
            .checkout-footer-grid-part  {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                @media @small,@small-big,@tablet {
                    flex-direction: column;
                }
                img {
                    max-height: 70px;
                    @media @small,@small-big {
                        width: 100%;
                    }
                }
            }
        }
    }
    .address-list-box {
        .address-list {
             background: white;
             border-radius: 10px;
             display: flex;
              align-items: center;
              min-height: 120px;
              justify-content: space-between;
              @media @small,@small-big {
                  flex-direction: column;
                  text-align: center;;
                  padding: 10px;
              }
              &.active {
                background: #f9f9f9;
                // background-image: url('../../images/tik.svg') !important;
                background-size: 80px 80px;
                background-repeat: no-repeat;
                background-position: top right;
                border: 1px solid @yesil;
                height: 100%;
            }
             .location-box {
                 float: left;
                 width: 10%;
                 padding-left: 10px;
             }
             .address-box {
                 float: left;
                 width: 90%;
                 padding: 20px;
                 p {
                     margin-bottom: 0;
                     &:first-child {
                         color: #7B7B7B;
                     }
                     &:last-child {
                         font-size: 14px;
                     }
                 }

             }
             h4 {
                 color: #484848;
                 font-size: 20px;
                 font-weight: 600;
                 margin-bottom: 5px;
                 margin-top: 0;
             }
             .button-box {
                 display: flex;
                 justify-content: flex-end;
                 z-index: 99;
                 button {
                     background: #F2F2F2 !important;
                     border-color: #F2F2F2 !important;
                     margin-bottom: 10px;
                     display: flex;
                     z-index: 99;
                     align-items: center;
                     justify-content: center;
                     min-width: 50px !important;
                     margin-right: 10px;
                     &:last-child {
                        width: 40px !important;
                        min-width: auto !important;
                     }
                 }
             }
            
        }
        .only-address {
            float: left;
            width: 100%;
            margin: 20px 0;
            > div {
                padding: 0;
                overflow: hidden;
                display: flex;
                align-items: center;
            }
            input {    
                border-radius: 10px !important;
                float: left;
                width: 30px;
                margin: 0;
                height: 30px;
                background-color: white !important;
                border: 1px solid #707070 !important;
                &:focus {
                    box-shadow: none !important;
                }
                &:checked {
                    background-color: #F6F6F6 !important;
                    border: 1px solid #F6F6F6  !important;
                    box-shadow: none !important;
                    background-blend-mode: difference;
                }
            }
            label {
                padding-left: 20px;
            }
        }
        form {
            float: left;
            width: 100%;
            padding: 20px;
            @media @small,@small-big {
                border: none;
                padding: 0;
                box-shadow: none;
            }
        }
        .teslimat-address, .fatura-address {
            float: left;
            width: 100%;
            padding: 20px;
            padding: 0 !important;
            position: relative; 
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 20px;
            @media @small {
                grid-template-columns: repeat(1,1fr);

            }
            .form-check-label {
                width: 100%;
                height: 100%;
            }
            input[type="radio"] {
                border-radius: 20px !important;
                width: 30px;
                height: 30px;
                background-color: white !important;
                border: 1px solid #707070 !important;
                margin-right: 20px;
                position: absolute;
                width: 85%;
                height: 100%;
                background: transparent !important;
                border: none !important;

                &:focus {
                    box-shadow: none !important;
                    border: none !important;

                }
                &:checked {
                    background-color: @yesil !important;
                    border: 1px solid @yesil  !important;
                    box-shadow: none !important;
                    background-image:none !important;
                    background: transparent !important;
                    border: none !important;


                }
            }
            label {
                height: 40px;
                display: flex;
                align-items: center;
                color: #5B5B5B !important;
            }
            .teslimat-address , .fatura-address{
                float: left;
                width: 100%;
                display: flex;
                align-items: center;
                border: 1px solid #efefef;
                border-radius: 10px;
                box-shadow: 0 0 3px #efefef;
                @media @small,@small-big,@tablet {
                    width: 100%;
                }
                .address-list {
                    float: left;
                    width: 100%;
                   

                }
            }
            
        }
        .address-box {
            display: block;
        }
    }

    .checkout-payment {
        float:right;           
    }
}

.order-result {
    .App {
        background-image: none;
        
    }
}
    .order-result-full-part {
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }
    .order-result-normal-part  {
        max-width: 1500px;
        box-shadow: 0px 0px 5px 0px #efefef;
        padding: 20px;
        background: white;
        border: 1px solid #efefef;
        .order-grid-part{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #b4b4b4;
            padding-bottom: 20px;
            overflow: hidden;
            @media @small {
                flex-direction: column;
            }
            .user-name {
                display: flex;  
                .order-note {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 25px;
                }
                svg {
                    max-height: 120px;
                }
            }
            .order-date {
                .siparis-no {
                    display: flex;
                    padding: 20px;
                    background: #23BE72;
                    color: white;
                    flex-direction: column;
                    justify-content: center;
                    border-radius: 5px;
                    align-items: center;
                }
            }
        }
        .order-mesaj {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin:20px auto;
            p {
                margin-bottom: 0;

            }
        }
        .order-mail-note {
            margin-top: 40px;
            text-align: center;
            p {
                color: #525252;
                font-size: 14px;
                margin-bottom: 0;
            }
        }
    }


.adres-ekleme, .uyeliksiz {
    button.checkout-payment {
        display: none;
    }
    .checkout-payment-uyeliksiz button {
        display: none;
    }
}
.payment-cart-modal {
    .modal-body {
        iframe {
            width: 100;
            min-width: 500px;
            min-height: 350px;
            height: auto;
        }
    }
}

.sozlesme-label {
    display: block !important;
}
.sozlesme-button[type="button"] {
    background: none !important;
    color: #666666 !important;
    padding: 0 !important;
    border: none !important;
    min-width: unset !important;
    @media @small {
        background: none !important;
        color: #000000 !important;
        padding: 0 !important;
        border: none !important;
        min-width: fit-content !important;
        font-size: 14px;
        font-weight: bold;
        margin: 0 !important;
        width: auto;
        text-indent: -3px;
    }
}
.garanti-pay {
    label {
        .garanti-image {
            display: flex;
            align-items: center;
        }
        p {
            font-size: 14px;
        }
        img {
            height: 80px;
        }
    }
}
.kupon-uygulama {
    margin-top: 20px;
    form {
        width: 100%;
        .cupon-form {
            flex-direction: row;
            .cupon-input {
                float: left;
                width: 70%;
                input {
                    height: 100%;

                }
            }
            button {
                float: left;
                width: 30%;
                background: #ffffff;
                border-color: #e7e7e7;
                color: black;
            }
        }
    }
    p {
        font-size: 14px;
        color: #6e6e6e;
        margin-top: 20px;
    }
}
.account-new-addres {
    .address-edit-input {
        @media @small {
            float: left;
            width: 100%;
        }
    }
}