.dropdown-menu .popover-body {
  width: 300px !important;
}
.dropdown-menu .popover-body ul li {
  border-bottom: 1px solid #ecdec9;
  width: 100% !important;
  margin-bottom: 5px;
  list-style: none;
  margin-bottom: 10px;
  float: left;
  padding-bottom: 5px !important;
  padding-left: 13px !important;
}
.header-full-part {
  float: left;
}
.header-full-part .header-normal-part {
  max-width: 1500px;
  width: 95%;
  height: 130px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .header-full-part .header-normal-part {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1921px) {
  .header-full-part .header-normal-part {
    max-width: unset;
    height: auto;
  }
}
.header-full-part .header-normal-part .row {
  justify-content: flex-end;
}
.header-full-part .header-category-list {
  float: right;
  width: 100%;
  z-index: 999;
}
.header-full-part .header-category-list .row {
  justify-content: space-between;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list .row {
    position: relative;
  }
}
.header-full-part .header-category-list .logo-part {
  float: left;
  position: relative;
  bottom: 55px;
  padding: 0;
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .header-full-part .header-category-list .logo-part {
    position: absolute;
    top: 10px;
    height: 120px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list .logo-part {
    top: -45px;
    position: absolute;
    height: 120px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list .logo-part {
    left: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-full-part .header-category-list .logo-part {
    width: 10%;
    padding: 5px;
    top: -65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .logo-part {
    width: 10%;
    position: absolute;
    top: 10px;
    left: 15px;
    height: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .logo-part {
    position: absolute;
    top: 10px !important;
    height: 120px;
  }
}
.header-full-part .header-category-list .logo-part img {
  max-width: 95%;
}
.header-full-part .header-category-list .menu-part {
  float: right;
  width: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .menu-part {
    width: 90%;
    display: flex;
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .header-full-part .header-category-list .menu-part {
    float: right;
    width: calc(100% - 140px);
    margin-left: 140px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .menu-part {
    width: 100%;
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .menu-part {
    width: 100%;
    margin-top: 40px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list .menu-part {
    position: absolute;
    right: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-full-part .header-category-list .menu-part a {
    font-size: 12px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .menu-part a {
    font-size: 10px !important;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list .menu-part a {
    font-size: 11px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .menu-part a {
    font-size: 11px;
  }
}
.header-full-part .header-category-list ul {
  float: right;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list ul {
    width: 90%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list ul {
    width: 100%;
    margin-bottom: 10px;
  }
}
.header-full-part .header-category-list ul li {
  list-style: none;
  padding-right: 10px;
  float: left;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: auto;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list ul li {
    padding: 0;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list ul li {
    padding: 0;
    font-size: 12px;
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list ul li {
    padding: 0;
    font-size: 10px;
  }
}
.header-full-part .header-category-list ul .hesabim-li,
.header-full-part .header-category-list ul .sepatim-li {
  padding-right: 0;
  font-size: 12px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list ul .hesabim-li img,
  .header-full-part .header-category-list ul .sepatim-li img {
    width: 28px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list ul .hesabim-li img,
  .header-full-part .header-category-list ul .sepatim-li img {
    width: 28px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list ul .hesabim-li img,
  .header-full-part .header-category-list ul .sepatim-li img {
    width: 28px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list ul .sepatim-li {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.header-full-part .header-category-list ul a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  padding: 0 5px;
}
.header-full-part .header-category-list ul a:hover {
  color: #23BE72 !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list ul a {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list ul a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .header-full-part .header-category-list ul a {
    font-size: 12px;
  }
}
.header-full-part .header-category-list .header-name-box {
  float: left;
  padding-left: 10px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .header-name-box {
    height: auto;
    padding: 0;
    text-align: center;
    justify-content: flex-start;
    min-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .header-name-box {
    height: auto;
    padding: 0;
  }
}
.header-full-part .header-category-list .header-name-box button {
  background: no-repeat;
  border: none;
  color: black;
  font-size: 12px;
  padding-left: initial;
  font-weight: 700;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .header-name-box button {
    padding: 0;
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .header-name-box button {
    padding: 0;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-full-part .header-category-list .header-name-box button {
    font-size: 11px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .header-name-box button {
    font-size: 10px;
  }
}
.header-full-part .header-category-list .header-name-box p {
  margin: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .header-name-box .uye-ol-span {
    font-size: 12px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .header-name-box .uye-ol-span {
    font-size: 10px;
  }
}
.header-full-part .header-category-list .header-icon-box {
  float: left;
  height: 80px;
  display: flex;
  padding: 0;
  align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .header-icon-box {
    height: auto;
    position: relative;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .header-icon-box {
    height: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .header-icon-box img {
    height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-full-part .header-category-list .header-icon-box img {
    height: 20px;
  }
}
.header-full-part .header-category-list .header-icon-box .basket-total-items {
  position: relative;
  background: #E23F38;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  left: 50px;
  bottom: 13px;
  color: white;
  font-size: 13px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-full-part .header-category-list .header-icon-box .basket-total-items {
    left: 20px;
    position: absolute;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .header-full-part .header-category-list .header-icon-box .basket-total-items {
    width: 14px;
    height: 16px;
    left: 30px;
    bottom: 6px;
    font-size: 10px;
  }
}
.header-top-full-part {
  float: left;
  width: 100%;
  border-bottom: 1px solid gainsboro;
}
.header-top-full-part .header-top-normal-part {
  max-width: 1500px;
}
@media only screen and (min-width: 1921px) {
  .header-top-full-part .header-top-normal-part {
    max-width: fit-content;
    width: 95%;
  }
}
.header-top-full-part .header-top-normal-part .header-grid-part {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.header-top-full-part .header-top-normal-part .header-grid-part ul {
  float: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.header-top-full-part .header-top-normal-part .header-grid-part ul li {
  list-style: none;
  padding: 0 10px;
  float: left;
  display: flex;
  align-items: center;
  font-size: 15px;
  width: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-full-part .header-top-normal-part .header-grid-part ul li {
    padding: 0;
    font-size: 13px;
  }
}
.header-top-full-part .header-top-normal-part .header-grid-part ul a {
  color: #222222 !important;
  text-decoration: none;
  padding: 0 5px;
  font-family: PaulSlab-bold;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-top-full-part .header-top-normal-part .header-grid-part ul a {
    font-size: 12px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-full-part .header-top-normal-part .header-grid-part ul a {
    font-size: 12px;
  }
}
.header-top-full-part .header-top-normal-part .header-grid-part ul a.satist {
  color: #de1f19 !important;
  font-weight: bold;
}
.header-top-full-part .header-top-normal-part .header-grid-part ul .tel-box {
  border: 1px solid black;
  border-radius: 10px;
  padding: 0 5px;
  height: 30px;
}
.header-top-full-part .header-top-normal-part .header-grid-part ul .tel-box .tel-text-box {
  height: 25px;
  padding: 0 5px;
  border-right: 1px solid black;
  float: left;
  font-family: PaulSlab-Regular;
}
.header-top-full-part .header-top-normal-part .header-grid-part ul .tel-box .tel-text-box img {
  padding: 0 5px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-top-full-part .header-top-normal-part .header-grid-part ul .tel-box .tel-text-box img {
    padding: 7px 5px;
    width: 22px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-full-part .header-top-normal-part .header-grid-part ul .tel-box .tel-text-box img {
    padding: 7px 5px;
    width: 22px;
  }
}
.header-top-full-part .header-top-normal-part .header-grid-part ul .tel-box .tel-text-box span {
  height: 25px;
}
.header-top-full-part .header-top-normal-part .header-grid-part-30 {
  position: relative;
  float: right;
  width: auto;
}
@media only screen and (max-width: 1199px) {
  .header-top-full-part .header-top-normal-part .header-grid-part-30 {
    display: none !important;
  }
}
.header-top-full-part .header-top-normal-part .header-grid-part-30 .input-group-text {
  font-size: 0.9rem;
  background-color: #fdfdfd;
  border: 1px solid #eeeeee;
}
.header-top-full-part .header-top-normal-part .header-grid-part-30 .search-result {
  display: none;
  position: absolute;
  top: 35px;
  left: 0px;
  width: 100%;
  background-color: white;
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
}
.header-top-full-part .header-top-normal-part .header-grid-part-70 {
  position: relative;
  float: right;
  width: auto;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-top-full-part .header-top-normal-part .header-grid-part-70 .header-grid-part ul a {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .header-top-full-part .header-top-normal-part .header-grid-part-70 .header-grid-part ul a {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-full-part .header-top-normal-part .header-grid-part-70 .header-grid-part ul a {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-top-full-part .header-top-normal-part .header-grid-part-70 .header-grid-part ul a {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .header-top-full-part .header-top-normal-part .header-grid-part-70 .header-grid-part ul a {
    font-size: 0.8rem;
  }
}
.popover {
  border-color: gainsboro !important;
  max-width: 450px !important;
}
.popover .popover-header {
  background: none !important;
  border-color: gainsboro !important;
}
.popover .popover-body {
  padding: 10px;
  float: left;
  width: 100%;
}
.popover .popover-body .cart-product-list-box {
  max-height: 300px;
  overflow: auto;
}
.popover .popover-body .cart-product-box {
  float: left;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 10px;
}
.popover .popover-body .cart-product-box:nth-child(2n) {
  border-bottom: 0;
}
.popover .popover-body .cart-product-box .product-image-box img {
  height: 64px;
  border-radius: 10px;
}
.popover .popover-body .cart-product-box .product-unit-dec {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.popover .popover-body .cart-product-box .product-unit-dec button {
  background: none;
  border: none;
}
.popover .popover-body .cart-product-box .product-unit-dec button img {
  height: 20px;
}
.popover .popover-body .cart-product-box .product-unit-dec .numbers-row {
  background: #F9F6F5;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.popover .popover-body .cart-product-box .product-unit-dec .numbers-row .dec {
  float: left;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 10px 0 0 10px;
}
.popover .popover-body .cart-product-box .product-unit-dec .numbers-row .inc {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border: none;
  border-left: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 0 10px 10px 0px;
}
.popover .popover-body .cart-product-box .product-unit-dec .numbers-row span {
  color: #343132;
  text-align: center;
}
.popover .popover-body .cart-product-box .product-unit-dec .numbers-row .input-are {
  max-width: 40%;
  float: left;
  height: 40px;
}
.popover .popover-body .cart-product-box .product-unit-dec .numbers-row .input-are .productQuantityTextBox {
  float: left;
  width: 100%;
  height: 26px;
  background: #F9F6F5;
  text-align: center;
  border: none;
}
.popover .popover-body .cart-product-box .product-unit-dec .numbers-row .input-are span {
  float: left;
  width: 100%;
  text-align: center;
}
.popover .popover-body .cart-product-box .produt-desc-price-box {
  padding: 0 10px;
}
.popover .popover-body .cart-product-box .produt-desc-price-box h6 {
  margin-bottom: 0;
}
.popover .popover-body .cart-product-box .produt-desc-price-box span {
  float: left;
  width: 100%;
  font-size: 13px;
  color: #A0A0A0;
}
.popover .popover-body .cart-product-box .produt-desc-price-box span:last-child {
  color: #23BE72;
  font-weight: 600;
  font-size: 16px;
}
.popover .popover-body .cargo-and-discount-box {
  float: left;
  width: 100%;
  margin: 10px 0;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
}
.popover .popover-body .cargo-and-discount-box .span-box {
  display: flex;
  padding: 5px 0;
  font-size: 16px;
  justify-content: space-between;
  float: left;
  width: 100%;
}
.popover .popover-body .cart-button-box {
  float: left;
  width: 100%;
}
.popover .popover-body .cart-button-box span {
  font-size: 20px;
  color: #23BE72;
}
.popover .popover-body .cart-button-box span:first-child {
  display: flex;
  justify-content: space-between;
}
.popover .popover-body .cart-button-box p {
  margin: 10px 0;
  font-size: 16px;
}
.popover .popover-body .cart-button-box .button-boc {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.popover .popover-body .cart-button-box .button-boc a {
  color: white !important;
  text-decoration: none;
  width: 40%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  border-radius: 10px;
}
.popover .popover-body .cart-button-box .button-boc a:first-child {
  background: #313131;
}
.popover .popover-body .cart-button-box .button-boc a:last-child {
  background-color: #23BE72;
}
.search-result {
  padding: 20px;
  background: white;
  float: left;
  width: 98%;
  min-height: 250px;
  position: relative;
  z-index: 999999;
  left: 3px;
  max-height: 427px;
  overflow: auto;
  box-shadow: 0px 0px 8px 2px #e6e6e6;
  overflow-y: scroll;
  top: -10px;
}
.search-result .product-list {
  width: calc(50% - 5px);
  float: left;
  margin-right: 10px;
  box-shadow: 0 0 3px gainsboro;
  margin-bottom: 20px;
  border-radius: 10px;
  z-index: 999999;
}
.search-result .product-list:nth-child(2n) {
  margin-right: 0;
}
.search-result .product-list h6,
.search-result .product-list p {
  margin-bottom: 0;
  padding: 5px 10px;
}
.search-result .product-list h6 {
  font-size: 14px;
  min-height: 50px;
  max-height: 50px;
  overflow: hidden;
}
.search-result .product-list p {
  color: #23BE72;
  font-weight: 600;
}
.search-result .product-list img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 90px;
  object-fit: contain;
}
.search-result .product-list a {
  text-decoration: none;
  float: left;
  width: 100%;
}
.mobile-menu-full-part .header-mobile-top-part {
  display: flex;
  justify-content: space-between;
}
.mobile-menu-full-part .header-mobile-top-part a {
  z-index: 9999;
  text-decoration: none;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.mobile-menu-full-part .header-mobile-top-part a:first-child {
  margin-top: 0;
}
.mobile-menu-full-part .header-mobile-top-part a span.login-span {
  display: flex;
  flex-direction: column;
}
.mobile-menu-full-part .header-mobile-top-part a img {
  height: 80px;
  margin-top: 10px;
}
.mobile-menu-full-part .basket-total-items {
  position: absolute;
  background: #E23F38;
  border-radius: 49%;
  text-align: center;
  color: white;
  width: 18px;
  height: 20px;
  top: 10px;
  font-size: 13px;
  right: 8px;
}
.mobile-menu-full-part .search-menu-part {
  display: flex;
  margin: 10px 0;
  z-index: 9;
}
.mobile-menu-full-part .search-menu-part .mobile-menu-button {
  background: white;
  color: black;
  border-color: #979797;
  min-width: 30%;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 7px;
  color: #99999E;
  font-size: 12px;
}
.mobile-menu-full-part .search-menu-part .mobile-menu-button:focus {
  box-shadow: none;
  background: white;
  border-color: #979797;
  color: black;
}
.mobile-menu-full-part .search-menu-part .mobile-menu-button svg {
  margin-right: 10px;
}
.mobile-menu-full-part .search-menu-part .input-group {
  border-radius: 7px;
}
.mobile-menu-full-part .search-menu-part .input-group input {
  background-color: white !important;
  border-radius: 7px;
  font-size: 13px !important;
}
.mobile-menu-full-part .search-menu-part .input-group span {
  background: #E8E8E8;
  color: #99999E;
  border: none;
}
.offcanvas-start {
  width: 85% !important;
  height: 100vh;
}
.offcanvas-start .offcanvas-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.offcanvas-start .offcanvas-body ul {
  padding: 0;
}
.offcanvas-start .offcanvas-body ul a {
  text-decoration: none;
}
.offcanvas-start .offcanvas-body ul button {
  background: none;
  color: black;
  border: none;
  padding: 0;
  width: 100%;
  text-align: left;
}
.offcanvas-start .offcanvas-body ul button:focus {
  box-shadow: none;
}
.offcanvas-start .offcanvas-body ul li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  line-height: 45px;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 10px;
  align-items: center;
  padding-bottom: 5px;
  font-size: 15px;
}
.offcanvas-start .offcanvas-body ul li:after {
  content: '';
  background-image: url('../images//acordionIcon.svg');
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
}
.offcanvas-start .offcanvas-body ul li.active button {
  color: #9e9e9e;
}
.offcanvas-start .offcanvas-body ul li.active > div {
  padding-left: 10px;
  float: left;
  width: 100%;
  margin-top: 10px;
}
.offcanvas-start .offcanvas-body ul .tumunu-incele {
  background: #ececec;
  padding: 5px 20px;
  border-radius: 5px;
  text-align: left;
  float: left;
  width: 100%;
}
.offcanvas-start .offcanvas-body ul .tumunu-incele:hover p {
  padding-right: 25px;
}
.offcanvas-start .offcanvas-body ul .tumunu-incele p {
  margin-bottom: 0;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.offcanvas-start .offcanvas-body ul .tumunu-incele p:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.offcanvas-start .offcanvas-body .mobile-phone {
  display: flex;
  justify-content: space-between;
  background: #f1f1f1;
  padding: 15px;
  margin-top: auto;
  margin-bottom: 60px;
  border-radius: 10px;
}
.offcanvas-start .offcanvas-body .mobile-phone a {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.offcanvas-start .offcanvas-body .mobile-phone a svg {
  margin-right: 15px;
}
.offcanvas-start .offcanvas-body .login-and-order-grid {
  display: flex;
  justify-content: space-between;
  background: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.offcanvas-start .offcanvas-body .login-and-order-grid a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}
.offcanvas-start .offcanvas-body .login-and-order-grid svg {
  width: 32px;
  height: 32px;
}
.offcanvas-start .offcanvas-body .login-and-order-grid svg circle,
.offcanvas-start .offcanvas-body .login-and-order-grid svg ellipse {
  fill: #b3b3b3;
}
.offcanvas-start .offcanvas-body .login-and-order-grid span {
  text-align: center;
}
.offcanvas-start .offcanvas-body .login-and-order-grid .header-name-box p {
  margin-bottom: 0;
  text-align: center;
}
.offcanvas-start .offcanvas-body .login-and-order-grid .header-name-box span {
  font-size: 14px;
  color: #3e3e3e;
}
.modal-backdrop.show {
  opacity: 0;
}
.offcanvas.show {
  transform: none;
  z-index: 999999;
}
.offcanvas-header {
  background: #fbfbfb;
  padding: 10px;
}
.offcanvas-header a img {
  height: 70px;
}
.dropdown-menu {
  max-width: 501px !important;
}
.dropdown-menu .popover-body {
  width: 500px;
  background: white;
}
.dropdown-menu .popover-body ul {
  float: left;
  width: 100%;
  padding: 0;
}
.dropdown-menu .popover-body ul li {
  list-style: none;
  margin-bottom: 10px;
  float: left;
  width: 50%;
}
.dropdown-menu .popover-body ul li img {
  height: 150px;
  border-radius: 10px;
}
.dropdown-menu .popover-body ul li a {
  float: left;
  width: 100%;
  text-decoration: none;
  padding-top: 5px;
}
.dropdown-menu .popover-body .tumunu-incele {
  background: #ececec;
  padding: 10px 30px;
  border-radius: 5px;
  text-align: left;
  float: left;
}
.dropdown-menu .popover-body .tumunu-incele:hover p {
  padding-right: 25px;
}
.dropdown-menu .popover-body .tumunu-incele p {
  margin-bottom: 0;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.dropdown-menu .popover-body .tumunu-incele p:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.popover-ara-toplam {
  display: flex;
  justify-content: space-between;
  float: left;
  width: 100%;
}
.popover-ara-toplam span {
  font-size: 16px;
}
.popover-ara-toplam span:last-child {
  color: #23BE72;
  font-weight: 600;
}
.cargo-mesaj {
  color: #e34541;
  font-weight: 500;
}
* {
  box-sizing: border-box;
}
body {
  background-color: #F7F7F7 !important;
  font-family: Rothwood-Book !important;
  font-display: swap;
}
textarea.form-control {
  margin: 20px;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border: 1px solid #EEEEEE;
}
.form-control,
input,
select {
  background-color: #F6F6F6 !important;
  border: 1px solid #EEEEEE !important;
  color: #666666 !important;
  font-size: 15px !important;
}
button:focus {
  box-shadow: none !important;
}
a {
  color: black !important;
  text-decoration: none;
}
.App {
  background-image: url('../images/background.webp');
  height: auto;
  float: left;
  width: 100%;
}
.index .App {
  height: 110vh;
  background-repeat: no-repeat;
}
.index .product-box {
  min-height: fit-content;
}
.carousel-control-next-icon {
  width: 60px !important;
  height: 60px !important;
  background-repeat: no-repeat !important;
}
.carousel-control-prev-icon {
  width: 60px !important;
  height: 60px !important;
  background-repeat: no-repeat !important;
}
.sr-only {
  display: none;
}
.carousel-caption {
  float: left;
  position: relative !important;
}
.home-slider-container {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container {
    justify-content: center;
  }
}
.home-slider-container .home-slider-normal-part {
  max-width: 1700px;
  float: right;
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .home-slider-container .home-slider-normal-part {
    max-width: 1630px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px), only screen and (min-width: 1921px) {
  .home-slider-container .home-slider-normal-part {
    max-width: 96vw;
    margin-top: 20px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-slider-container .home-slider-normal-part {
    max-width: 96vw;
    padding: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-slider-container .home-slider-normal-part {
    max-width: 96vw;
    padding: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-slider-container .home-slider-normal-part {
    max-width: 98vw;
    padding: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .home-slider-container .home-slider-normal-part {
    max-width: 98vw;
    padding: 10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part {
    max-width: 98vw;
    padding: 10px;
  }
}
.home-slider-container .home-slider-normal-part .swiper-button-next {
  right: 200px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part .swiper-button-next {
    right: 100px;
    width: auto;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part .swiper-button-next {
    right: 5px;
    display: none;
  }
}
.home-slider-container .home-slider-normal-part .swiper-button-next:after {
  content: url('../images//Next.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part .swiper-button-next:after {
    background-image: url('../images//Next.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
.home-slider-container .home-slider-normal-part .swiper-button-prev {
  left: -70px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .home-slider-container .home-slider-normal-part .swiper-button-prev {
    left: -45px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part .swiper-button-prev {
    left: -50px;
    width: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part .swiper-button-prev {
    left: -45px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part .swiper-button-prev {
    left: -10px;
    display: none;
  }
}
.home-slider-container .home-slider-normal-part .swiper-button-prev:after {
  content: url('../images//prev.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .home-slider-normal-part .swiper-button-prev:after {
    background-image: url('../images//prev.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
.home-slider-container .home-slider-normal-part .swiper-pagination-bullet-active {
  background: #E13F3F;
}
.home-slider-container .home-slider-normal-part .swiper-container {
  overflow: visible;
}
.home-slider-container .home-slider-normal-part .swiper-pagination {
  bottom: -30px;
}
.home-slider-container a:hover .hemen-al span {
  padding-right: 25px;
}
.home-slider-container a:hover .hemen-al span:after {
  opacity: 1;
  right: 0;
}
.home-slider-container .slider-image {
  float: right;
  width: 70%;
  text-align: end;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .slider-image {
    width: 100%;
    margin: 0 auto;
  }
}
.home-slider-container .slider-image img {
  max-width: 100%;
  border-radius: 40px 0 0 40px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .slider-image img {
    border-radius: 20px;
  }
}
.home-slider-container .slider-description {
  float: left;
  width: 30%;
  padding-right: 12px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .slider-description {
    width: 100%;
    margin-top: 30px;
  }
}
.home-slider-container .slider-description .slider-border {
  float: left;
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .home-slider-container .slider-description .slider-border {
    margin-bottom: 10px;
  }
}
.home-slider-container .slider-description .slider-border span {
  width: 40px;
  height: 2px;
  background: red;
  float: left;
}
.home-slider-container .slider-description p {
  font-family: PaulSlab-Regular;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-slider-container .slider-description p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .slider-description p {
    font-size: 14px;
  }
}
.home-slider-container .slider-description h2 {
  font-size: 50px;
  font-family: PaulSlab-bold;
  font-style: normal;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-slider-container .slider-description h2 {
    font-size: 26px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-slider-container .slider-description h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .slider-description h2 {
    font-size: 18px;
  }
}
.home-slider-container .slider-description h3 {
  font-family: Rothwood-Book;
}
.home-slider-container .slider-description .hemen-al {
  padding: 10px 50px;
  background: #E13F3F;
  border-color: #E13F3F;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-slider-container .slider-description .hemen-al {
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-slider-container .slider-description .hemen-al {
    padding: 5px 10px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-slider-container .slider-description .hemen-al {
    background: #23BE72;
    border: #23BE72;
    border-radius: 10px;
    width: 95%;
  }
}
.home-slider-container .slider-description .hemen-al {
  transition: all 0.5s;
  cursor: pointer;
}
.home-slider-container .slider-description .hemen-al span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.home-slider-container .slider-description .hemen-al span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.home-slider-container .slider-description .hemen-al:hover span {
  padding-right: 25px;
}
.home-slider-container .slider-description .hemen-al:hover span:after {
  opacity: 1;
  right: 0;
}
.slider-description h3 {
  font-size: 12px;
}
.home-product-normal-part.container {
  overflow: hidden;
  max-width: 1500px;
  margin: 40px auto;
}
@media only screen and (min-width: 1921px) {
  .home-product-normal-part.container {
    max-width: 96vw;
    margin-top: 20px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .home-product-normal-part.container {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-product-normal-part.container {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-product-normal-part.container {
    max-width: initial;
    width: 95%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-product-normal-part.container {
    max-width: 95%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-product-normal-part.container {
    max-width: 90%;
    margin: 40px auto;
    padding: 0;
  }
  .home-product-normal-part.container:last-child {
    margin-bottom: 0;
  }
}
.home-product-normal-part.container h2 {
  font-family: Rothwood-Bold !important;
  padding-left: 10px;
}
.home-product-normal-part.container p {
  padding-left: 10px;
}
.home-product-normal-part.container .product-box {
  max-width: 345px;
  width: 100%;
}
.home-product-normal-part.container .home-product-box {
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  padding: 0 8px 16px 8px;
  margin-top: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-product-normal-part.container .home-product-box {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .home-product-normal-part.container .home-product-box {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
@media only screen and (min-width: 1921px) {
  .home-product-normal-part.container .home-product-box {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .home-product-normal-part.container .home-product-box {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    padding: 0;
    margin-top: 5px;
  }
}
.home-product-normal-part.container .home-product-box .product-box  {
  width: 100%;
  max-width: fit-content;
  margin: 0;
}
.category-full-part {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.category-full-part .category-normal-part {
  padding: 0;
  overflow: hidden;
  max-width: 88vw;
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .category-full-part .category-normal-part {
    max-width: 92vw;
  }
}
@media only screen and (min-width: 1921px) {
  .category-full-part .category-normal-part {
    max-width: 92vw;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-full-part .category-normal-part {
    max-width: 94vw;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-full-part .category-normal-part {
    max-width: 94vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part {
    max-width: 94vw;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .category-full-part .category-normal-part {
    max-width: 96vw;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part {
    max-width: 92vw;
  }
}
.category-full-part .category-normal-part a {
  float: left;
  width: 100%;
  transition: all 0.5s ease-in-out;
  border-radius: 20px;
  background: transparent;
  position: relative;
}
.category-full-part .category-normal-part a:hover {
  box-shadow: 0px 7px 20px 0px #b7b7b7;
  transform: translate(0, -10px);
}
.category-full-part .category-normal-part .category-desc {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px;
  color: white;
  text-align: center;
  border-radius: 0 0 20px 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.78) 0%, rgba(0, 212, 255, 0) 100%, rgba(9, 9, 121, 0) 100%);
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .category-full-part .category-normal-part .category-desc {
    bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part .category-desc {
    bottom: 0;
  }
}
.category-full-part .category-normal-part .category-desc span {
  float: left;
  width: 100%;
  font-size: 12px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part .category-desc span {
    font-size: 8px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part .category-desc h4 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part .category-desc h4 {
    font-size: 14px;
  }
}
.category-full-part .category-normal-part img {
  width: 100%;
  min-height: 332px;
  border-radius: 20px;
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .category-full-part .category-normal-part img {
    min-height: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-full-part .category-normal-part img {
    min-height: 190px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .category-full-part .category-normal-part img {
    height: 332px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part img {
    min-height: 218px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part img {
    min-height: 160px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part img {
    min-height: 150px;
  }
}
.category-full-part .category-normal-part .swiper-container {
  height: 440px;
  display: flex;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-full-part .category-normal-part .swiper-container {
    height: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part .swiper-container {
    height: 240px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part .swiper-container {
    height: 280px;
  }
}
@media only screen and (max-width: 479px) {
  .category-full-part .category-normal-part .swiper-container {
    height: 320px;
  }
}
.category-full-part .category-normal-part .swiper-button-prev  {
  top: 40px;
  right: 420px;
  left: auto;
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part .swiper-button-prev  {
    right: 225px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part .swiper-button-prev  {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-full-part .category-normal-part .swiper-button-prev  {
    top: 30px;
  }
}
.category-full-part .category-normal-part .swiper-button-next  {
  top: 40px;
  right: 260px;
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part .swiper-button-next  {
    right: 50px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part .swiper-button-next  {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-full-part .category-normal-part .swiper-button-next  {
    top: 30px;
  }
}
.category-full-part .category-normal-part .swiper-button-next:after {
  content: url('../images//categoryNext.svg');
}
.category-full-part .category-normal-part .swiper-button-prev:after {
  content: url('../images//categoryPrev.svg');
}
.category-full-part .category-normal-part .swiper-pagination-bullet-active {
  background: #E13F3F;
}
.category-full-part .category-normal-part .swiper-pagination {
  width: 120px;
  top: 25px;
  right: 295px;
  left: auto;
  height: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-full-part .category-normal-part .swiper-pagination {
    right: 90px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part .swiper-pagination {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-full-part .category-normal-part .swiper-pagination {
    top: 15px;
  }
}
.category-full-part .category-normal-part .swiper-slide {
  height: 335px;
  margin-top: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .category-full-part .category-normal-part .swiper-slide {
    height: auto;
  }
}
.category-full-part .category-normal-part .category-title {
  position: absolute;
  font-family: Rothwood-Book;
}
@media only screen and (max-width: 479px) {
  .category-full-part .category-normal-part .category-title {
    padding: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-full-part .category-normal-part .category-title {
    position: relative;
  }
}
.rebate-full-part {
  float: left;
  width: 100%;
  background-image: url('../images/deneme.webp');
  margin: 50px 0;
  height: 250px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .rebate-full-part {
    margin: 0;
    background-size: contain;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .rebate-full-part {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .rebate-full-part {
    height: auto;
  }
}
.rebate-full-part .rebate-normal-part {
  max-width: 1500px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .rebate-full-part .rebate-normal-part {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .rebate-full-part .rebate-normal-part {
    max-width: 1100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rebate-full-part .rebate-normal-part {
    max-width: 980px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rebate-full-part .rebate-normal-part {
    max-width: 95%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .rebate-full-part .rebate-normal-part {
    max-width: 95%;
  }
}
@media only screen and (min-width: 1921px) {
  .rebate-full-part .rebate-normal-part {
    max-width: 92vw;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part > .row {
    justify-content: center;
    flex-direction: column-reverse;
  }
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video {
  z-index: 2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video {
    width: 50%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video {
    display: flex;
    justify-content: flex-end;
  }
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video button {
  background: none;
  border: none;
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video button:focus {
  box-shadow: none;
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video img {
  max-width: 450px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video img {
    max-width: 360px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-video img {
    max-width: 100%;
    position: relative;
    bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-customer {
    display: none;
  }
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc {
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc {
    max-width: 41%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc {
    padding: 10px;
  }
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc h2 {
  background: #b7081b;
  color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: fit-content;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc h2 {
    font-size: 18px;
  }
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc p {
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc p {
    font-size: 14px;
  }
}
.rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc span {
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rebate-full-part .rebate-normal-part .rebate-grid-part .rebate-desc span {
    font-size: 14px;
  }
}
.recipes-full-part {
  float: left;
  width: 100%;
  padding: 20px;
}
@media only screen and (max-width: 479px) {
  .recipes-full-part {
    padding-bottom: 0;
  }
}
.recipes-full-part .recipes-normal-part {
  max-width: 1500px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .recipes-full-part .recipes-normal-part {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recipes-full-part .recipes-normal-part {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .recipes-full-part .recipes-normal-part {
    max-width: 1100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .recipes-full-part .recipes-normal-part {
    max-width: 95%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .recipes-full-part .recipes-normal-part {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1921px) {
  .recipes-full-part .recipes-normal-part {
    max-width: 92vw;
  }
}
.recipes-full-part .recipes-normal-part .breadcrumb ol {
  display: flex;
  padding: 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .recipes-full-part .recipes-normal-part .breadcrumb ol {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-y: hidden;
    border-radius: 10px 10px 0 0;
  }
}
.recipes-full-part .recipes-normal-part .breadcrumb ol li {
  list-style: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .recipes-full-part .recipes-normal-part .breadcrumb ol li {
    min-width: fit-content;
  }
}
.recipes-full-part .recipes-normal-part .breadcrumb ol li:first-child:after {
  content: ' › ';
  padding-right: 10px;
}
.recipes-grid-box {
  display: flex;
  justify-content: space-between;
  width: 1500px;
  margin: 0 auto;
  padding: 0 12px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .recipes-grid-box {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recipes-grid-box {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .recipes-grid-box {
    max-width: 1100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .recipes-grid-box {
    max-width: 95%;
  }
}
@media only screen and (min-width: 1921px) {
  .recipes-grid-box {
    width: 92vw;
  }
}
@media only screen and (max-width: 479px) {
  .recipes-grid-box {
    flex-direction: column;
  }
}
.recipes-grid-box a {
  background: #E13F3F;
  color: white !important;
  padding: 14px;
  height: 50px;
  border-radius: 10px;
  text-decoration: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .recipes-grid-box a {
    display: flex;
    align-items: center;
    height: 85px;
  }
}
@media only screen and (max-width: 479px) {
  .recipes-grid-box a {
    height: auto;
    text-align: center;
    display: block;
  }
}
.cargo-area-full-part {
  float: left;
  width: 100%;
  margin-top: 50px;
  background: #E8E8E8;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cargo-area-full-part {
    margin-top: 20px;
  }
}
.cargo-area-full-part .cargo-area-normal-part {
  max-width: 1500px;
  padding: 20px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .cargo-area-full-part .cargo-area-normal-part {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cargo-area-full-part .cargo-area-normal-part {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .cargo-area-full-part .cargo-area-normal-part {
    max-width: 1100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cargo-area-full-part .cargo-area-normal-part {
    max-width: 700px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cargo-area-full-part .cargo-area-normal-part {
    max-width: 95%;
  }
}
@media only screen and (min-width: 1921px) {
  .cargo-area-full-part .cargo-area-normal-part {
    max-width: 92vw;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cargo-area-full-part .cargo-area-normal-part .cargo-grid-part > .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}
.cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-desc  {
  padding: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-desc  {
    width: auto;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-desc  {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-desc  img {
  float: left;
  height: 60px;
  padding: 10px;
}
.cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-desc  p {
  margin-bottom: 0;
}
.cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-bank {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-bank {
    width: auto;
  }
}
.cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-bank img {
  max-height: 70px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cargo-area-full-part .cargo-area-normal-part .cargo-grid-part .cargo-bank img {
    width: 100%;
    margin-top: 10px;
  }
}
.video-area-full-part {
  float: left;
  width: 100%;
  margin-top: 50px;
}
@media only screen and (max-width: 479px) {
  .video-area-full-part {
    margin-top: 20px;
  }
}
.video-area-full-part .video-area-normal-part {
  max-width: 1500px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .video-area-full-part .video-area-normal-part {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-full-part .video-area-normal-part {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .video-area-full-part .video-area-normal-part {
    max-width: 1100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area-full-part .video-area-normal-part {
    max-width: 700px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .video-area-full-part .video-area-normal-part {
    max-width: 95%;
  }
}
@media only screen and (min-width: 1921px) {
  .video-area-full-part .video-area-normal-part {
    max-width: 92vw;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box {
    margin-bottom: 0;
  }
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box img {
  max-width: 100%;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box img:hover {
  box-shadow: 6px 14px 32px 0 #b7b7b7;
  transform: translate(0, -10px);
  transition-delay: 0.2s !important;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box button {
  background: none;
  border: none;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box button:focus {
  box-shadow: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box button {
    padding: 0;
  }
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box div {
  border-radius: 20px;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box div:first-child {
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box div:first-child {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-left-box div:first-child {
    margin-bottom: 0;
  }
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box img {
  max-width: 100%;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box img:hover {
  box-shadow: 6px 14px 32px 0 #b7b7b7;
  transform: translate(0, -10px);
  transition-delay: 0.2s !important;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box button {
  background: none;
  border: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box button {
    padding: 0;
  }
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box button:focus {
  box-shadow: none;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box div {
  border-radius: 20px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box div {
    margin-bottom: 10px;
  }
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box a {
  text-decoration: none;
  color: white;
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box {
  margin-bottom: 0;
  background: #313131;
  display: flex;
  justify-content: space-between;
  height: 152px;
  align-items: center;
  color: white;
  padding: 0 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box {
    padding: 0 10px;
    height: 100px;
  }
}
@media only screen and (max-width: 479px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box {
    height: 100px;
  }
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box img {
  border-radius: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box img {
    height: 30px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box img {
    height: 20%;
  }
}
@media only screen and (max-width: 479px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box img {
    height: 15%;
  }
}
.video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box span {
  width: 230px;
  font-size: 26px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box span {
    font-size: 18px;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box span {
    font-size: 16px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .video-area-full-part .video-area-normal-part .video-area-grid-part .video-area-right-box .youtube-link-box span {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.home-comment-full-part {
  float: left;
  width: 100%;
  background-image: url('../images/sizdenGelenler.webp');
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-comment-full-part {
    height: 300px;
  }
}
@media only screen and (max-width: 479px) {
  .home-comment-full-part {
    height: 250px;
  }
}
.home-comment-full-part .home-comment-normal-part {
  max-width: 800px;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-comment-full-part .home-comment-normal-part {
    max-width: 600px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .home-comment-full-part .home-comment-normal-part {
    max-width: 500px;
  }
}
@media only screen and (max-width: 479px) {
  .home-comment-full-part .home-comment-normal-part {
    max-width: fit-content;
    width: 95%;
    max-width: 320px;
  }
}
.home-comment-full-part .home-comment-normal-part .comment-box {
  width: 80%;
  float: left;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .home-comment-full-part .home-comment-normal-part .comment-box {
    width: 100%;
    padding: 10px;
  }
}
.home-comment-full-part .home-comment-normal-part .comment-box p,
.home-comment-full-part .home-comment-normal-part .comment-box h6,
.home-comment-full-part .home-comment-normal-part .comment-box span {
  float: left;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-comment-full-part .home-comment-normal-part .comment-box {
    float: left;
    width: 70%;
  }
  .home-comment-full-part .home-comment-normal-part .comment-box p,
  .home-comment-full-part .home-comment-normal-part .comment-box h6,
  .home-comment-full-part .home-comment-normal-part .comment-box span {
    float: left;
    width: 100%;
  }
}
.home-comment-full-part .home-comment-normal-part .swiper-container {
  background-image: url('../images/yorumSllider.webp');
  height: 320px;
  position: relative;
  top: 50%;
  box-shadow: 0 5px 10px 0 rgba(167, 167, 167, 0.8);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-comment-full-part .home-comment-normal-part .swiper-container {
    height: 200px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .home-comment-full-part .home-comment-normal-part .swiper-container {
    height: 230px;
  }
}
@media only screen and (max-width: 479px) {
  .home-comment-full-part .home-comment-normal-part .swiper-container {
    height: 310px;
    top: 10%;
  }
}
.home-comment-full-part .home-comment-normal-part .swiper-container .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-comment-full-part .home-comment-normal-part .swiper-container .swiper-button-next {
  right: 20px;
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .home-comment-full-part .home-comment-normal-part .swiper-container .swiper-button-next {
    display: none;
  }
}
.home-comment-full-part .home-comment-normal-part .swiper-container .swiper-button-next:after {
  content: url('../images//categoryNext.svg');
}
.home-comment-full-part .home-comment-normal-part .swiper-container .swiper-button-prev {
  left: 20px;
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .home-comment-full-part .home-comment-normal-part .swiper-container .swiper-button-prev {
    display: none;
  }
}
.home-comment-full-part .home-comment-normal-part .swiper-container .swiper-button-prev:after {
  content: url('../images//categoryPrev.svg');
}
.home-comment-full-part .home-comment-normal-part .swiper-container .swiper-pagination-bullet-active {
  background: #E13F3F;
}
.home-comment-full-part .home-comment-normal-part h6 {
  float: left;
  width: 750px;
}
.home-comment-full-part .home-comment-normal-part .comment-border {
  width: 500px;
  height: 2px;
  float: left;
  text-align: center;
  background: black;
}
.home-comment-full-part .home-comment-normal-part p {
  float: left;
  width: 650px;
}
.home-discription-full-part {
  float: left;
  width: 100%;
  margin-top: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-discription-full-part {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-discription-full-part {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .home-discription-full-part {
    margin-top: 100px;
    margin-bottom: 40px;
  }
}
.home-discription-full-part .home-discription-normal-part {
  max-width: 1500px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .home-discription-full-part .home-discription-normal-part {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-discription-full-part .home-discription-normal-part {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .home-discription-full-part .home-discription-normal-part {
    max-width: 1100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-discription-full-part .home-discription-normal-part {
    max-width: 95%;
  }
}
@media only screen and (min-width: 1921px) {
  .home-discription-full-part .home-discription-normal-part {
    max-width: 92vw;
  }
}
.home-discription-full-part .home-discription-normal-part .home-discription-grid-part {
  position: relative;
  top: 55px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part {
    top: 30px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part {
    top: 0;
  }
}
.home-discription-full-part .home-discription-normal-part .home-discription-grid-part > .row {
  justify-content: center;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part > .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part > .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
}
.home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box {
  background: white;
  margin-left: 15px;
  padding: 20px;
  border-radius: 10px;
  max-width: calc(25% - 15px);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box {
    width: calc(50% - 15px);
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box {
    width: 100%;
    max-width: unset;
    padding: 5px;
    margin: 0;
    font-size: 14px;
  }
}
.home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box .home-discription-icon-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box .home-discription-icon-box {
    flex-direction: column-reverse;
  }
}
.home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box .home-discription-icon-box img {
  height: 50px;
}
@media only screen and (max-width: 479px) {
  .home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box .home-discription-icon-box img {
    height: 32px;
  }
}
.home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box .home-discription-icon-box h6 {
  color: #E13F41;
}
.home-discription-full-part .home-discription-normal-part .home-discription-grid-part .home-discription-box span {
  float: left;
  width: 100%;
}
.home-discription-full-part .home-discription-normal-part .home-discription-seo-box  {
  margin: 10px 0;
  padding: 0;
}
.home-discription-full-part .home-discription-normal-part .home-discription-seo-box  h1 {
  font-size: 20px;
}
.footer-full-part {
  float: left;
  width: 100%;
  background-image: url('../images/background.webp');
}
.footer-full-part .footer-normal-part {
  max-width: 1500px;
  margin-top: 120px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .footer-full-part .footer-normal-part {
    max-width: 1300px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-full-part .footer-normal-part {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-full-part .footer-normal-part {
    max-width: 1100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .footer-full-part .footer-normal-part {
    max-width: 95%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .footer-full-part .footer-normal-part {
    max-width: 100%;
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1921px) {
  .footer-full-part .footer-normal-part {
    max-width: 1500px;
  }
}
.footer-full-part .footer-normal-part .footer-category-box {
  padding: 0;
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .footer-full-part .footer-normal-part .footer-category-box {
    display: none;
  }
}
.footer-full-part .footer-normal-part .footer-category-box img {
  height: 130px;
}
.footer-full-part .footer-normal-part .footer-category-box ul {
  float: left;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.footer-full-part .footer-normal-part .footer-category-box ul li {
  float: left;
  list-style: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part .footer-category-box ul li {
    font-size: 13px;
  }
}
.footer-full-part .footer-normal-part .footer-category-box ul li a {
  text-decoration: none;
}
.footer-full-part .footer-normal-part .footer-category-box p {
  float: left;
  width: 100%;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part .footer-category-box p {
    font-size: 13px;
  }
}
.footer-full-part .footer-normal-part .footer-link-box {
  display: flex;
  padding: 0;
  overflow: hidden;
  justify-content: space-between;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .footer-full-part .footer-normal-part .footer-link-box {
    display: none;
  }
}
.footer-full-part .footer-normal-part .footer-link-box ul {
  max-width: 25%;
  padding: 0;
  overflow: hidden;
  margin-top: 40px;
}
.footer-full-part .footer-normal-part .footer-link-box ul h5 {
  color: #E23F3F;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part .footer-link-box ul h5 {
    font-size: 16px;
  }
}
.footer-full-part .footer-normal-part .footer-link-box ul li {
  list-style: none;
}
.footer-full-part .footer-normal-part .footer-link-box ul li a {
  text-decoration: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part .footer-link-box ul li {
    font-size: 13px;
  }
}
.footer-full-part .footer-normal-part .footer-link-box ul span {
  float: left;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part .footer-link-box ul span {
    font-size: 13px;
  }
}
.footer-full-part .footer-normal-part .footer-link-box .footer-sociial-box li {
  margin-top: 20px;
  float: left;
  padding-right: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part .footer-link-box .footer-sociial-box li {
    padding-right: 10px;
  }
}
.footer-full-part .footer-normal-part .footer-link-box .footer-sociial-box li:last-child {
  padding-right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-full-part .footer-normal-part .footer-link-box .footer-sociial-box li img {
    height: 30px;
  }
}
.footer-full-part .footer-normal-part .footer-link-box .social-icon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
@media only screen and (max-width: 479px) {
  .footer-full-part .footer-normal-part .footer-link-box .social-icon {
    margin-top: 10px;
  }
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion {
  background-color: initial;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item {
  background: none;
  border: none;
  background-color: initial;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-button:not(.collapsed) {
  color: black;
  box-shadow: none;
  background: none;
  border-bottom: 1px solid gainsboro;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-button {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-button:after {
  content: none;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-button:before {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url('../images//acordionIcon.svg');
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item button[aria-expanded="true"] {
  background-color: initial;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item button[aria-expanded="true"]:focus {
  color: black;
  background-color: initial;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item button[aria-expanded="true"]:focus-visible {
  background-color: initial;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item button[aria-expanded="true"] span {
  color: black;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item button[aria-expanded="true"]::before {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  transform: rotate(90deg);
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item button {
  background-color: initial;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item li {
  list-style: none;
  text-align: left;
  line-height: 28px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item li {
    line-height: 40px;
  }
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-body {
  background-color: white;
  border-radius: 0 0 20px 20px;
  padding: 10px 0;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-body a {
  text-decoration: none;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .accordion .accordion-item .accordion-header {
  border-bottom: 1px solid gainsboro;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .footer-sociial-box {
  padding: 10px;
  width: 100%;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .footer-sociial-box h5 {
  font-size: 16px;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .footer-sociial-box span {
  font-size: 14px;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .footer-sociial-box li {
  margin-top: 10px;
  list-style: none;
  float: left;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .footer-full-part .footer-normal-part .mobile-footer-grid-box .footer-sociial-box li span {
    float: left;
    width: 100%;
  }
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box .footer-sociial-box .social-icon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0;
}
.footer-full-part .footer-normal-part .mobile-footer-grid-box p {
  float: left;
  width: 100%;
  margin-top: 20px;
  padding: 0 10px;
  margin-bottom: 0;
}
.footer-full-part .footer-normal-part .footer-mazaka {
  text-align: center;
  padding: 10px;
}
.site-loading {
  display: flex;
  justify-content: center;
}
.site-loading img {
  height: 36px;
}
.mySwiper .swiper-pagination-bullets .swiper-pagination-bullet {
  margin-top: 10px !important;
}
.lezzetler-card-box {
  float: left;
  width: 100%;
}
.lezzetler-card-box .card {
  float: left;
  width: 100%;
  margin-right: 29px;
  box-shadow: 0 2px 15px 0 rgba(177, 177, 177);
  border-radius: 13px;
  margin-bottom: 15px;
  margin-left: 10px;
  height: 100%;
  max-height: 420px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-card-box .card {
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
    max-height: 380px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-card-box .card {
    max-height: 480px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lezzetler-card-box .card {
    max-height: 320px;
  }
}
.lezzetler-card-box .card:last-child {
  margin-right: 0;
}
.lezzetler-card-box .card button {
  padding: 0;
  background: none;
  border: none;
  max-height: 330px;
  height: 295px;
}
.lezzetler-card-box .card button:focus {
  box-shadow: none;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .lezzetler-card-box .card button {
    height: 300px;
    display: flex;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .lezzetler-card-box .card button {
    height: 210px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lezzetler-card-box .card button {
    height: auto;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-card-box .card button {
    height: auto;
  }
}
.lezzetler-card-box .card button img {
  border-radius: 13px 13px 0 0;
  height: 100%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .lezzetler-card-box .card button img {
    min-height: 162px;
  }
}
.lezzetler-card-box .card button:focus:not(:focus-visible) {
  outline: 0;
  border: none !important;
}
.lezzetler-card-box .card .card-body a {
  text-decoration: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-card-box .card .card-body a {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lezzetler-card-box .card .card-body a {
    font-size: 14px;
  }
}
.lezzetler-card-box .card .card-body a .card-title {
  font-size: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lezzetler-card-box .card .card-body a .card-title {
    font-size: 16px;
  }
}
.lezzetler-card-box .card .card-body a .card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 14px;
  height: 62px;
  min-height: 62px;
}
.lezzetler-card-box .mySwiper {
  padding: 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-card-box .mySwiper {
    overflow: hidden;
  }
}
.lezzetler-card-box .mySwiper .swiper-pagination-bullets .swiper-pagination-bullet {
  margin-top: 10px !important;
}
.lezzetler-card-box .mySwiper .swiper-pagination-bullets {
  bottom: 0;
}
.lezzetler-card-box .mySwiper .swiper-wrapper {
  padding: 20px 0;
}
.lezzetler-card-box .swiper-slide {
  height: 100%;
}
.lezzetler-card-box .swiper-button-next {
  right: 60pxx;
}
@media only screen and (max-width: 479px) {
  .lezzetler-card-box .swiper-button-next {
    display: none;
  }
}
.lezzetler-card-box .swiper-button-next:after {
  content: url('../images//Next.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-card-box .swiper-button-next:after {
    background-image: url('../images//Next.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .lezzetler-card-box .swiper-button-next:after {
    background-size: 26px 50px;
    background-repeat: no-repeat;
  }
}
.lezzetler-card-box .swiper-button-prev {
  left: 60px;
}
@media only screen and (max-width: 479px) {
  .lezzetler-card-box .swiper-button-prev {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lezzetler-card-box .swiper-button-prev {
    left: -20px;
  }
}
.lezzetler-card-box .swiper-button-prev:after {
  content: url('../images//prev.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-card-box .swiper-button-prev:after {
    background-image: url('../images//prev.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .lezzetler-card-box .swiper-button-prev:after {
    background-size: 26px 50px;
    background-repeat: no-repeat;
  }
}
.lezzetler-card-box .swiper-pagination-bullet-active {
  background: #E13F3F;
}
.validation-error {
  padding: 5px;
  font-size: 13px;
  color: #dc3545;
}
.modal.show[aria-modal="true"] {
  background: #0000008a;
}
#popover-basic {
  background: white;
}
.delete-address-modal.show {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.album-img {
  float: left;
  width: 100%;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.tarifler-modal iframe {
  min-height: 500px;
}
.modal.show {
  z-index: 9999999;
}
.globalClass_5c43,
jdiv {
  bottom: 0;
  top: auto;
}
.product-box {
  width: 100%;
  min-height: 480px;
  margin-right: 20px;
  margin-bottom: 20px;
  float: left;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.17);
  background: white;
  border-radius: 13px;
  transition: all 0.5s ease-in-out;
}
.product-box:hover {
  box-shadow: 0px 7px 20px 0px #b7b7b7;
  transform: translate(0, -10px);
}
@media only screen and (max-width: 479px) {
  .product-box:hover {
    transform: none;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .product-box {
    width: calc(33% - 30px);
    min-height: 480px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-box {
    width: calc(33.3333% - 20px);
    min-height: 460px;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-box {
    min-height: 480px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-box {
    width: calc(33.333% - 10px);
    margin-right: 10px;
    min-height: 350px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box {
    width: 100%;
    min-height: unset;
    margin-bottom: 20px;
    min-height: unset !important;
    transition: none;
    border: 1px solid #ededed;
    box-shadow: none;
  }
  .product-box:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .product-box {
    width: calc(50% - 10px);
    min-height: 360px;
    margin-bottom: 20px;
  }
  .product-box:nth-child(2n) {
    margin-right: 0;
  }
}
.product-box a {
  float: left;
  width: 100%;
}
.product-box .product-cart-image-box {
  float: left;
  width: 100%;
}
.product-box .product-cart-image-box span {
  width: 100%;
}
.product-box .product-cart-image-box img {
  width: 100%;
  border-radius: 13px 13px 0 0 ;
  min-height: 180px;
  max-height: 220px;
  object-fit: cover;
}
.product-box .product-cart-desc {
  float: left;
  width: 100%;
  background: white;
  border-radius: 0 0 10px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 20px;
  padding: 15px 0;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .product-box .product-cart-desc {
    min-height: 214px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box .product-cart-desc {
    height: auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-box .product-cart-desc {
    height: 240px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-box .product-cart-desc {
    height: auto;
  }
}
.product-box .product-cart-desc A {
  text-decoration: none;
}
.product-box .product-cart-desc h3 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
  min-height: 48px;
  padding: 10px 25px 0 25px;
  color: #6b6b6b;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-box .product-cart-desc h3 {
    padding: 10px;
    font-size: 18px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-box .product-cart-desc h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    min-height: fit-content;
  }
}
@media only screen and (max-width: 479px) {
  .product-box .product-cart-desc h3 {
    min-height: unset;
    height: auto;
    padding-bottom: 2px;
    overflow: initial;
  }
}
.product-box .product-cart-desc .product-price {
  padding: 0 10px;
  margin-top: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-box .product-cart-desc .product-price {
    min-height: 30px;
    padding: 0 10px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box .product-cart-desc .product-price {
    min-height: unset;
    height: auto;
    margin-top: 0;
  }
}
.product-box .product-cart-desc .product-price .eski-fiyat {
  color: #D15A5E;
  font-size: 20px;
  margin-bottom: 0px;
  text-decoration: line-through;
  padding-left: 10px;
}
.product-box .product-cart-desc .product-price .fiyat {
  color: #23BE72;
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 800;
  padding-left: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-box .product-cart-desc .product-price .fiyat {
    font-size: 20px;
  }
}
.product-box .product-cart-desc .product-count {
  float: left;
  width: 100%;
  padding: 0px 25px 10px;
  font-size: 13px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-box .product-cart-desc .product-count {
    font-size: 13px;
    padding: 10px;
    padding-top: 0;
  }
}
.product-box .product-cart-desc .product-count span {
  float: left;
  color: #a2a2a2;
}
.product-box .product-add-to-cart {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto auto;
}
@media only screen and (max-width: 479px) {
  .product-box .product-add-to-cart {
    width: 98%;
    padding: 10px 5px;
  }
}
.product-box .product-add-to-cart .add-to-cart {
  border-radius: 10px;
  width: 45%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-box .product-add-to-cart .add-to-cart {
    width: 45%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-box .product-add-to-cart .add-to-cart {
    width: 48%;
  }
}
@media only screen and (max-width: 479px) {
  .product-box .product-add-to-cart .add-to-cart {
    width: 49%;
  }
}
.product-box .product-add-to-cart .add-to-cart button {
  background: #23BE72;
  border-color: #23BE72;
  height: 50px;
  border-radius: 10px;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-box .product-add-to-cart .add-to-cart button {
    font-size: 13px;
    height: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .product-box .product-add-to-cart .add-to-cart button {
    font-size: 13px;
  }
}
.product-box .product-add-to-cart .numbers-row {
  background: #F9F6F5;
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-box .product-add-to-cart .numbers-row {
    width: 52%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-box .product-add-to-cart .numbers-row {
    width: 50%;
    font-size: 13px;
    height: 40px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box .product-add-to-cart .numbers-row {
    width: 49%;
  }
}
.product-box .product-add-to-cart .numbers-row .dec {
  float: left;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 10px 0 0 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-box .product-add-to-cart .numbers-row .dec {
    height: 40px;
  }
}
.product-box .product-add-to-cart .numbers-row .inc {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border: none;
  border-left: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 0 10px 10px 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-box .product-add-to-cart .numbers-row .inc {
    height: 40px;
  }
}
.product-box .product-add-to-cart .numbers-row span {
  color: #343132;
  text-align: center;
  font-size: 13px;
}
.product-box .product-add-to-cart .numbers-row .input-are {
  max-width: 40%;
  float: left;
  height: 40px;
}
.product-box .product-add-to-cart .numbers-row .input-are .productQuantityTextBox {
  float: left;
  width: 100%;
  height: 26px;
  background: #F9F6F5;
  text-align: center;
  border: none;
}
.product-box .product-add-to-cart .numbers-row .input-are span {
  float: left;
  width: 100%;
  text-align: center;
}
.category .product-box {
  min-height: fit-content;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category .product-box {
    min-height: 430px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .category .product-box {
    min-height: auto;
  }
}
.category .product-cart-desc {
  height: auto;
}
@media only screen and (max-width: 479px) {
  .category .product-cart-desc {
    height: auto;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product-add-to-cart-modal .modal-dialog {
    margin: 0;
    float: left;
    width: 100%;
  }
}
.product-add-to-cart-modal .modal-body {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
}
.product-add-to-cart-modal .modal-body .product-cart-image-box {
  display: flex;
  align-items: center;
}
.product-add-to-cart-modal .modal-body .product-cart-image-box img {
  height: 120px;
  border-radius: 10px;
}
@media only screen and (max-width: 479px) {
  .product-add-to-cart-modal .modal-body .product-cart-image-box img {
    height: 70px;
  }
}
.product-add-to-cart-modal .modal-body .product-cart-image-box h3 {
  padding-left: 10px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: normal;
}
.product-add-to-cart-modal .modal-body .product-cart-image-box svg {
  height: 80px;
}
.product-add-to-cart-modal .modal-body .product-name-unit span {
  padding-left: 5px;
  color: #8c8c8c;
}
.product-add-to-cart-modal .modal-body .product-price {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-add-to-cart-modal .modal-body .product-price {
    height: auto;
  }
}
@media only screen and (max-width: 479px) {
  .product-add-to-cart-modal .modal-body .product-price {
    min-width: 120px;
  }
}
.product-add-to-cart-modal .modal-body .product-price .eski-fiyat {
  color: #D15A5E;
  font-size: 20px;
  margin-bottom: 0px;
  text-decoration: line-through;
}
.product-add-to-cart-modal .modal-body .product-price .fiyat {
  color: #23BE72;
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 800;
}
.product-add-to-cart-modal .modal-body .siparis-tekrarla {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.product-add-to-cart-modal .modal-body .siparis-tekrarla .order-list-modal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.product-add-to-cart-modal .modal-body .siparis-tekrarla .order-list-modal .product-price {
  min-width: 150px;
}
.product-add-to-cart-modal .modal-body .siparis-tekrarla .order-list-modal .cart-image {
  min-width: 200px;
  object-fit: contain;
}
.product-add-to-cart-modal .modal-content {
  width: 750px;
  margin: 0 auto;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-add-to-cart-modal .modal-content {
    width: 100%;
  }
}
.product-add-to-cart-modal .modal-header {
  flex-direction: column-reverse;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-add-to-cart-modal .modal-header svg {
    height: 80px;
  }
}
@media only screen and (max-width: 479px) {
  .product-add-to-cart-modal .modal-header svg {
    height: 60px;
  }
}
.product-add-to-cart-modal .modal-header button {
  background-color: #9c9c9c;
}
.product-add-to-cart-modal .modal-footer {
  justify-content: center;
}
.product-add-to-cart-modal .modal-footer .link-box {
  display: flex;
  flex-direction: column;
}
.product-add-to-cart-modal .modal-footer .link-box button {
  background: none;
  border: none;
  box-shadow: none;
  color: black;
  text-decoration: underline;
}
.product-add-to-cart-modal .modal-footer .link-box a {
  background: #23BE72;
  padding: 10px 40px;
  border-radius: 5px;
  color: white !important;
  text-decoration: none;
  text-align: center;
}
.categories-full-part {
  float: left;
  width: 100%;
}
.categories-full-part .categories-normal-part {
  max-width: 1500px;
}
@media screen and (min-width: only screen and (min-width: 1366px) and (max-width: 1700px)) {
  .categories-full-part .categories-normal-part {
    top: -20px;
    position: relative;
    width: 96%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-full-part .categories-normal-part {
    top: -20px;
    position: relative;
    width: 96%;
  }
}
@media only screen and (max-width: 479px) {
  .categories-full-part .categories-normal-part div.subConMenu {
    top: -10px;
    position: relative;
  }
}
.categories-full-part .categories-normal-part .cat-sub-categories {
  display: flex;
  list-style-type: none;
  gap: 15px;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  margin: 40px 0px 50px 0px;
  flex-wrap: wrap;
}
.categories-full-part .categories-normal-part .cat-sub-categories li {
  display: flex !important;
  text-wrap: nowrap;
  text-decoration: none !important;
  border-radius: 5px;
  width: 91px;
  flex-direction: column;
  height: 105px;
}
.categories-full-part .categories-normal-part .cat-sub-categories li a {
  padding: 5px 10px;
  text-decoration: none !important;
  color: blue !important;
  font-size: 14px;
}
.categories-full-part .categories-normal-part .cat-sub-categories li a img {
  border-radius: 6px;
  width: 75px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  flex-wrap: nowrap;
  text-align: center;
  margin: auto;
  margin-bottom: 8px;
  box-shadow: rgb(165 142 107) -2px 3px 14px 0px, rgb(255 255 255) 1px 6px 8px 9px;
}
.categories-full-part .categories-normal-part .cat-sub-categories li a span {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  text-wrap: wrap;
  font-family: system-ui;
  font-size: smaller;
  color: black;
}
@media only screen and (max-width: 479px) {
  .categories-full-part .categories-normal-part .cat-sub-categories {
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 10px 0px !important;
    gap: 4px 6px;
  }
  .categories-full-part .categories-normal-part .cat-sub-categories li {
    border-radius: 3px;
  }
  .categories-full-part .categories-normal-part .cat-sub-categories li a {
    padding: 5px 8px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-full-part .categories-normal-part .cat-sub-categories {
    margin: 0px 0px 25px 0px !important;
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1336px) {
  .categories-full-part .categories-normal-part .cat-sub-categories {
    margin: 0px 0px 25px 0px !important;
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}
.categories-full-part .categories-normal-part .categories-product {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-full-part .categories-normal-part .categories-product {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 480px) and (max-width: 767px) {
  .categories-full-part .categories-normal-part .categories-product {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 479px) {
  .categories-full-part .categories-normal-part .categories-product {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .categories-full-part .categories-normal-part .categories-product {
    top: 50px;
    position: relative;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-full-part .categories-normal-part .categories-product {
    top: 50px;
    position: relative;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-full-part .categories-normal-part .categories-product {
    top: 0px !important;
  }
}
.categories-full-part .categories-normal-part .product-box {
  float: left;
  margin-right: 0;
  margin-bottom: 0;
  min-width: unset;
  width: 100%;
}
.categories-full-part .categories-normal-part .product-box .product-cart-image-box img {
  max-height: 236px;
  object-fit: cover;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categories-full-part .categories-normal-part .product-box .product-cart-image-box img {
    max-height: 200px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categories-full-part .categories-normal-part .product-box {
    min-height: 450px;
  }
}
.categories-full-part .categories-normal-part h1 {
  text-transform: capitalize;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .categories-full-part .categories-normal-part h1 {
    font-size: 1.5rem;
  }
}
.categories-full-part .categories-normal-part .breadcrumb {
  display: flex;
  padding: 10px 0;
}
.categories-full-part .categories-normal-part .breadcrumb ol {
  display: flex;
  padding: 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .categories-full-part .categories-normal-part .breadcrumb ol {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-y: hidden;
    border-radius: 10px 10px 0 0;
  }
}
.categories-full-part .categories-normal-part .breadcrumb ol li {
  list-style: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .categories-full-part .categories-normal-part .breadcrumb ol li {
    min-width: fit-content;
  }
}
.categories-full-part .categories-normal-part .breadcrumb ol li:first-child:after {
  content: ' › ';
  padding-right: 10px;
}
.categories-full-part .categories-normal-part .breadcrumb a {
  padding-right: 10px;
  text-decoration: none;
  text-transform: capitalize;
}
.categories-full-part .categories-normal-part .breadcrumb a:hover {
  color: #23BE72 !important;
  font-weight: 600;
}
.categories-full-part .categories-normal-part .breadcrumb span {
  padding-right: 10px;
}
.categories-full-part .category-discription-seo-box {
  float: left;
  max-width: 1500px;
  margin: 20px auto;
  margin-top: 40px;
  width: 100%;
}
@media (min-width: 768px) {
  .categories-full-part .category-discription-seo-box {
    margin-top: 120px !important;
  }
}
.categories-full-part .pastirma-tanitim a {
  transition: all 0.5s;
  cursor: pointer;
  padding: 10px 50px;
  background: #E13F3F;
  border-color: #E13F3F;
  color: white !important;
  border-radius: 5px;
}
.categories-full-part .pastirma-tanitim a span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.categories-full-part .pastirma-tanitim a span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.categories-full-part .pastirma-tanitim a:hover span {
  padding-right: 25px;
}
.categories-full-part .pastirma-tanitim a:hover span:after {
  opacity: 1;
  right: 0;
}
.breadcrumb {
  display: flex;
  padding: 10px 0;
}
.breadcrumb a {
  padding-right: 10px;
  text-decoration: none;
  text-transform: capitalize;
}
.breadcrumb a:hover {
  color: #23BE72 !important;
  font-weight: 600;
}
.breadcrumb span {
  padding-right: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb span {
    font-size: 12px;
  }
}
.lezzetler-category .breadcrumb {
  padding: 10px 0;
}
.lezzetler-category .modal-body iframe {
  height: 500px;
}
.lezzetler-category .lezzetler-card-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lezzetler-category .lezzetler-card-box {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .lezzetler-category .lezzetler-card-box {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .lezzetler-category .lezzetler-card-box {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }
}
@media only screen and (max-width: 479px) {
  .lezzetler-category .lezzetler-card-box .card {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-full-part {
    height: 110px;
  }
}
.header-full-part .header-category-list ul li:nth-child(7) {
  background: url(https://www.sahin724.com/images/cat-bg-mobil.webp?v1) no-repeat left center;
  background-size: contain;
}
.header-full-part .header-category-list ul li:nth-child(7) a {
  color: white !important;
  padding-left: 10px;
}
.offcanvas-start .offcanvas-body ul a:nth-child(7) li {
  color: red;
  font-weight: bold;
}
.categories-full-part .categories-normal-part {
  max-width: 1500px;
  background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
  background-size: contain;
  padding-top: 31px;
  width: 98%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-full-part .categories-normal-part {
    background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
    background-size: contain;
    padding-top: 31px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .categories-full-part .categories-normal-part {
    background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
    background-size: contain;
    padding-top: 22px;
  }
}
@media only screen and (max-width: 479px) {
  .categories-full-part .categories-normal-part {
    background: url(https://www.sahin724.com/images/cat-bg-mobil.webp) no-repeat 12px 9px;
    background-size: 350px;
    padding-top: 8px;
  }
}
.categories-full-part .categories-normal-part h1 {
  color: white;
  margin-left: 14px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categories-full-part .categories-normal-part h1 {
    padding-top: 8px;
    width: 98%;
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-full-part .categories-normal-part h1 {
    color: white;
    top: -10px;
    position: relative;
    font-size: 16px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .categories-full-part .categories-normal-part h1 {
    color: white;
    top: -10px;
    position: relative;
  }
}
@media only screen and (max-width: 479px) {
  .categories-full-part .categories-normal-part h1 {
    color: white;
    margin: 12px 15px;
    font-size: 18px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .categories-full-part .categories-normal-part h1 {
    padding-top: 10px;
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-full-part .categories-normal-part h1 {
    font-size: 13px;
  }
}
.categories-full-part .categories-normal-part .breadcrumb {
  color: white;
  margin-left: 14px;
  top: -5px;
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categories-full-part .categories-normal-part .breadcrumb {
    top: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-full-part .categories-normal-part .breadcrumb {
    position: relative;
    top: -23px;
    font-size: 12px;
    height: 40px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .categories-full-part .categories-normal-part .breadcrumb {
    position: relative;
    top: -23px;
    font-size: 12px;
    height: 40px;
  }
}
@media only screen and (max-width: 479px) {
  .categories-full-part .categories-normal-part .breadcrumb {
    font-size: 12px;
    margin: 0px 15px;
    top: -12px;
    position: relative;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .categories-full-part .categories-normal-part .breadcrumb {
    font-size: 11px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-full-part .categories-normal-part .breadcrumb {
    font-size: 11px;
  }
}
.categories-full-part .categories-normal-part .breadcrumb a {
  color: white !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categories-full-part .categories-normal-part .breadcrumb a {
    font-size: 12px;
  }
}
.product-detail .App,
.category .App {
  height: auto;
  float: left;
  width: 100%;
  background-repeat: repeat;
}
.product-detail .modal-body iframe,
.category .modal-body iframe {
  min-height: 500px;
  width: 100%;
}
.product-detail-full-part {
  float: left;
  width: 100%;
}
.product-detail-full-part .product-slider-normal-part {
  float: left;
  max-width: 1500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part {
    max-width: unset;
    width: 98%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-left-box {
    max-width: unset;
    width: 60%;
    padding: 0;
    max-height: 390px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-left-box {
    width: 100%;
    padding: 0px;
    max-height: 300px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box {
  float: left;
  max-width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box {
    max-width: unset;
    width: 100%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box {
    max-width: unset;
    width: 100%;
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .mySwiper2 {
  height: 500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .mySwiper2 {
    height: auto;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .mySwiper2 {
    overflow: visible;
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .mySwiper2 img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .mySwiper2 img {
    object-fit: contain;
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .mySwiper .swiper-wrapper {
  height: 130px;
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-next {
  right: 30px;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-next {
    display: none;
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-next:after {
  content: url('../images//Next.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-next:after {
    background-image: url('../images//Next.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-prev {
  left: 30px;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-prev {
    display: none;
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-prev:after {
  content: url('../images//prev.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-button-prev:after {
    background-image: url('../images//prev.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-pagination-fraction {
  bottom: -30px !important;
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-pagination-bullet-active {
  background: #E13F3F;
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-container-thumbs {
  margin: 20px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-container-thumbs {
    display: none;
  }
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-container-thumbs .swiper-slide {
  height: 120px;
}
.product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-container-thumbs .swiper-slide img {
  max-height: 120px;
  border-radius: 10px;
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-container-thumbs .swiper-slide img {
    max-height: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-slider-box .swiper-container-thumbs .swiper-slide img {
    max-height: 100px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box {
  float: left;
  padding-left: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box {
    max-width: unset;
    width: 40%;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box {
    width: 100%;
    padding: 20px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box {
  float: left;
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box {
    display: flex;
    flex-direction: column-reverse;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box h1 {
  float: left;
  width: 70%;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box h1 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box h1 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box h1 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box h1 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box h1 {
    font-size: 24px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box .product-detail-rating {
  float: left;
  width: 30%;
  border-left: 2px solid gainsboro;
  padding-left: 10px;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box .product-detail-rating {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box .product-detail-rating p {
  float: left;
  width: 100%;
  margin-bottom: 0;
  color: #999999;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box .product-detail-rating > div {
  float: left;
  width: 100%;
  color: #999999;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box .product-detail-rating > div {
    text-align: end;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box .product-detail-rating > div .react-rater-star {
  color: #FEB53E;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-desc-box .product-detail-rating > div .reviews {
  float: left;
  width: 100%;
  font-size: 12px;
  text-decoration: underline;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row {
  background: #F9F6F5;
  width: 45%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 4px #42424252;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row .dec {
  float: left;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 0 4px #42424252;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row .inc {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border: none;
  border-left: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 0 10px 10px 0px;
  box-shadow: 0 0 4px #42424252;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row span {
  color: #343132;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row span {
    font-size: 12px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row .input-are {
  max-width: 40%;
  float: left;
  height: 40px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row .input-are .productQuantityTextBox {
  float: left;
  width: 100%;
  height: 26px;
  background: #F9F6F5;
  text-align: center;
  border: none;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .numbers-row .input-are span {
  float: left;
  width: 100%;
  text-align: center;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .product-price {
    text-align: center;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .product-price .eski-fiyat {
  color: #D15A5E;
  font-size: 20px;
  margin-bottom: 0px;
  text-decoration: line-through;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .product-price .fiyat {
  color: #23BE72;
  font-size: 32px;
  margin-bottom: 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .product-price .fiyat {
    font-size: 26px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .save-buton {
  display: flex;
  flex-direction: column;
  background: none;
  color: black;
  border: none;
  align-items: center;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .save-buton img {
  height: 42px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-price-and-save .save-buton span {
  float: left;
  width: 100%;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .add-to-cart {
  width: 100%;
  float: left;
  margin: 20px 0;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .add-to-cart button {
  background: #23BE72;
  border-color: #23BE72;
  height: 50px;
  width: 100%;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .campaign-box {
  background: #A0A0A0;
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: center;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .campaign-box img {
  padding-right: 20px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .campaign-box p {
  margin-bottom: 0;
  height: auto;
  font-size: 22px;
  color: white;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-cargo-area {
  float: left;
  width: 100%;
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-cargo-area .custom-cargo {
  float: left;
  width: calc(50% - 20px);
  display: flex;
  align-items: center;
  margin: 10px;
  height: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-cargo-area .custom-cargo {
    width: 100%;
    margin: 0px;
  }
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-cargo-area .custom-cargo span {
    font-size: 12px;
  }
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-cargo-area .custom-cargo {
    width: 100%;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-cargo-area .custom-cargo img {
  padding-right: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-cargo-area .custom-cargo img {
    width: 30px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-sort-desc-box {
  float: left;
  margin-top: 20px;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-right-box .product-detail-sort-desc-box {
    margin-top: 5px;
    font-size: 15px;
  }
}
.product-detail-full-part .product-slider-normal-part .breadcrumb {
  z-index: 100;
  padding: 0 15px;
}
.product-detail-full-part .product-slider-normal-part .breadcrumb ol {
  padding: 0;
  display: flex;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .breadcrumb ol {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-y: hidden;
    border-radius: 10px 10px 0 0;
  }
}
.product-detail-full-part .product-slider-normal-part .breadcrumb ol li {
  list-style: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .breadcrumb ol li {
    min-width: fit-content;
  }
}
.product-detail-full-part .product-slider-normal-part .breadcrumb ol li:first-child:after,
.product-detail-full-part .product-slider-normal-part .breadcrumb ol li:nth-child(2):after {
  content: ' › ';
  padding-right: 10px;
}
.product-detail-full-part .product-slider-normal-part .lezzetler-card-box {
  margin-top: 50px;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .lezzetler-card-box {
    margin-top: 20px;
  }
}
.product-detail-full-part .product-slider-normal-part .lezzetler-card-box h2 {
  margin-bottom: 20px;
  font-size: 22px;
  padding-left: 10px;
}
.product-detail-full-part .product-slider-normal-part .lezzetler-card-box h2 span {
  text-transform: capitalize;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .lezzetler-card-box h2 {
    font-size: 16px;
  }
}
.product-detail-full-part .product-slider-normal-part .lezzetler-card-box .swiper-container {
  padding: 10px;
}
.product-detail-full-part .product-slider-normal-part .lezzetler-card-box .swiper-button-next {
  right: 60px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs {
  margin-top: 30px;
  background: #F7F7F7;
  position: relative;
  display: inline-block;
  padding-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs {
    padding-top: 3px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button {
  padding: 10px 30px;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button {
    padding: 10px 5px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button.active {
  background: #555F69;
  color: white !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button.active span {
    font-size: 9px;
    padding-top: 3px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button.active span span:after {
  content: "";
  border: 20px solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  position: relative;
  top: 12px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button.active svg path {
  fill: white !important;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button span {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button span {
    font-size: 9px;
    padding-top: 3px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button span svg {
  height: 32px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-container button span svg {
    height: 15px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs iframe {
  border-radius: 10px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-header svg {
  height: 40px;
  width: 40px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-header button {
  background: white;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  flex-direction: row;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-header button:after {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  content: '';
  background-image: url('../images//acordionIcon.svg');
  background-repeat: no-repeat;
  background-size: 2rem;
  transition: transform 0.2s ease-in-out;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-header button:focus {
  box-shadow: none;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-header .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-header span span {
  padding-left: 20px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body {
  float: left;
  width: 100%;
  background: white;
  padding: 20px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .active,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .active {
  display: inline-table;
  position: relative;
  width: 100%;
  text-align: left;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content div.unconditionally,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body div.unconditionally {
    width: 100%;
    float: left;
    position: relative;
    display: inline-block;
  }
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content div.unconditionally div,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body div.unconditionally div {
    width: 100%;
    position: relative;
    display: inline-block;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin {
  float: left;
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin {
    flex-direction: column;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin .video-part,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin .video-part {
  float: left;
  width: 50%;
  padding: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin .video-part,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin .video-part {
    width: 100%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin .video-part,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin .video-part {
    width: 100%;
    margin-bottom: 20px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin .video-part iframe,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin .video-part iframe {
  width: 100%;
  border-radius: 10px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin .aciklama-part ,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin .aciklama-part  {
  float: left;
  width: 50%;
  padding-left: 10px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .neden-sahin .aciklama-part ,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .neden-sahin .aciklama-part  {
    width: 100%;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box {
    display: block;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box .customer-part,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box .customer-part {
  margin-top: auto;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box .customer-part,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box .customer-part {
    float: left;
    width: 33.33%;
    height: auto;
    min-height: 310px;
  }
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box .customer-part,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box .customer-part {
    width: 50%;
    min-height: 250px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box span,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box span {
  float: left;
  width: 100%;
  font-weight: 600;
  font-size: 40px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box span span,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box span span {
  float: left;
  width: auto;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box label,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box label {
  font-size: 22px;
  font-weight: 400;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .customer-box p,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .customer-box p {
  color: #333333;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 100%;
  justify-content: center;
  padding: 20px;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part {
    flex-direction: column;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part .list-group,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part .list-group {
  height: 100%;
  float: left;
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part .list-group,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part .list-group {
    width: 100%;
    margin-bottom: 10px;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part form,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part form {
  float: left;
  width: 50%;
  background: white;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid gainsboro;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part form,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part form {
    width: 100%;
    margin-left: 0;
  }
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part form label,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part form label {
  margin-left: 20px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part form button,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part form button {
  margin-top: 10px;
  background: #23BE72;
  border-color: #23BE72;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part form button:focus,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part form button:focus {
  box-shadow: none;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part svg,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part svg {
  height: 50px;
  width: 100%;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-grid-part svg path,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-grid-part svg path {
  fill: #23BE72;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-desc span,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-desc span {
  padding-left: 10px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .comment-date,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .comment-date {
  display: flex;
  justify-content: space-between;
  color: #8d8d8d;
  font-size: 14px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .unconditionally,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .unconditionally {
  display: flex;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .unconditionally > div,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .unconditionally > div {
  padding-right: 10px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .unconditionally iframe,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .unconditionally iframe {
  min-width: 600px;
  border-radius: 10px;
}
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .manti iframe,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .manti iframe,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .sucuk iframe,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .sucuk iframe,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .pastirma iframe,
.product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .pastirma iframe {
  width: calc(50% - 10px) !important;
  margin-right: 10px;
  float: left;
}
@media only screen and (max-width: 479px) {
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .manti iframe,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .manti iframe,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .sucuk iframe,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .sucuk iframe,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .tab-content .pastirma iframe,
  .product-detail-full-part .product-slider-normal-part .product-detail-tabs .accordion-body .pastirma iframe {
    width: 100% !important;
  }
}
.product-detail-full-part .product-slider-normal-part h2 {
  margin-top: 10px;
}
@media only screen and (min-width: 1921px) {
  .product-detail-full-part .product-slider-normal-part .recipes-full-part .recipes-normal-part {
    max-width: 1500px !important;
  }
}
.react-rater-star.is-active {
  color: #feb53e !important;
}
.react-rater-star.will-be-active {
  color: #feb53e !important;
}
@media only screen and (min-width: 1921px) {
  .recipes-normal-part {
    max-width: 1500px !important;
  }
}
.login-or-sginup .App,
.reset-password .App {
  background-image: none;
}
.login-or-sginup-full-part {
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}
@media only screen and (max-width: 479px) {
  .login-or-sginup-full-part {
    padding: 5px;
  }
}
.login-or-sginup-full-part .login-or-sginup-normal-part {
  max-width: 1500px;
  display: flex;
}
@media only screen and (max-width: 479px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part {
    max-width: unset;
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part {
    flex-direction: column;
    width: 100%;
  }
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container {
  width: 100%;
  background: #ffffff;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container {
    width: 100%;
  }
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li {
  float: left;
  width: 50%;
  text-align: center;
  border-radius: 10px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li:first-child {
  display: none;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li:last-child {
  z-index: 9999;
  font-size: 13px;
  position: absolute;
  bottom: -210px;
  right: 0;
  width: auto;
}
@media only screen and (max-width: 479px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li:last-child {
    bottom: -190px;
  }
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li:last-child button {
  border: none;
  box-shadow: none;
  background: none;
  text-decoration: underline;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li button {
  float: left;
  width: 100%;
  padding: 15px;
  color: black;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container {
  width: 90% !important;
  margin: 10px auto;
  border-bottom: none;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li:nth-child(2) {
  background-color: #e5e5e5 !important;
  margin: 5px 0 !important;
  width: 50% !important;
  border-radius: 8px 0 0 8px!important;
  padding: 3px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li:nth-child(3) {
  background-color: #e5e5e5 !important;
  margin: 5px 0 !important;
  width: 50% !important;
  border-radius: 0 8px 8px 0!important;
  padding: 3px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li button {
  background: none !important;
  border-color: #e5e5e5 !important;
  border-radius: 8px;
  color: #444444 !important;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-tab-container li button.active {
  background: white !important;
  color: #444444 !important;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content {
  float: left;
  width: 95%;
  margin: 0 2.5%;
  padding: 20px;
  background: white;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part .tab-content {
    width: 100%;
    padding: 10px;
  }
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .alert {
  display: flex;
  align-items: center;
  padding: 5px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .alert p {
  margin-bottom: 0;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .alert button {
  padding: 5px;
  margin-top: 5px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .form-control {
  height: 50px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form button {
  background: #23BE72;
  border-color: #23BE72;
  height: 50px;
  width: 100%;
  margin-top: 50px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form a {
  margin: 10px 0;
  float: right;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .form-check {
  float: left;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .sginup-camps {
  background: none;
  width: auto;
  float: left;
  color: black;
  display: block;
  border: none;
  padding: 0;
  padding-left: 10px;
  height: auto;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .sginup-camps:hover,
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .sginup-camps:focus,
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .sginup-camps:focus-visible {
  border: none;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .policy-checkbox {
  float: left;
  width: 100%;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .policy-checkbox > div {
  float: left;
  width: auto;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .campaign-input {
  display: flex;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .campaign-input input {
  border-radius: 10px !important;
  width: 30px;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .campaign-input input:focus {
  box-shadow: none !important;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .campaign-input input:checked {
  background-color: #F6F6F6 !important;
  border: 1px solid #F6F6F6 !important;
  box-shadow: none !important;
  background-blend-mode: difference;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .campaign-input button {
  margin-top: 0;
  text-align: left;
}
@media only screen and (max-width: 479px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part .tab-content form .campaign-input button {
    text-align: left;
  }
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .forgot-password .back-button {
  border: none;
  margin-bottom: 20px;
  background: whitesmoke !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px !important;
  min-width: auto !important;
  height: 50px !important;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .forgot-password .back-button:hover {
  background: #ececec;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .forgot-password .back-button:focus {
  box-shadow: none;
  border: none;
  background: none;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .forgot-password button {
  margin-top: 10px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .social-medya-login {
  float: left;
  width: 100%;
  display: flex;
  margin-left: calc(-1 * ((100% - 100%) / 2));
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  color: #B7B7B7;
  letter-spacing: 0.09px;
  font-size: 14px;
  margin: 20px 0;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .social-medya-login:before {
  content: "";
  border-top: 1px solid #B7B7B7;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-content .social-medya-login:after {
  content: "";
  border-top: 1px solid #B7B7B7;
  flex: 1 0 20px;
  margin: 0 0 0 20px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-input-box .email-input,
.login-or-sginup-full-part .login-or-sginup-normal-part .login-input-box .name-input,
.login-or-sginup-full-part .login-or-sginup-normal-part .login-input-box .password-input,
.login-or-sginup-full-part .login-or-sginup-normal-part .login-input-box .campaign-input,
.login-or-sginup-full-part .login-or-sginup-normal-part .login-input-box .surname-input {
  margin-bottom: 20px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-promotion-area {
  width: 50%;
  padding: 0 10px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part .login-promotion-area {
    width: 100%;
    margin-top: 20px;
  }
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-promotion-area h1 {
  font-size: 28px;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-promotion-area p {
  margin-bottom: 0;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .login-promotion-area iframe {
  height: 75%;
}
.login-or-sginup-full-part .login-or-sginup-normal-part .tab-container-part {
  width: 50%;
  background: white;
  padding: 30px;
  border-radius: 8px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .login-or-sginup-full-part .login-or-sginup-normal-part .tab-container-part {
    width: 100%;
    padding: 0;
  }
}
.modal-dialog {
  max-width: 1300px !important;
}
.forgot-password .login-or-sginup-normal-part li {
  display: none;
}
.sginup .login-or-sginup-normal-part li:last-child {
  display: none;
}
.sginup .tab-content button {
  margin-top: 0 !important;
}
.forgot-password-full-part {
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}
.forgot-password-full-part .forgot-password-normal-part {
  max-width: 1500px;
}
.forgot-password-full-part .forgot-password-normal-part .forgot-password-grid-part {
  max-width: 500px;
  background: white;
  padding: 20px;
}
.forgot-password-full-part .forgot-password-normal-part .forgot-password-grid-part h3 {
  margin-bottom: 20px;
}
.forgot-password-full-part .forgot-password-normal-part .forgot-password-grid-part form input {
  margin-bottom: 20px;
}
.forgot-password-full-part .forgot-password-normal-part .forgot-password-grid-part form button {
  background: #23BE72;
  border-color: #23BE72;
  box-shadow: none;
  width: 100%;
}
.cantrat-modal .modal-content {
  width: 98%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cantrat-modal .modal-body {
    max-height: 500px;
    overflow: auto;
  }
}
.login-failed-alert button {
  background-color: unset;
  width: auto;
  height: auto;
  margin-top: 5px;
}
.contact-full-part {
  float: left;
  width: 100%;
}
.contact-full-part .contact-normal-part {
  max-width: 1500px;
}
.contact-full-part .contact-normal-part .tab-content {
  float: left;
  width: 100%;
}
.contact-full-part .contact-normal-part .tab-content .accordion-item {
  margin: 20px 0;
  border: none;
}
.contact-full-part .contact-normal-part .tab-content .accordion-item .accordion-header button {
  border: 1px solid gainsboro;
  border-radius: 10px;
}
.contact-full-part .contact-normal-part .contact-left-box {
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin-right: 10px;
  max-width: calc(83.3% - 10px);
}
.contact-full-part .contact-normal-part .contact-tabs-box {
  margin-bottom: 0 !important;
  float: left;
}
.contact-full-part .contact-normal-part .contact-tabs-box button {
  color: #9c9c9c;
  border: none;
}
.contact-full-part .contact-normal-part .contact-tabs-box button[aria-selected="true"] {
  color: black;
  border: none;
}
.contact-full-part .contact-normal-part .contact-tabs-box button[aria-selected="true"]:focus {
  box-shadow: none;
}
.contact-full-part .contact-normal-part .contact-tabs-box button[aria-selected="true"]:focus-visible {
  box-shadow: none;
}
.contact-full-part .contact-normal-part button[aria-expanded="true"] {
  background: #F6F6F6;
  border: none;
}
.contact-full-part .contact-normal-part .contact-right-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #23BE72;
  color: white;
  border-radius: 10px;
  padding: 10px;
  max-height: 200px;
}
.contact-full-part .contact-normal-part .contact-right-box a {
  color: white !important;
}
.contact-full-part .contact-normal-part .contact-right-box h1 {
  font-size: 24px;
}
.static-page-full-part {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.static-page-full-part .static-page-normal-part {
  max-width: 1500px;
  margin: 20px auto;
}
.static-page-full-part .static-page-normal-part .static-page-left-box {
  height: 100%;
}
.static-page-full-part .static-page-normal-part .tab-content {
  float: left;
  width: 80%;
  background: white;
  display: flex;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  padding: 20px;
}
.static-page-full-part .static-page-normal-part .tab-content .tab-pane {
  float: le;
  width: 100%;
}
.static-page-full-part .static-page-normal-part .tab-content h3 {
  font-size: 26px;
}
.static-page-full-part .static-page-normal-part .contact-tabs-box {
  margin-bottom: 0 !important;
  float: left;
  width: calc(20% - 20px);
  margin-right: 20px;
  height: 100%;
  border-radius: 10px;
  background: white;
  border: 1px solid #DFDFDF;
  flex-direction: column;
}
.static-page-full-part .static-page-normal-part .contact-tabs-box li {
  float: left;
  width: 100%;
}
.static-page-full-part .static-page-normal-part .contact-tabs-box button {
  color: #9c9c9c;
  border: none;
  text-align: left;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .static-page-full-part .static-page-normal-part .contact-tabs-box button {
    display: flex;
    align-items: center;
  }
  .static-page-full-part .static-page-normal-part .contact-tabs-box button span {
    font-size: 14px;
  }
  .static-page-full-part .static-page-normal-part .contact-tabs-box button:after {
    background-image: url('../images//acordionIconYesil.svg');
    width: 36px;
    height: 25px;
    background-size: contain;
    content: '';
    background-repeat: no-repeat;
    background-position: right;
  }
}
.static-page-full-part .static-page-normal-part .contact-tabs-box button[aria-selected="true"] {
  color: black;
  border: none;
}
.static-page-full-part .static-page-normal-part .contact-tabs-box button[aria-selected="true"]:focus {
  box-shadow: none;
}
.static-page-full-part .static-page-normal-part .contact-tabs-box button[aria-selected="true"]:focus-visible {
  box-shadow: none;
}
.static-pages {
  background: #F7F7F7;
}
.static-pages .App {
  background-image: none;
}
.accounts .App {
  background-image: none;
}
.account-full-part {
  float: left;
  width: 100%;
}
.account-full-part .account-normal-part {
  max-width: 1500px;
  margin: 20px auto;
}
.account-full-part .account-normal-part .account-left-box {
  position: relative;
  top: -150px;
}
.account-full-part .account-normal-part .tab-content {
  float: left;
  width: 80%;
  background: white;
  display: flex;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  padding: 20px;
  height: auto;
  min-height: 380px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .account-full-part .account-normal-part .tab-content {
    width: 65%;
  }
}
.account-full-part .account-normal-part .tab-content .tab-pane {
  float: left;
  width: 100%;
}
.account-full-part .account-normal-part .tab-content h3 {
  font-size: 26px;
}
.account-full-part .account-normal-part .tab-content .login-input-box div {
  margin-bottom: 20px;
}
.account-full-part .account-normal-part .tab-content .login-input-box .password-input > div {
  float: left;
  width: auto;
}
.account-full-part .account-normal-part .tab-content .login-input-box .kampanya-checkbox {
  display: flex;
}
.account-full-part .account-normal-part .tab-content .login-input-box .kampanya-checkbox input {
  border-radius: 10px !important;
  width: 30px;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
}
.account-full-part .account-normal-part .tab-content .login-input-box .kampanya-checkbox input:focus {
  box-shadow: none !important;
}
.account-full-part .account-normal-part .tab-content .login-input-box .kampanya-checkbox input:checked {
  background-color: #F6F6F6 !important;
  border: 1px solid #F6F6F6 !important;
  box-shadow: none !important;
  background-blend-mode: difference;
}
.account-full-part .account-normal-part .tab-content .login-input-box .kampanya-checkbox button {
  margin-bottom: 20px;
}
.account-full-part .account-normal-part .tab-content .login-input-box input {
  width: 100%;
  padding: 5px;
}
.account-full-part .account-normal-part .tab-content form a {
  float: left;
  width: 100%;
  text-align: right;
}
.account-full-part .account-normal-part .tab-content form .sginup-camps {
  background: none;
  width: auto;
  float: left;
  color: black;
  display: block;
  border: none;
  padding: 0;
  padding-left: 10px;
  height: auto;
}
.account-full-part .account-normal-part .tab-content form .sginup-camps:hover,
.account-full-part .account-normal-part .tab-content form .sginup-camps:focus,
.account-full-part .account-normal-part .tab-content form .sginup-camps:focus-visible {
  border: none;
}
.account-full-part .account-normal-part .tab-content form button {
  background: #23BE72;
  border-color: #23BE72;
  width: 50%;
}
.account-full-part .account-normal-part .user-info-grid-part {
  position: relative;
  top: 160px;
  width: calc(20% - 20px);
  padding: 26px;
  padding-bottom: 0;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .account-full-part .account-normal-part .user-info-grid-part {
    width: calc(35% - 20px);
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .account-full-part .account-normal-part .user-info-grid-part {
    top: 190px;
  }
}
.account-full-part .account-normal-part .user-info-grid-part h3 {
  font-size: 18px;
  font-weight: 500;
}
.account-full-part .account-normal-part .user-info-grid-part p {
  font-size: 13px;
  color: #808080;
}
.account-full-part .account-normal-part .user-info-grid-part button {
  background: #808080;
  border-color: #808080;
}
.account-full-part .account-normal-part .user-info-grid-part button:focus {
  box-shadow: none;
}
.account-full-part .account-normal-part .contact-tabs-box {
  margin-bottom: 0 !important;
  float: left;
  width: calc(20% - 20px);
  margin-right: 20px;
  border-radius: 10px;
  background: white;
  padding: 10px;
  border: 1px solid #DFDFDF;
  flex-direction: column;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .account-full-part .account-normal-part .contact-tabs-box {
    width: calc(35% - 20px);
  }
}
.account-full-part .account-normal-part .contact-tabs-box li {
  float: left;
  padding-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid #efefef;
}
.account-full-part .account-normal-part .contact-tabs-box li:first-child {
  margin-top: 160px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .account-full-part .account-normal-part .contact-tabs-box li:first-child {
    margin-top: 190px;
  }
}
.account-full-part .account-normal-part .contact-tabs-box li:last-child {
  border-bottom: none;
}
.account-full-part .account-normal-part .contact-tabs-box button {
  color: #9c9c9c;
  border: none;
}
.account-full-part .account-normal-part .contact-tabs-box button[aria-selected="true"] {
  color: black;
  border: none;
  display: flex;
  float: left;
  justify-content: space-between;
  width: 100%;
}
.account-full-part .account-normal-part .contact-tabs-box button[aria-selected="true"]:after {
  background-image: url('../images//acordionIconYesil.svg');
  width: 36px;
  height: 25px;
  background-size: contain;
  content: '';
  background-repeat: no-repeat;
  background-position: right;
}
.account-full-part .account-normal-part .contact-tabs-box button[aria-selected="true"]:focus {
  box-shadow: none;
}
.account-full-part .account-normal-part .contact-tabs-box button[aria-selected="true"]:focus-visible {
  box-shadow: none;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button {
  flex-direction: column;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button:after {
  content: none;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button:before {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url('../images//acordionIcon.svg');
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box {
  display: flex;
  justify-content: space-between;
  float: left;
  width: 100%;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box {
  flex-grow: 1;
  max-width: 220px;
  overflow: hidden;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul {
  display: flex;
  padding: 0;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li {
  list-style: none;
  padding-left: 10px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li:nth-child(n) {
  margin-left: -15%;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li:first-child {
  margin-left: 0;
  padding-left: 0;
  display: flex;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li img {
  height: 64px;
  width: 64px;
  border-radius: 100px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-number span,
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-date span,
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state span {
  font-size: 14px;
  float: left;
  width: 100%;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-number span:first-child,
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-date span:first-child,
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state span:first-child {
  font-size: 16px;
  color: #666666;
  padding-bottom: 10px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state .span {
  color: #1B971C;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state .siparis-saati {
  color: black;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state .iptal-edildi {
  color: #ca0000;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-button-box {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-button-box button {
  margin-right: 10px;
  background: #F8F7F7;
  border-color: #E3E3E3;
  color: #5D5D5D;
  font-size: 13px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-button-box button:last-child {
  margin-right: 0;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-button-box button:hover,
.account-full-part .account-normal-part .order-acordion-container .accordion-header button .order-button-box button:focus {
  border-color: #1B971C;
  color: #1B971C;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"] {
  background: white;
  border-color: white;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"]:focus {
  background: white;
  color: black;
  border-color: white;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"]:focus-visible {
  border-color: white;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"] span {
  color: black;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"]::before {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  transform: rotate(90deg);
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid gainsboro;
  padding: 20px 0;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box {
    flex-direction: column;
  }
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box:first-child {
  margin-top: 20px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .order-product-image {
  flex-basis: 30%;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .order-product-image img {
  height: 100px;
  width: auto;
  border-radius: 10px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .indirimli-fiyat {
  flex-basis: 30%;
  text-decoration: line-through;
  color: #707070;
  text-align: center;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .product-code {
  flex-basis: 35%;
}
@media only screen and (max-width: 479px) {
  .account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .product-code {
    text-align: center;
  }
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .product-code span {
  float: left;
  width: 100%;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .product-code span:first-child {
  color: #666666;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .oder-product-price {
  text-align: center;
  flex-basis: 35%;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .oder-product-price span:first-child {
  float: left;
  width: 100%;
  color: #23BE72;
  font-size: 18px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-product-box .oder-product-price span:last-child {
  font-size: 12px;
  color: #b1b1b1;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address {
  float: left;
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address {
    display: flex;
    flex-direction: column-reverse;
  }
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .adresler {
  float: left;
  width: 50%;
  font-style: normal;
  line-height: 19px;
  word-break: break-word;
  color: #484848;
  font-size: 14px;
}
@media only screen and (max-width: 479px) {
  .account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .adresler {
    width: 100%;
  }
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .adresler h4 {
  color: #484848;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .adresler .teslimat-adresi {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  font-size: 12px;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .adresler .fatura-adresi {
  float: left;
  font-size: 12px;
  width: 100%;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri {
  float: left;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media only screen and (max-width: 479px) {
  .account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri {
    width: 100%;
  }
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri h4 {
  color: #484848;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0;
  width: 70%;
  text-align: left;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul {
  width: 70%;
  background: #EFEFEF;
  padding: 20px;
  border-radius: 10px;
}
@media only screen and (max-width: 479px) {
  .account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul {
    width: 100%;
  }
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #4A4A4A;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li:last-child {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid gainsboro;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li:last-child span:last-child {
  font-weight: 500;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li .kargo-indirim {
  text-decoration: line-through;
}
.account-full-part .account-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li .siparis-indirimi {
  color: #23BE72;
  font-size: 14px;
}
.account-full-part .account-normal-part .address-list-box .address-list {
  background: white;
  border: 1px solid #efefef;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 0 3px #efefef;
  display: flex;
  align-items: center;
  min-height: 120px;
  justify-content: space-between;
}
.account-full-part .account-normal-part .address-list-box .address-list .location-box {
  float: left;
  width: 10%;
  padding-left: 10px;
}
.account-full-part .account-normal-part .address-list-box .address-list .address-box {
  float: left;
  width: 90%;
  padding-left: 1rem;
}
.account-full-part .account-normal-part .address-list-box .address-list .address-box p {
  margin-bottom: 0;
}
.account-full-part .account-normal-part .address-list-box .address-list .address-box p:first-child {
  color: #7B7B7B;
}
.account-full-part .account-normal-part .address-list-box .address-list .address-box p:last-child {
  font-size: 14px;
}
.account-full-part .account-normal-part .address-list-box .address-list h4 {
  color: #484848;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 0;
}
.account-full-part .account-normal-part .address-list-box .address-list .button-box {
  display: flex;
}
.account-full-part .account-normal-part .address-list-box .address-list .button-box button {
  margin-right: 20px;
  background: #F2F2F2;
  border-color: #F2F2F2;
  margin-bottom: 10px;
}
.account-full-part .account-normal-part .no-favorite {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.account-full-part .account-normal-part .favorite-product {
  float: left;
  width: 100%;
}
.account-full-part .account-normal-part .favorite-product a {
  float: left;
  width: calc(33.33% - 15px);
  margin-right: 10px;
  color: black;
  text-decoration: none;
}
.account-full-part .account-normal-part .favorite-product a:nth-child(3n) {
  margin-right: 0;
}
.account-full-part .account-normal-part .favorite-product a img {
  width: 100%;
  max-height: 320px;
  border-radius: 10px 10px 0 0;
  border: 1px solid gainsboro;
}
.account-full-part .account-normal-part .favorite-product a h4 {
  color: #484848;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0;
}
.account-full-part .account-normal-part .favorite-product a .price-and-delete-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.account-full-part .account-normal-part .favorite-product a .price-and-delete-button p {
  margin-bottom: 0;
}
.account-full-part .account-normal-part .favorite-product a .price-and-delete-button button {
  background: #F6F6F6;
  border-color: #F6F6F6;
}
.account-full-part .account-normal-part .new-address-button {
  float: left;
  width: 100%;
  padding: 5px 5px;
  margin-bottom: 20x;
}
.account-full-part .account-normal-part .new-address-button button {
  float: right;
  box-shadow: none;
  background: #23BE72;
  border: #23BE72;
}
@media only screen and (max-width: 479px) {
  .address-edit-input-box {
    padding: 10px;
  }
}
.address-edit-input-box .address-edit-input {
  margin-bottom: 20px;
  width: 33.333%;
  float: left;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .address-edit-input-box .address-edit-input {
    width: 50%;
    margin-bottom: 10px;
    padding: 5px;
  }
}
@media only screen and (max-width: 479px) {
  .address-edit-input-box .address-edit-input {
    width: 100%;
  }
}
.address-edit-input-box .address-edit-input:nth-child(2) {
  width: 50%;
}
@media only screen and (max-width: 479px) {
  .address-edit-input-box .address-edit-input:nth-child(2) {
    width: 100%;
  }
}
.address-edit-input-box .address-edit-input:nth-child(7) {
  width: 33%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .address-edit-input-box .address-edit-input:nth-child(7) {
    width: 50%;
  }
}
@media only screen and (max-width: 479px) {
  .address-edit-input-box .address-edit-input:nth-child(7) {
    width: 100%;
  }
}
.address-edit-input-box .address-edit-input:nth-child(8) {
  width: 100%;
}
.address-edit-input-box .address-edit-input input {
  width: 100%;
  padding: 10px;
  float: left;
}
.address-edit-input-box .address-edit-input select {
  width: 100%;
  padding: 10px;
}
.address-edit-input-box .address-edit-input.ceptel {
  width: 33%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .address-edit-input-box .address-edit-input.ceptel {
    width: 50%;
  }
}
@media only screen and (max-width: 479px) {
  .address-edit-input-box .address-edit-input.ceptel {
    width: 100%;
  }
}
.address-edit-input-box .address-edit-input.ceptel input {
  background-image: url('../images/trFlag.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: left;
  background-origin: content-box;
  text-indent: 40px;
}
.address-edit-input-box .kurumsal-address .address-edit-input {
  width: calc(50% - 20px);
  margin-right: 20px;
}
@media only screen and (max-width: 479px) {
  .address-edit-input-box .kurumsal-address .address-edit-input {
    width: 100%;
    margin: 0;
  }
}
.address-edit-input-checkbox > div {
  float: left;
  min-width: 200px;
  padding: 20px;
  padding-top: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .address-edit-input-checkbox > div {
    padding-bottom: 0;
  }
}
.address-edit-input-checkbox > div input {
  border-radius: 20px !important;
  width: 30px;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
}
.address-edit-input-checkbox > div input:focus {
  box-shadow: none !important;
}
.address-edit-input-checkbox > div input:checked {
  background-color: #23BE72 !important;
  border: 1px solid #23BE72 !important;
  box-shadow: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
}
.address-edit-input-checkbox > div label {
  height: 40px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  color: #5B5B5B !important;
}
.address-edit-input-addrescheckbox > div {
  float: left;
  min-width: 200px;
  padding: 20px;
  padding-left: 0;
  padding-top: 0;
  display: flex;
}
.address-edit-input-addrescheckbox > div input {
  border-radius: 10px !important;
  width: 30px;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
}
@media only screen and (max-width: 479px) {
  .address-edit-input-addrescheckbox > div input {
    width: 100px;
  }
}
.address-edit-input-addrescheckbox > div input:focus {
  box-shadow: none !important;
}
.address-edit-input-addrescheckbox > div input:checked {
  background-color: #F6F6F6 !important;
  border: 1px solid #F6F6F6 !important;
  box-shadow: none !important;
  background-blend-mode: difference;
}
.address-edit-input-addrescheckbox > div .form-check-label {
  color: #666666 !important;
  font-weight: 600;
  font-size: 16px;
}
.address-edit-input-addrescheckbox > div label {
  color: #666666;
  margin-bottom: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.toast {
  position: absolute;
  top: 150px;
  right: 0;
}
.toast .toast-header {
  justify-content: space-between;
}
.toast .toast-header small {
  color: #e23e41;
}
.form-title {
  color: #243181;
  font-weight: 600;
}
.modal-body .cargo-info {
  display: flex;
  justify-content: space-between;
  background: #e0ffe5;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 20px;
}
.modal-body .cargo-info span {
  color: #777777;
}
.modal-body .cargo-info span:last-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #777777;
}
.modal-body .cargo-hareketleri ul li {
  list-style: none;
  display: flex;
  text-align: left;
  justify-content: flex-end;
  padding: 3px;
}
.modal-body .cargo-hareketleri ul li:nth-child(2n) {
  background: #ececec;
}
.modal-body .cargo-hareketleri ul li span {
  width: 50%;
}
.modal-body .cargo-hareketleri ul li span:nth-child(2n) {
  text-align: justify;
  padding-left: 80px;
}
.modal-dialog-centered form button {
  float: right;
  margin-top: 10px;
  width: 25%;
  background: #23BE72;
  border-color: #23BE72;
}
.modal-dialog-centered form button:focus {
  box-shadow: none;
}
.comments-input-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.comments-input-box .sipariskalemleri-edit-input {
  margin-top: 10px;
}
.comments-input-box .sipariskalemleri-edit-input select {
  width: 100%;
  height: 40px;
  padding: 10px;
}
.comments-input-box textarea.form-control {
  margin: 0px !important;
}
#bank-data-content iframe {
  width: 100%;
  height: auto;
  min-height: 500px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  #bank-data-content iframe {
    min-width: fit-content;
  }
}
.delete-address-modal .modal-dialog {
  max-width: 320px !important;
}
.login-failed li {
  float: left;
  width: calc(50% - 10px);
  margin: 5px;
  text-align: center;
  border-radius: 10px;
}
.login-failed li:first-child {
  display: none;
}
.login-failed li:last-child {
  position: absolute;
  bottom: -270px !important;
  right: 0;
  width: auto;
}
@media only screen and (max-width: 479px) {
  .login-failed li:last-child {
    bottom: -250px !important;
  }
}
#login-or-sginup-tab-uyeliksiz {
  background: #ffffff;
  border: none;
}
.pointBox .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f7f4f4;
  background-clip: border-box;
  --mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50%/30.00px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding: 15px 0px;
  border: none;
  margin: 1rem auto;
}
.sales-quiry,
.about-account {
  background-image: none;
  background: #F7F7F7;
}
.sales-quiry .App,
.about-account .App {
  background-image: none;
}
.sales-quiry-full-part {
  margin-bottom: 20px;
}
.sales-quiry-full-part .sales-quiry-normal-part {
  max-width: 1500px;
}
.sales-quiry-full-part .sales-quiry-normal-part h1 {
  color: #999999;
  font-size: 24px;
}
.sales-quiry-full-part .sales-quiry-normal-part p {
  font-size: 14px;
  color: #999999;
}
.sales-quiry-full-part .sales-quiry-normal-part .sales-quirt-grid-part {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
.sales-quiry-full-part .sales-quiry-normal-part .sales-quirt-grid-part button:focus {
  box-shadow: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .sales-quiry-full-part .sales-quiry-normal-part .sales-quirt-grid-part button button {
    width: 100% !important;
  }
}
@media only screen and (max-width: 479px) {
  .sales-quiry-full-part .sales-quiry-normal-part .sales-quirt-grid-part .address-edit-input-box {
    flex-direction: column;
  }
  .sales-quiry-full-part .sales-quiry-normal-part .sales-quirt-grid-part .address-edit-input-box .address-edit-input {
    width: 100%;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button {
  flex-direction: column;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button:after {
  content: none;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button:before {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url('../images//acordionIcon.svg');
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box {
  display: flex;
  justify-content: space-between;
  float: left;
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box {
    flex-direction: column;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box {
  flex-grow: 1;
  max-width: 220px;
  overflow: hidden;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul {
  display: flex;
  padding: 0;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li {
  list-style: none;
  padding-left: 10px;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li:nth-child(n) {
  margin-left: -15%;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-image-box ul li img {
  height: 64px;
  width: 64px;
  border-radius: 100px;
}
@media only screen and (max-width: 479px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-number,
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-date,
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state {
    text-align: center;
    margin-bottom: 10px;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-number span,
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-date span,
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state span {
  font-size: 14px;
  float: left;
  width: 100%;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-number span:first-child,
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-date span:first-child,
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state span:first-child {
  font-size: 16px;
  color: #666666;
  padding-bottom: 10px;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state .span {
  color: #1B971C;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state .siparis-saati {
  color: black;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-status-box .order-state .iptal-edildi {
  color: #ca0000;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-button-box {
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-button-box button {
  margin-right: 10px;
  background: #F8F7F7;
  border-color: #E3E3E3;
  color: #5D5D5D;
  font-size: 13px;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-button-box button:last-child {
  margin-right: 0;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-button-box button:hover,
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button .order-button-box button:focus {
  border-color: #1B971C;
  color: #1B971C;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"] {
  background: white;
  border-color: white;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"]:focus {
  background: white;
  color: black;
  border-color: white;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"]:focus-visible {
  border-color: white;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"] span {
  color: black;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-header button[aria-expanded="true"]::before {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  transform: rotate(90deg);
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid gainsboro;
  padding: 20px 0;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box:first-child {
  margin-top: 20px;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .order-product-image {
  flex-basis: 30%;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .order-product-image img {
  height: 100px;
  width: auto;
  border-radius: 10px;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .indirimli-fiyat {
  flex-basis: 30%;
  text-decoration: line-through;
  text-align: center;
  color: #E13F3F;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .product-code {
  flex-basis: 35%;
}
@media only screen and (max-width: 479px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .product-code {
    text-align: center;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .product-code span {
  float: left;
  width: 100%;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .product-code span:first-child {
  color: #666666;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .oder-product-price {
  text-align: center;
  flex-basis: 35%;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .oder-product-price span:first-child {
  float: left;
  width: 100%;
  color: #23BE72;
  font-size: 18px;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-product-box .oder-product-price span:last-child {
  font-size: 12px;
  color: #b1b1b1;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address {
  float: left;
  width: 100%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address {
    display: flex;
    flex-direction: column-reverse;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .adresler {
  float: left;
  width: 50%;
  font-style: normal;
  line-height: 19px;
  word-break: break-word;
  color: #484848;
  font-size: 14px;
}
@media only screen and (max-width: 479px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .adresler {
    float: left;
    width: 100%;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .adresler h4 {
  color: #484848;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .adresler .teslimat-adresi {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  font-size: 12px;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .adresler .fatura-adresi {
  float: left;
  font-size: 12px;
  width: 100%;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri {
  float: left;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri {
    float: left;
    width: 100%;
    align-items: flex-start;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri h4 {
  color: #484848;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0;
  width: 70%;
  text-align: left;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri h4 {
    width: 100%;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul {
  width: 70%;
  background: #EFEFEF;
  padding: 20px;
  border-radius: 10px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul {
    width: 100%;
  }
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #4A4A4A;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li:last-child {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid gainsboro;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li:last-child span:last-child {
  font-weight: 500;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li .kargo-indirim {
  text-decoration: line-through;
}
.sales-quiry-full-part .sales-quiry-normal-part .order-acordion-container .accordion-body .order-address .odeme-bilgileri ul li .siparis-indirimi {
  color: #23BE72;
  font-size: 14px;
}
.tarifler-detay .breadcrumb {
  padding: 10px 0;
}
.tarifler-detay-full-part {
  margin-bottom: 20px;
}
.tarifler-detay-full-part .tarifler-detay-normal-part {
  max-width: 1500px;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part {
  float: left;
  width: 100%;
  background: white;
  display: flex;
  box-shadow: 0 3px 3px gainsboro;
  justify-content: space-between;
  border-radius: 10px;
  min-height: 520px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part {
    flex-direction: column;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part .tarif-video {
  width: 50%;
  padding: 20px;
  float: left;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part .tarif-video {
    width: 100%;
    height: 400px;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part .tarif-video iframe {
  border-radius: 16px;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part .tarif-aciklama {
  padding: 20px;
  width: 50%;
  float: left;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part .tarif-aciklama {
    width: 100%;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .tarif-detay-grid-part .tarif-aciklama ol {
  line-height: 28px;
  font-size: 14px;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .mySwiper {
  max-height: 450px;
  margin-top: 20px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .mySwiper {
    overflow: hidden;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .mySwiper .swiper-wrapper {
  max-height: 450px;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-next {
  right: -60pxx;
}
@media only screen and (max-width: 479px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-next {
    display: none;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-next:after {
  content: url('../images//Next.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-next:after {
    background-image: url('../images//Next.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-prev {
  left: -60px;
}
@media only screen and (max-width: 479px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-prev {
    display: none;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-prev:after {
  content: url('../images//prev.svg');
}
@media only screen and (min-width: 1200px) and (max-width: 1365px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-button-prev:after {
    background-image: url('../images//prev.svg');
    background-size: 100% 100%;
    display: inline-block;
    width: 100%;
    height: 100%;
    content: "";
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-pagination-fraction {
  bottom: -30px !important;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-pagination-bullets {
  bottom: -4px;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .swiper-pagination-bullet-active {
  background: #E13F3F;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product h2 {
  font-size: 22px;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .relation-product .product-box {
  min-height: fit-content;
  float: left;
  width: 100%;
}
.tarifler-detay-full-part .tarifler-detay-normal-part .breadcrumb ol {
  display: flex;
  padding: 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .breadcrumb ol {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-y: hidden;
    border-radius: 10px 10px 0 0;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .breadcrumb ol li {
  list-style: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .tarifler-detay-full-part .tarifler-detay-normal-part .breadcrumb ol li {
    min-width: fit-content;
  }
}
.tarifler-detay-full-part .tarifler-detay-normal-part .breadcrumb ol li:first-child:after,
.tarifler-detay-full-part .tarifler-detay-normal-part .breadcrumb ol li:nth-child(2):after {
  content: ' › ';
  padding-right: 10px;
}
.tarifler-detay-full-part .tarifler-detay-loading-part {
  display: flex;
  justify-content: center;
}
.not-found-page-full-part {
  background: #F7F7F7;
}
.not-found-page-full-part .not-found-page-normal-part {
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
.sozlesme-button {
  background: none;
  border: none;
  box-shadow: none;
}
.direct-checkout .App {
  background: none;
}
.direct-checkout .driect-checkout-full-part {
  margin-bottom: 40px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .direct-checkout .driect-checkout-full-part {
    margin-bottom: 20px;
  }
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part {
  width: 95%;
  max-width: 1500px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .direct-checkout .driect-checkout-full-part .driect-checkout-normal-part {
    width: 100%;
    max-width: 1500px;
    padding: 0;
    overflow: hidden;
  }
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input-box {
  justify-content: flex-start;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input-box button {
  width: 40%;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input {
  float: left;
  width: 33.333%;
  margin: 10px 0;
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input {
    padding: 5px;
    margin: 5px 0;
  }
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input:first-child,
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input:nth-child(2) {
  width: 100%;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input input {
  width: 100%;
  padding: 10px;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .checkout-edit-input select {
  width: 100%;
  padding: 10px;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part {
    flex-direction: column;
  }
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .direct-checkout-lef-part {
  width: 100%;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part {
  width: 100%;
  float: left;
  background: white;
  margin-left: 20px;
  padding: 20px;
  border-radius: 10px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part {
    margin-left: 0;
    margin-top: 10px;
  }
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-product-price {
  border: 1px dashed gainsboro;
  padding: 10px;
  border-radius: 10px;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-product-price .product-list {
  margin-top: 10px;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-product-price .product-list .product {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-product-price .product-list .product p {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-product-price .product-list .product p {
    max-width: 220px;
  }
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-product-price .product-list .product .price {
  color: #23BE72;
  font-weight: 600;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .total-price {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .total-price span:last-child {
  color: #23BE72;
  font-weight: 600;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .indirimler span:last-child {
  color: #E13F3F;
  font-weight: 600;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-shipping-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  border-bottom: 1px solid gainsboro;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-shipping-price span {
  float: left;
  width: 100%;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-shipping-price .cargo-note {
  font-size: 14px;
  color: #8a8a8a;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .order-shipping-price .cargo-price {
  text-align: end;
  color: #23BE72;
  font-weight: 600;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .kdv-total-price {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.direct-checkout .driect-checkout-full-part .driect-checkout-normal-part .direct-checkout-full-part .cart-index-right-part .kdv-total-price span:last-child {
  color: #23BE72;
  font-weight: 600;
}
.direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox {
  margin-bottom: 20px;
  margin-top: 20px;
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox {
    margin-top: 10px;
  }
}
.direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox > div {
  float: left;
  min-width: 200px;
  padding: 10px;
  padding-top: 0;
  display: flex;
}
.direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox > div input {
  border-radius: 10px !important;
  float: left;
  width: 30px;
  margin: 0;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox > div input {
    width: 80px;
    padding-right: 10px;
  }
}
.direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox > div input:focus {
  box-shadow: none !important;
}
.direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox > div input:checked {
  background-color: #F6F6F6 !important;
  border: 1px solid #F6F6F6 !important;
  box-shadow: none !important;
  background-blend-mode: difference;
}
.direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox > div .form-check-label {
  color: #666666 !important;
  font-size: 16px;
  height: 30px;
  display: flex;
  margin: 0 10px;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .checkout-edit-input-addrescheckbox > div .form-check-label {
    font-size: 14px;
    height: auto;
    margin: 0;
    padding-left: 10px;
    text-align: left;
  }
}
.direct-checkout .driect-checkout-full-part button {
  background-color: #23BE72;
  border-color: #23BE72;
  min-width: 20%;
  width: auto;
}
.direct-checkout .driect-checkout-full-part button:focus {
  box-shadow: none;
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox {
    padding: 0;
  }
}
.direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div {
  float: left;
  position: relative;
  min-width: 200px;
  padding-top: 0;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div {
    padding: 0;
    margin-bottom: 55px;
  }
}
.direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div input {
  border-radius: 20px !important;
  width: 30px;
  height: 30px;
  border: 1px solid #707070 !important;
  position: absolute;
  width: 100%;
  height: 160px;
  background: transparent !important;
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div input {
    height: 170px;
    margin-bottom: 10px;
  }
}
.direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div input:focus {
  box-shadow: none !important;
}
.direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div input:checked {
  background-color: #23BE72 !important;
  border: 2px solid #23BE72 !important;
  box-shadow: none !important;
  background: transparent !important;
}
.direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div h4 {
  font-size: 18px;
}
@media only screen and (max-width: 479px), only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div h4 {
    font-size: 16px;
  }
}
.direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div label {
  height: 40px;
  display: flex;
  float: left;
  width: 100%;
  height: auto;
  align-items: flex-start;
  color: #5B5B5B !important;
  margin-top: 30px;
  padding-left: 15px;
  flex-direction: column;
}
@media only screen and (max-width: 479px), only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div label {
    font-size: 14px;
    margin: 0;
    padding-left: 5px;
  }
}
@media only screen and (max-width: 479px) {
  .direct-checkout .driect-checkout-full-part .wire-transfer-edit-input-checkbox > div label {
    padding-left: 15px;
    padding-top: 20px;
  }
}
.direct-checkout .driect-checkout-full-part .garanti-pay label .garanti-image {
  display: flex;
  align-items: center;
}
.direct-checkout .driect-checkout-full-part .garanti-pay label p {
  font-size: 14px;
}
.direct-checkout .driect-checkout-full-part .garanti-pay label img {
  height: 80px;
}
.direct-checkout .accordion-header button {
  background: white;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  flex-direction: column-reverse;
}
.direct-checkout .accordion-header button:after {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  content: '';
  background-image: url('../images//acordionIcon.svg');
  background-repeat: no-repeat;
  background-size: 2rem;
  transition: transform 0.2s ease-in-out;
}
.direct-checkout .accordion-header button p {
  color: #b3b3b3;
  font-size: 14px;
}
.direct-checkout .accordion-header button img {
  height: 32px;
}
.direct-checkout .accordion-header .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
}
.direct-checkout .accordion {
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
.direct-checkout .accordion-body {
  width: 100%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .direct-checkout .accordion-body {
    width: 100%;
  }
}
.custom-page-full-part .custom-page-normal-part {
  max-width: 1500px;
  width: 95%;
}
.custom-page-full-part .custom-page-normal-part .custom-page-image-box {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.custom-page-full-part .custom-page-normal-part .custom-page-image-box img {
  width: 100%;
  border-radius: 10px;
}
.custom-page-full-part .custom-page-normal-part .custom-page-description {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  background: gainsboro;
  border-radius: 10px;
}
.custom-page-full-part .custom-page-normal-part .custom-page-description svg {
  height: 40px;
  padding-right: 10px;
}
.custom-page-full-part .custom-page-normal-part .custom-page-description span {
  font-size: 22px;
}
.custom-page-full-part .custom-page-normal-part .custom-vakif-part {
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}
.custom-page-full-part .custom-page-normal-part .custom-vakif-part img {
  width: auto;
  float: left;
  width: 100%;
}
.custom-page-full-part .custom-page-normal-part .custom-video {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.custom-page-full-part .custom-page-normal-part .custom-video:last-child {
  margin: 20px 0;
}
.custom-page-full-part .custom-page-normal-part .custom-video iframe {
  border-radius: 10px;
}
.custom-page-full-part .custom-page-normal-part .custom-read-more {
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  align-items: center;
  justify-items: center;
  background: white;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-page-full-part .custom-page-normal-part .custom-read-more {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .custom-page-full-part .custom-page-normal-part .custom-read-more {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .custom-page-full-part .custom-page-normal-part .custom-read-more {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }
}
.custom-page-full-part .custom-page-normal-part .custom-read-more span {
  font-size: 22px;
  font-weight: 600;
}
.custom-page-full-part .custom-page-normal-part .custom-read-more img {
  height: 100px;
}
.custom-page-full-part .custom-page-normal-part .kizilay-aciklama {
  float: left;
  width: 100%;
}
.custom-page-full-part .custom-page-normal-part .kizilay-aciklama img {
  width: 100%;
}
.pastirma-tanitim-full-part .pastirna-tanitim-normal-part {
  max-width: 1500px;
  width: 100%;
}
.pastirma-tanitim-full-part .pastirna-tanitim-normal-part h1 {
  font-size: 28px;
  margin-bottom: 20px;
}
.pastirma-tanitim-full-part .pastirna-tanitim-normal-part iframe {
  border-radius: 8px;
}
button[type="submit"] {
  background-color: #23BE72;
  border-color: #23BE72;
}
button[type="submit"]:focus {
  background-color: #23BE72;
  border-color: #23BE72;
  box-shadow: none;
}
button[type="submit"]:hover {
  background-color: #23BE72;
  border-color: #23BE72;
}
.cart .App {
  background-image: none;
  height: 100vh;
}
.cart .globalClass_5c43,
.cart jdiv {
  display: none !important;
}
.cart-index-full-part {
  float: left;
  width: 100%;
}
.cart-index-full-part .cart-index-normal-part {
  max-width: 1500px;
}
@media only screen and (min-width: 1921px) {
  .cart-index-full-part .cart-index-normal-part {
    max-width: fit-content;
    width: 95%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-index-full-part .cart-index-normal-part > .row {
    flex-direction: column;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part {
    width: 100%;
    margin-bottom: 20px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list {
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid gainsboro;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list {
    display: block;
    float: left;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list {
    position: relative;
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-image-box {
    width: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-image-box img {
  height: 120px;
  border-radius: 10px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-image-box img {
    width: 30%;
    float: left;
    object-fit: contain;
    height: auto;
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-image-box img {
    height: 100%;
    border-radius: 10px;
    max-height: 120px;
    object-fit: cover;
  }
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .remove-button {
    position: absolute;
    top: 8px;
    right: 0px;
  }
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .remove-button img {
    width: 14px;
    object-fit: fill;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit {
  text-align: center;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit {
    width: 70%;
    float: left;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit span:first-child {
  margin: 10px 0;
  color: #a89f9f;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row {
  background: #F9F6F5;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row {
    height: 42px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .dec {
  float: left;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 10px 0 0 10px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .dec:focus {
  box-shadow: none;
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .dec {
    height: 42px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .inc {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  border: none;
  border-left: 1px solid #f3f3f3;
  height: 50px;
  background: #F9F6F5;
  border-radius: 0 10px 10px 0px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .inc:focus {
  box-shadow: none;
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .inc {
    height: 42px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row span {
  color: #343132;
  font-size: 14px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .input-are {
  max-width: 40%;
  float: left;
  height: 40px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .input-are .productQuantityTextBox {
  float: left;
  width: 100%;
  height: 26px;
  background: #F9F6F5;
  text-align: center;
  border: none;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .number-row-and-unit .numbers-row .input-are span {
  float: left;
  width: 100%;
  text-align: center;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-price-box {
  text-align: center;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-price-box {
    width: 30%;
    float: left;
    min-height: 65px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-price-box {
    min-height: 40px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-price-box span {
  float: left;
  width: 100%;
  font-size: 15px;
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-price-box span {
    color: #23BE72;
    font-weight: 600;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-price-box span:nth-child(2) {
  color: #23BE72;
  font-weight: 600;
  font-size: 18px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-price-box .product-discount {
  text-decoration: line-through;
  font-size: 15px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-desc-box {
  width: 30%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-desc-box {
    width: 70%;
    float: left;
    object-fit: contain;
    height: auto;
    min-height: 70px;
    font-size: 14px;
    padding-left: 10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-desc-box {
    min-height: 100px;
  }
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-desc-box {
    width: 55%;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-desc-box span {
  color: #a89f9f;
  font-weight: 600;
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-desc-box h5 {
  font-size: 1rem;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list .product-desc-box h5 {
    font-size: 16px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list button {
  background: none;
  border: none;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part .cart-product-list button {
    float: left;
    width: 100%;
    text-align: end;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-left-part h1 {
  color: #a89f9f;
  font-size: 32px;
  font-weight: 400;
}
@media only screen and (max-width: 479px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-left-part h1 {
    font-size: 18px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part {
  background: white;
  margin-left: 15px;
  width: calc(33.333% - 15px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  border-radius: 10px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-right-part {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-product-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
  border-bottom: 1px solid gainsboro;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price span span:first-child {
  color: #646464;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price span span:last-child,
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price span span .cargo-note {
  display: block;
  font-size: 13px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price .cargo-bedava {
  padding-left: 10px;
  color: #23BE72;
  font-weight: 500;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price .cargo-note {
  display: block;
  font-size: 13px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price .cargo-mesaj {
  color: #e34541;
  font-weight: 500;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-shipping-price .cargo-price {
  width: 20%;
  text-align: end;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .kdv-total-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .fixed-area {
  display: flex;
  background: #f7f7f7;
  width: 100%;
  align-items: center;
  left: 0;
  padding: 0 10px;
  position: fixed;
  border: 1px solid gainsboro;
  justify-content: space-between;
  bottom: 0px;
  border-radius: 10px 10px 0 0;
  z-index: 9;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .fixed-area a {
  margin-top: 0;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .fixed-area span {
  padding-right: 10px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .fixed-area span:last-child {
  color: #23BE72;
  font-family: 500;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part a {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 5px;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .order-chcekout-btn {
  background: #23BE72;
  border-color: #23BE72;
  color: white !important;
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part h2 {
  font-size: 26px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .cart-index-right-part h2 {
    font-size: 22px;
  }
}
.cart-index-full-part .cart-index-normal-part .cart-index-right-part .indirimler span:last-child {
  color: #E13F3F;
}
.cart-index-full-part .cart-index-normal-part .video-area {
  margin: 20px 0;
  display: flex;
  padding: 0;
  overflow: hidden;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .video-area {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-index-full-part .cart-index-normal-part .video-area {
    flex-direction: column-reverse;
  }
}
.cart-index-full-part .cart-index-normal-part .video-area iframe {
  border-radius: 8px;
  max-height: 500px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part .video-area iframe {
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-index-full-part .cart-index-normal-part .video-area iframe {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-index-full-part .cart-index-normal-part .video-area iframe {
    max-height: 395px;
  }
}
.cart-index-full-part .cart-index-normal-part .video-area img {
  margin-right: 10px;
  height: auto;
  border-radius: 8px;
  margin-top: 0 !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-index-full-part .cart-index-normal-part .video-area img {
    width: 60% !important;
    object-fit: contain;
  }
}
.cart-index-full-part .cart-index-normal-part .video-area img {
  margin-top: 20px;
  width: 100%;
}
.cart-index-full-part .cart-index-normal-part h1 {
  font-size: 26px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .cart-index-full-part .cart-index-normal-part h1 {
    font-size: 22px;
  }
}
.cart-index-full-part .no-cart-index-normal-part {
  max-width: 1500px;
  width: 95%;
  height: 21.4vh;
}
@media only screen and (min-width: 1921px) {
  .cart-index-full-part .no-cart-index-normal-part {
    max-width: fit-content;
    width: 95%;
  }
}
.cart-index-full-part .no-cart-index-normal-part .no-cart-svg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cart-index-full-part .no-cart-index-normal-part .no-cart-svg svg {
  height: 45px;
}
.cart-index-full-part .no-cart-index-normal-part .no-cart-svg svg path {
  width: 100%;
  fill: #e13f41;
}
.campaign-full-part {
  margin-bottom: 20px;
}
@media only screen and (max-width: 479px) {
  .campaign-full-part {
    padding: 0 15px;
  }
}
.campaign-full-part .campaign-normal-part  {
  max-width: 1500px;
  padding: 10px;
  width: 95%;
  margin: 20px auto;
  height: auto;
  min-height: 420px;
}
@media only screen and (min-width: 1921px) {
  .campaign-full-part .campaign-normal-part  {
    max-width: fit-content;
    width: 95%;
  }
}
.campaign-full-part .campaign-normal-part  .campaing-desc-part {
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  margin: 40px 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .campaign-full-part .campaign-normal-part  .campaing-desc-part {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1921px) {
  .campaign-full-part .campaign-normal-part  .campaing-desc-part {
    max-width: fit-content;
    width: 95%;
  }
}
.campaign-full-part .campaign-normal-part  .campaing-desc-part .campaing-message-part {
  color: #707070;
}
.campaign-full-part .campaign-normal-part  .campaing-desc-part .campaing-message-part p {
  margin-bottom: 0;
}
.campaign-full-part .campaign-normal-part  .campaing-desc-part .campaing-message-part p:first-child {
  font-weight: 600;
  font-size: 18   px;
}
.campaign-full-part .campaign-normal-part  .campaing-desc-part .campaing-total-price-part {
  background: #23BE72;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: white;
  border-radius: 5px;
  font-weight: 600;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .campaign-full-part .campaign-normal-part  .campaing-desc-part .campaing-total-price-part {
    padding: 15px;
    margin-top: 20px;
  }
}
.campaign-full-part .campaign-normal-part  .campaign-mesaj {
  color: white;
  padding-left: 0px;
}
.campaign-full-part .campaign-normal-part  .swiper-container {
  width: 100%;
}
.campaign-full-part .campaign-normal-part  .swiper-container .swiper-wrapper {
  height: 300px;
}
@media only screen and (max-width: 479px) {
  .campaign-full-part .campaign-normal-part  .swiper-container .swiper-wrapper {
    height: 270px;
  }
}
.campaign-full-part .campaign-normal-part  .swiper-container .swiper-button-next,
.campaign-full-part .campaign-normal-part  .swiper-container .swiper-button-prev,
.campaign-full-part .campaign-normal-part  .swiper-container .swiper-pagination {
  display: block;
}
.campaign-full-part .campaign-normal-part  .swiper-container .swiper-button-next:after,
.campaign-full-part .campaign-normal-part  .swiper-container .swiper-button-prev:after {
  font-size: 24px !important;
}
.campaign-full-part .campaign-normal-part  .free-product-cart {
  width: 100%;
  background: white;
  border-radius: 10px;
  min-height: 250px;
}
@media only screen and (max-width: 479px) {
  .campaign-full-part .campaign-normal-part  .free-product-cart {
    min-height: 250px;
    max-width: 270px;
  }
}
.campaign-full-part .campaign-normal-part  .free-product-cart img {
  max-height: 170px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: contain;
  border-bottom: 1px solid gainsboro;
}
@media only screen and (max-width: 479px) {
  .campaign-full-part .campaign-normal-part  .free-product-cart img {
    max-height: 190px;
  }
}
.campaign-full-part .campaign-normal-part  .free-product-cart .free-product-name {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  padding: 10px;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .campaign-full-part .campaign-normal-part  .free-product-cart .free-product-name {
    flex-direction: column;
    align-items: flex-start;
  }
}
.campaign-full-part .campaign-normal-part  .free-product-cart .free-product-name span:last-child {
  font-weight: 600;
  min-width: 60px;
  color: #23BE72;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 10px auto;
  margin-top: auto;
  position: absolute;
  top: 10px;
  right: 10px;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .add-to-cart {
  width: 50%;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .add-to-cart button {
  background: #23BE72;
  border-color: #23BE72;
  height: 50px;
  width: 100%;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .numbers-row {
  background: #F9F6F5;
  width: 40px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column-reverse;
  height: auto;
  background: #5d3ebc;
  border-radius: 15px;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .numbers-row .dec {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid #f3f3f3;
  height: 35px;
  background: #F9F6F5;
  border-radius: 0px 0px 10px 10px;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .numbers-row .inc {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  border-left: 1px solid #f3f3f3;
  height: 35px;
  background: #F9F6F5;
  border-radius: 10px 10px 0px 0px;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .numbers-row span {
  color: white;
  padding: 5px;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .numbers-row .input-are {
  max-width: 40%;
  float: left;
  height: 40px;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .numbers-row .input-are .productQuantityTextBox {
  float: left;
  width: 100%;
  height: 26px;
  background: #F9F6F5;
  text-align: center;
  border: none;
}
.campaign-full-part .campaign-normal-part  .free-product-cart .product-add-to-cart .numbers-row .input-are span {
  float: left;
  width: 100%;
  text-align: center;
}
.campaign-full-part .campaign-normal-part  .add-to-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.campaign-full-part .campaign-normal-part  .add-to-cart .numbers-row {
  display: flex;
}
.campaign-full-part .campaign-normal-part  .add-to-cart button {
  background: #23BE72;
  border-color: #23BE72;
}
.campaign-full-part .campaign-message-normal-part {
  max-width: 1500px;
  padding: 15px;
  background: gainsboro;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
@media only screen and (min-width: 1921px) {
  .campaign-full-part .campaign-message-normal-part {
    max-width: fit-content;
    width: 95%;
  }
}
.campaign-full-part .campaign-message-normal-part .campaign-mesaj {
  margin-left: 20px;
}
.campaign-full-part .campaign-message-normal-part .campaign-mesaj span {
  padding-left: 0px;
}
.cart-index-header-full-part .cart-index-header-normal-part {
  max-width: 1500px;
}
@media only screen and (min-width: 1921px) {
  .cart-index-header-full-part .cart-index-header-normal-part {
    max-width: fit-content;
    width: 95%;
  }
}
@media only screen and (max-width: 479px) {
  .cart-index-header-full-part .cart-index-header-normal-part a {
    float: left;
    width: 100%;
    text-align: center;
  }
}
.cart-index-header-full-part .cart-index-header-normal-part a img {
  height: 151px;
  padding: 10px;
  padding-left: 0;
}
@media only screen and (max-width: 479px) {
  .cart-index-header-full-part .cart-index-header-normal-part a img {
    height: 100px;
  }
}
.cart-index-header-full-part .cart-index-loading-normal-part {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.yilbasi-tatili {
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: #e34541;
}
.campaing {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .campaing {
    flex-direction: column;
    height: auto;
  }
  .campaing > div {
    margin: 10px auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 480px) and (max-width: 767px) {
  .campaing {
    flex-direction: column;
  }
}
.campaing .free-product {
  flex-direction: row;
  display: flex;
  height: 220px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .campaing .free-product {
    flex-direction: column;
    height: auto;
  }
}
.campaing .free-product-cart {
  background: red;
  width: 310px !important;
  border-radius: 0 !important;
  border: 1px solid gainsboro;
}
.campaing .free-product-cart:first-child {
  border-right: none;
}
.checkout .App {
  background-image: none;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.checkout .globalClass_5c43,
.checkout jdiv {
  display: none !important;
}
.checkout.uyelik .checkout-header-part .tab-content,
.checkout.login .checkout-header-part .tab-content,
.checkout.forgot-password .checkout-header-part .tab-content,
.checkout.sginup .checkout-header-part .tab-content {
  background: none !important;
}
.checkout.uyelik .checkout-header-part .tab-content .tab-content,
.checkout.login .checkout-header-part .tab-content .tab-content,
.checkout.forgot-password .checkout-header-part .tab-content .tab-content,
.checkout.sginup .checkout-header-part .tab-content .tab-content {
  background: white !important;
}
.checkout.modal-open .modal .modal-header button {
  margin-left: 25px;
}
.checkout.modal-open .modal .modal-footer button {
  width: 35%;
}
.checkout.modal-open .modal-body .address-edit-input-box .address-edit-input {
  margin-bottom: 20px;
  width: 33.333%;
  float: left;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout.modal-open .modal-body .address-edit-input-box .address-edit-input {
    width: 50%;
    margin-bottom: 10px;
    padding: 5px;
  }
}
.checkout.modal-open .modal-body .address-edit-input-box .address-edit-input:nth-child(2),
.checkout.modal-open .modal-body .address-edit-input-box .address-edit-input:nth-child(3) {
  width: 50%;
}
.checkout.modal-open .modal-body .address-edit-input-box .address-edit-input:nth-child(7) {
  width: 33%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout.modal-open .modal-body .address-edit-input-box .address-edit-input:nth-child(7) {
    width: 50%;
  }
}
.checkout.modal-open .modal-body .address-edit-input-box .address-edit-input:nth-child(8) {
  width: 100%;
}
.checkout.modal-open .modal-body .address-edit-input-box .kurumsal-address .address-edit-input {
  width: calc(50% - 10px);
  margin-right: 20px;
}
@media only screen and (max-width: 479px) {
  .checkout.modal-open .modal-body .address-edit-input-box .kurumsal-address .address-edit-input {
    width: 100%;
    margin: 0;
  }
}
.checkout.modal-open .modal-body .address-edit-input-box .kurumsal-address .address-edit-input:last-child {
  margin-right: 0;
}
.checkout.modal-open .modal-body .address-edit-input-box textarea.form-control {
  margin: 0px !important;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout.modal-open .modal-body button {
    width: 100% !important;
  }
}
.checkout .tab-container-part {
  background: white;
  border-radius: 8px;
  width: 42% !important;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .tab-container-part {
    width: 100% !important;
    padding: 0 !important;
  }
}
.checkout .tab-container-part button.nav-link {
  margin: 0 auto !important;
}
.checkout .tab-container-part .tab-content button {
  margin: 20px 0 !important;
}
.checkout .tab-container-part .tab-content button.sginup-camps {
  margin: 0px !important;
}
.checkout .login-tab-container {
  width: 90% !important;
  margin: 0 auto;
  border-bottom: none;
}
.checkout .login-tab-container li:nth-child(2) {
  background-color: #e5e5e5 !important;
  margin: 5px 0 !important;
  width: 50% !important;
  border-radius: 8px 0 0 8px!important;
  padding: 3px;
}
.checkout .login-tab-container li:nth-child(3) {
  background-color: #e5e5e5 !important;
  margin: 5px 0 !important;
  width: 50% !important;
  border-radius: 0 8px 8px 0!important;
  padding: 3px;
}
.checkout .login-tab-container li:first-child {
  display: block !important;
  float: left;
  padding: 0;
  margin: 0;
  width: 100% !important;
}
.checkout .login-tab-container li:first-child span {
  color: #E13F3F;
}
.checkout .login-tab-container li:first-child button {
  background: white !important;
  border-radius: 0;
}
.checkout .login-tab-container li button {
  background: none !important;
  border-color: #e5e5e5 !important;
  border-radius: 8px;
  color: #444444 !important;
}
.checkout .login-tab-container li button.active {
  background: white !important;
  color: #444444 !important;
}
.checkout .checkout-full-part .checkout-normal-part {
  max-width: 1500px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part {
  float: left;
  width: 100%;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part a {
  float: left;
  margin-top: 20px;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part a {
    text-align: center;
    float: left;
    width: 100%;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part a img {
  height: 110px;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part a img {
    height: 105px;
    padding: 10px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part {
  align-items: center;
  float: left;
  height: 140px;
  border-bottom: 0;
  padding-left: 25px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part {
    height: auto;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-y: hidden;
    border-radius: 10px 10px 0 0;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item {
    min-width: fit-content;
    height: 41px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item {
  flex-basis: auto;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item {
    padding-left: 5px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item button {
  display: flex;
  align-items: center;
  padding: 0;
  color: black;
  background: none;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item button:focus {
  background: none;
  border: none;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item button:after {
  content: '';
  background-image: url('../images/arrow-right.svg');
  width: 5px;
  height: 10px;
  float: right;
  margin-left: 20px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item .nav-link.active {
  background: none;
  border: none;
  color: red;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .wizard-nav-part .nav-item:last-child button:after {
  content: none;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .select-adress-list {
  float: left;
  width: 70%;
  margin-right: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 10spx;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .select-adress-list {
    width: 100%;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .checkout-payment-button {
  float: left;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .new-address {
  color: #e34541 !important;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content {
  float: left;
  width: 100%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto 50px auto;
  position: relative;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content {
    margin-bottom: 0;
    padding: 5px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .payment-acordion {
  float: left;
  width: 70%;
  margin-right: 15px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .payment-acordion {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti {
  background: white;
  width: calc(30% - 15px);
  display: inline-block;
  background: #f9f9f9;
  height: 100%;
  padding: 15px;
  border-radius: 10px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-product-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
  border-bottom: 1px solid gainsboro;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price span span:first-child {
  color: #646464;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price span span:last-child,
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price span span .cargo-note {
  display: block;
  font-size: 13px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price .cargo-bedava {
  padding-left: 10px;
  color: #23BE72;
  font-weight: 500;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price .cargo-note {
  display: block;
  font-size: 13px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price .cargo-mesaj {
  color: #e34541;
  font-weight: 500;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-shipping-price .cargo-price {
  width: 20%;
  text-align: end;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .kdv-total-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  overflow: hidden;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .fixed-area {
  display: flex;
  background: #f7f7f7;
  width: 100%;
  align-items: center;
  left: 0;
  padding: 0 10px;
  position: fixed;
  border: 1px solid gainsboro;
  justify-content: space-between;
  bottom: 0px;
  border-radius: 10px 10px 0 0;
  z-index: 9;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .fixed-area a {
  margin-top: 0;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .fixed-area span {
  padding-right: 10px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .fixed-area span:last-child {
  color: #23BE72;
  font-family: 500;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti a {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 5px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti .order-chcekout-btn {
  background: #23BE72;
  border-color: #23BE72;
  color: white !important;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti h2 {
  font-size: 26px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .siparis-ozeti h2 {
    font-size: 22px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .tab-content {
  background: white;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content button {
  background-color: #23BE72;
  border-color: #23BE72;
  min-width: 20%;
  width: auto;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content button {
    width: 80%;
    float: none !important;
    margin: 20px 10%;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content button:focus {
  box-shadow: none;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content h1 {
  position: relative;
  bottom: 0;
  font-weight: normal;
  font-size: 26px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content label {
  margin: 15px 0;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkbox-desc {
  margin-left: 15px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkbox-desc label {
  margin: 0;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input {
  float: left;
  width: 33.333%;
  margin: 10px 0;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input {
    padding: 5px;
    margin: 5px 0;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input:first-child,
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input:nth-child(2) {
  width: 100%;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input input {
  width: 100%;
  padding: 10px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input select {
  width: 100%;
  padding: 10px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .order-note-form {
  float: left;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid gainsboro;
  padding: 20px 0;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .order-note-form .orderNote-input {
  width: 45%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .order-note-form .orderNote-input {
    width: 100%;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox {
  margin-bottom: 20px;
  margin-top: 20px;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox {
    margin-top: 10px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox > div {
  float: left;
  min-width: 200px;
  padding: 10px;
  padding-top: 0;
  display: flex;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox > div input {
  border-radius: 10px !important;
  float: left;
  width: 30px;
  margin: 0;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox > div input {
    width: 80px;
    padding-right: 10px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox > div input:focus {
  box-shadow: none !important;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox > div input:checked {
  background-color: #F6F6F6 !important;
  border: 1px solid #F6F6F6 !important;
  box-shadow: none !important;
  background-blend-mode: difference;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox > div .form-check-label {
  color: #666666 !important;
  font-size: 16px;
  height: 30px;
  display: flex;
  margin: 0 10px;
  align-items: center;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-addrescheckbox > div .form-check-label {
    font-size: 14px;
    height: auto;
    margin: 0;
    padding-left: 10px;
    text-align: left;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .cartType-edit-input select {
  height: 40px;
  width: 100%;
  padding: 10px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .accordion-header button {
  background: white;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  margin: 0;
  flex-direction: column-reverse;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .accordion-header button:after {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  content: '';
  background-image: url('../images//acordionIcon.svg');
  background-repeat: no-repeat;
  background-size: 2rem;
  transition: transform 0.2s ease-in-out;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .accordion-header button p {
  color: #b3b3b3;
  font-size: 14px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .accordion-header button img {
  height: 32px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .accordion-header .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .accordion-body {
  width: 100%;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .accordion-body {
    width: 100%;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-box {
  justify-content: flex-start;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-box button {
  width: 40%;
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-edit-input-box button {
    width: 80%;
    margin: 20px 10%;
    z-index: 9999;
  }
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox {
    padding: 0;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div {
  float: left;
  position: relative;
  min-width: 200px;
  padding: 20px;
  padding-top: 0;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div {
    padding: 0;
    min-height: fit-content;
    padding: 10px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div input {
  border-radius: 7px !important;
  width: 30px;
  height: 30px;
  border: 1px solid #707070 !important;
  position: absolute;
  width: 100%;
  height: 160px;
  background: transparent !important;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div input {
    height: 100%;
    margin-bottom: 10px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div input:focus {
  box-shadow: none !important;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div input:checked {
  background-color: #23BE72 !important;
  border: 2px solid #23BE72 !important;
  box-shadow: none !important;
  background: transparent !important;
  background-image: none !important;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div h4 {
  font-size: 18px;
}
@media only screen and (max-width: 479px), only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div h4 {
    font-size: 16px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div label {
  height: 40px;
  display: flex;
  float: left;
  width: 100%;
  height: auto;
  align-items: flex-start;
  color: #5B5B5B !important;
  margin-top: 30px;
  padding-left: 15px;
  flex-direction: column;
}
@media only screen and (max-width: 479px), only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div label {
    font-size: 14px;
    margin: 0;
    padding-left: 5px;
  }
}
@media only screen and (max-width: 479px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .wire-transfer-edit-input-checkbox > div label {
    padding-left: 15px;
    padding-top: 20px;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-address-button {
  display: flex;
  justify-content: flex-end;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-address-button button {
  background: none;
  color: black;
  border: none;
  text-align: end;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .checkout-address-button button:focus {
  box-shadow: inset 0 0px 0 #ddd;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .alert p {
  margin-bottom: 0;
  font-size: 14px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-content .alert button {
  background-color: transparent;
  width: auto;
  min-width: unset;
  padding: 15px;
  margin-top: 5px !important;
  background-color: transparent !important;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part {
  justify-content: center;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part ul {
    width: 100%;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .login-promotion-area {
  background: white;
  border-radius: 0 10px  10px 0px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part button {
  background: white;
  width: 100%;
  border-color: white;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .nav-link.active {
  background: #23BE72;
  color: white;
  border-color: #23BE72;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .tab-content {
  width: 100%;
  margin: 0;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .tab-content {
    width: 100%;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .tab-content button {
  margin-top: 40px !important;
  background: #23BE72;
  border-color: #23BE72;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .tab-content button.sginup-camps {
  margin-top: 0px !important;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .login-input-box button {
  background: white;
  border-color: white;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .login-input-box .campaign-input {
  display: flex;
  margin: 10px 0;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .login-input-box .campaign-input > div input {
  width: 26px;
  height: 26px;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .login-input-box .campaign-input button {
  font-size: 14px;
  text-align: left;
  color: #444444;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .login-input-box .campaign-input button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .login-or-sginup-normal-part .login-input-box .campaign-input button span {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}
.checkout .checkout-full-part .checkout-normal-part .checkout-header-part .tab-pane {
  padding: 10px;
}
.checkout .checkout-footer-full-part {
  background-image: url('../images/background.webp');
  margin-top: auto;
  float: left;
  width: 100%;
}
.checkout .checkout-footer-full-part .checkout-footer-normal-part {
  max-width: 1500px;
}
.checkout .checkout-footer-full-part .checkout-footer-normal-part .checkout-footer-grid-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .checkout-footer-full-part .checkout-footer-normal-part .checkout-footer-grid-part {
    flex-direction: column;
  }
}
.checkout .checkout-footer-full-part .checkout-footer-normal-part .checkout-footer-grid-part img {
  max-height: 70px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .checkout-footer-full-part .checkout-footer-normal-part .checkout-footer-grid-part img {
    width: 100%;
  }
}
.checkout .address-list-box .address-list {
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 120px;
  justify-content: space-between;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .address-list-box .address-list {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }
}
.checkout .address-list-box .address-list.active {
  background: #f9f9f9;
  background-size: 80px 80px;
  background-repeat: no-repeat;
  background-position: top right;
  border: 1px solid #23BE72;
  height: 100%;
}
.checkout .address-list-box .address-list .location-box {
  float: left;
  width: 10%;
  padding-left: 10px;
}
.checkout .address-list-box .address-list .address-box {
  float: left;
  width: 90%;
  padding: 20px;
}
.checkout .address-list-box .address-list .address-box p {
  margin-bottom: 0;
}
.checkout .address-list-box .address-list .address-box p:first-child {
  color: #7B7B7B;
}
.checkout .address-list-box .address-list .address-box p:last-child {
  font-size: 14px;
}
.checkout .address-list-box .address-list h4 {
  color: #484848;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 0;
}
.checkout .address-list-box .address-list .button-box {
  display: flex;
  justify-content: flex-end;
  z-index: 99;
}
.checkout .address-list-box .address-list .button-box button {
  background: #F2F2F2 !important;
  border-color: #F2F2F2 !important;
  margin-bottom: 10px;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
  min-width: 50px !important;
  margin-right: 10px;
}
.checkout .address-list-box .address-list .button-box button:last-child {
  width: 40px !important;
  min-width: auto !important;
}
.checkout .address-list-box .only-address {
  float: left;
  width: 100%;
  margin: 20px 0;
}
.checkout .address-list-box .only-address > div {
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.checkout .address-list-box .only-address input {
  border-radius: 10px !important;
  float: left;
  width: 30px;
  margin: 0;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
}
.checkout .address-list-box .only-address input:focus {
  box-shadow: none !important;
}
.checkout .address-list-box .only-address input:checked {
  background-color: #F6F6F6 !important;
  border: 1px solid #F6F6F6 !important;
  box-shadow: none !important;
  background-blend-mode: difference;
}
.checkout .address-list-box .only-address label {
  padding-left: 20px;
}
.checkout .address-list-box form {
  float: left;
  width: 100%;
  padding: 20px;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .checkout .address-list-box form {
    border: none;
    padding: 0;
    box-shadow: none;
  }
}
.checkout .address-list-box .teslimat-address,
.checkout .address-list-box .fatura-address {
  float: left;
  width: 100%;
  padding: 20px;
  padding: 0 !important;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
@media only screen and (max-width: 479px) {
  .checkout .address-list-box .teslimat-address,
  .checkout .address-list-box .fatura-address {
    grid-template-columns: repeat(1, 1fr);
  }
}
.checkout .address-list-box .teslimat-address .form-check-label,
.checkout .address-list-box .fatura-address .form-check-label {
  width: 100%;
  height: 100%;
}
.checkout .address-list-box .teslimat-address input[type="radio"],
.checkout .address-list-box .fatura-address input[type="radio"] {
  border-radius: 20px !important;
  width: 30px;
  height: 30px;
  background-color: white !important;
  border: 1px solid #707070 !important;
  margin-right: 20px;
  position: absolute;
  width: 85%;
  height: 100%;
  background: transparent !important;
  border: none !important;
}
.checkout .address-list-box .teslimat-address input[type="radio"]:focus,
.checkout .address-list-box .fatura-address input[type="radio"]:focus {
  box-shadow: none !important;
  border: none !important;
}
.checkout .address-list-box .teslimat-address input[type="radio"]:checked,
.checkout .address-list-box .fatura-address input[type="radio"]:checked {
  background-color: #23BE72 !important;
  border: 1px solid #23BE72 !important;
  box-shadow: none !important;
  background-image: none !important;
  background: transparent !important;
  border: none !important;
}
.checkout .address-list-box .teslimat-address label,
.checkout .address-list-box .fatura-address label {
  height: 40px;
  display: flex;
  align-items: center;
  color: #5B5B5B !important;
}
.checkout .address-list-box .teslimat-address .teslimat-address,
.checkout .address-list-box .fatura-address .teslimat-address,
.checkout .address-list-box .teslimat-address .fatura-address,
.checkout .address-list-box .fatura-address .fatura-address {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow: 0 0 3px #efefef;
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout .address-list-box .teslimat-address .teslimat-address,
  .checkout .address-list-box .fatura-address .teslimat-address,
  .checkout .address-list-box .teslimat-address .fatura-address,
  .checkout .address-list-box .fatura-address .fatura-address {
    width: 100%;
  }
}
.checkout .address-list-box .teslimat-address .teslimat-address .address-list,
.checkout .address-list-box .fatura-address .teslimat-address .address-list,
.checkout .address-list-box .teslimat-address .fatura-address .address-list,
.checkout .address-list-box .fatura-address .fatura-address .address-list {
  float: left;
  width: 100%;
}
.checkout .address-list-box .address-box {
  display: block;
}
.checkout .checkout-payment {
  float: right;
}
.order-result .App {
  background-image: none;
}
.order-result-full-part {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.order-result-normal-part {
  max-width: 1500px;
  box-shadow: 0px 0px 5px 0px #efefef;
  padding: 20px;
  background: white;
  border: 1px solid #efefef;
}
.order-result-normal-part .order-grid-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b4b4b4;
  padding-bottom: 20px;
  overflow: hidden;
}
@media only screen and (max-width: 479px) {
  .order-result-normal-part .order-grid-part {
    flex-direction: column;
  }
}
.order-result-normal-part .order-grid-part .user-name {
  display: flex;
}
.order-result-normal-part .order-grid-part .user-name .order-note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 25px;
}
.order-result-normal-part .order-grid-part .user-name svg {
  max-height: 120px;
}
.order-result-normal-part .order-grid-part .order-date .siparis-no {
  display: flex;
  padding: 20px;
  background: #23BE72;
  color: white;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
}
.order-result-normal-part .order-mesaj {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}
.order-result-normal-part .order-mesaj p {
  margin-bottom: 0;
}
.order-result-normal-part .order-mail-note {
  margin-top: 40px;
  text-align: center;
}
.order-result-normal-part .order-mail-note p {
  color: #525252;
  font-size: 14px;
  margin-bottom: 0;
}
.adres-ekleme button.checkout-payment,
.uyeliksiz button.checkout-payment {
  display: none;
}
.adres-ekleme .checkout-payment-uyeliksiz button,
.uyeliksiz .checkout-payment-uyeliksiz button {
  display: none;
}
.payment-cart-modal .modal-body iframe {
  width: 100;
  min-width: 500px;
  min-height: 350px;
  height: auto;
}
.sozlesme-label {
  display: block !important;
}
.sozlesme-button[type="button"] {
  background: none !important;
  color: #666666 !important;
  padding: 0 !important;
  border: none !important;
  min-width: unset !important;
}
@media only screen and (max-width: 479px) {
  .sozlesme-button[type="button"] {
    background: none !important;
    color: #000000 !important;
    padding: 0 !important;
    border: none !important;
    min-width: fit-content !important;
    font-size: 14px;
    font-weight: bold;
    margin: 0 !important;
    width: auto;
    text-indent: -3px;
  }
}
.garanti-pay label .garanti-image {
  display: flex;
  align-items: center;
}
.garanti-pay label p {
  font-size: 14px;
}
.garanti-pay label img {
  height: 80px;
}
.kupon-uygulama {
  margin-top: 20px;
}
.kupon-uygulama form {
  width: 100%;
}
.kupon-uygulama form .cupon-form {
  flex-direction: row;
}
.kupon-uygulama form .cupon-form .cupon-input {
  float: left;
  width: 70%;
}
.kupon-uygulama form .cupon-form .cupon-input input {
  height: 100%;
}
.kupon-uygulama form .cupon-form button {
  float: left;
  width: 30%;
  background: #ffffff;
  border-color: #e7e7e7;
  color: black;
}
.kupon-uygulama p {
  font-size: 14px;
  color: #6e6e6e;
  margin-top: 20px;
}
@media only screen and (max-width: 479px) {
  .account-new-addres .address-edit-input {
    float: left;
    width: 100%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .contact-full-part .contact-normal-part > .row {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .contact-full-part .contact-normal-part .contact-right-box {
    width: 60%;
    float: left;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .contact-full-part .contact-normal-part .contact-right-box {
    width: 80%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .contact-full-part .contact-normal-part .contact-left-box {
    margin: 0;
    max-width: unset;
    width: 97%;
  }
}
@media only screen and (max-width: 479px) {
  .contact-full-part .contact-normal-part .contact-left-box .contact-tabs-box {
    flex-wrap: nowrap;
    display: block;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px), only screen and (max-width: 479px) {
  .static-page-full-part .static-page-normal-part .static-page-left-box {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .static-page-full-part .static-page-normal-part .static-page-left-box .contact-tabs-box {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-y: hidden;
    border-radius: 10px 10px 0 0;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .static-page-full-part .static-page-normal-part .static-page-left-box .contact-tabs-box .nav-item {
    min-width: fit-content;
    height: 41px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .static-page-full-part .static-page-normal-part .static-page-left-box .tab-content {
    width: 100%;
    border-radius: 0 0 10px 10px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .static-page-full-part .static-page-normal-part .static-page-left-box .tab-content iframe {
    width: 100%;
  }
}
@media only screen and (max-width: 479px) {
  .static-page-full-part .static-page-normal-part .static-page-left-box .tab-content iframe {
    height: 300px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .user-info-grid-part {
    width: 100%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .contact-tabs-box {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    overflow: scroll;
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .contact-tabs-box li {
    min-width: fit-content;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .tab-content {
    width: 100%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .tab-content .order-acordion-container .accordion-item .accordion-header .accordion-button .order-status-box {
    flex-direction: column;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .tab-content .order-acordion-container .accordion-item .accordion-header .accordion-button .order-status-box .order-image-box {
    max-width: unset;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .tab-content .order-acordion-container .accordion-item .accordion-header .accordion-button .order-status-box .order-number,
  .account-full-part .account-normal-part .account-left-box .tab-content .order-acordion-container .accordion-item .accordion-header .accordion-button .order-status-box .order-date,
  .account-full-part .account-normal-part .account-left-box .tab-content .order-acordion-container .accordion-item .accordion-header .accordion-button .order-status-box .order-state {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .tab-content .order-acordion-container .accordion-item .accordion-header .accordion-button .order-button-box {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-full-part .account-normal-part .account-left-box .tab-content .address-list-box .address-list {
    flex-direction: column;
    padding-top: 10px;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .account-new-address .modal .modal-dialog .modal-content .modal-body form .address-edit-input-box .address-edit-input {
    width: 100%;
  }
}
@media only screen and (max-width: 479px), only screen and (min-width: 480px) and (max-width: 767px) {
  .account-new-address .modal .modal-dialog .modal-content .modal-body form button {
    width: 100% !important;
  }
}
@font-face {
  font-family: 'PaulSlab-bold';
  src: url('./font/PaulSlab-Bold-Trail.ttf') format('ttf');
  font-display: swap;
}
@font-face {
  font-family: 'PaulSlab-Regular';
  src: url('./font/PaulSlab-Regular-Trail.ttf') format('ttf'), url('./font/PaulSlab-Regular-Trail.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'PaulSlab-Thin';
  src: url('./font/PaulSlab-Thin-Trail.ttf') format('ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Rothwood-Book';
  src: url('./font/FontsFree-Net-Rothwood-Book.ttf') format('ttf'), url('./font/FontsFree-Net-Rothwood-Book.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Rothwood-Bold';
  src: url('./font/FontsFree-Net-Rothwood-Bold.ttf') format('ttf'), url('./font/FontsFree-Net-Rothwood-Bold.woff') format('woff');
  font-display: swap;
}
