    .contact-full-part{
        .contact-normal-part{
            > .row{
                @media @small,@small-big{
                    flex-direction: column-reverse;
                    align-items: center;
                }
            }
            .contact-right-box{
                @media @small-big,@small{
                    width: 60%;
                    float: left;
                    margin-bottom: 20px;
                }
                @media @small{
                    width: 80%;
                }
            }
            .contact-left-box{
                @media @small-big,@small{
                    margin: 0;
                    max-width: unset;
                    width: 97%;
                }
                .contact-tabs-box{
                    @media @small{
                        flex-wrap: nowrap;
                        display: block;
                    }
                    
                }
            }
        }
    }
    .static-page-full-part{
        .static-page-normal-part{
            .static-page-left-box{
                @media @small-big,@small{
                    display: flex;
                    flex-direction: column;
                }
                .contact-tabs-box{
                    @media @small,@small-big{
                        width: 100%;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        overflow: scroll;
                        overflow-y: hidden;
                        border-radius: 10px 10px 0 0;
                    }
                    .nav-item{
                        @media @small,@small-big{
                            min-width: fit-content;
                            height: 41px;
                        }
                        
                    }
                }
                .tab-content{
                    @media @small,@small-big{
                        width: 100%;
                        border-radius: 0 0 10px 10px;
                    }
                    iframe{
                        @media @small,@small-big{
                            width: 100%;
                        }
                        @media @small{
                            height: 300px;
                        }
                    }
                }
            }
        }
    }
    .account-full-part{
        .account-normal-part{
            .account-left-box{
                @media @small,@small-big,@tablet{
                    display: flex;
                    flex-direction: column;
                }
                .user-info-grid-part{
                    @media @small,@small-big,@tablet{
                        width: 100%;
                    }
                }
                .contact-tabs-box{
                    @media @small,@small-big,@tablet{
                        width: 100%;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: flex-end;
                        overflow: scroll;
                        margin-bottom: 20px !important;
                    }
                    li{
                        @media @small,@small-big,@tablet{
                            min-width: fit-content;
                        }
                    }
                }
                .tab-content{
                    @media @small,@small-big,@tablet{
                        width: 100%;
                    }
                    .order-acordion-container{
                        .accordion-item{
                            .accordion-header{
                                .accordion-button{
                                    .order-status-box{
                                        @media @small,@small-big,@tablet{
                                            flex-direction: column;
                                        }
                                        .order-image-box{
                                            @media @small,@small-big,@tablet {
                                                max-width: unset;

                                            }
                                        }
                                        .order-number,.order-date,.order-state{
                                            @media @small,@small-big,@tablet{
                                                margin-bottom: 10px;
                                            }
                                        }
                                    }
                                    .order-button-box{
                                        @media @small,@small-big,@tablet{
                                            justify-content: flex-start;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .address-list-box{
                        .address-list{
                            @media @small,@small-big,@tablet{
                                flex-direction: column;
                                padding-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .account-new-address{
        .modal{
            .modal-dialog{
                .modal-content{
                    .modal-body{
                        form{
                            .address-edit-input-box{
                                .address-edit-input{
                                    @media @small,@small-big,@tablet{
                                        width: 100%;
                                    }

                                }
                            }
                            button {
                                @media @small,@small-big {
                                    width: 100% !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
