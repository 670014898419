// .categories-full-part {
//     float: left;
//     width: 100%;
//     .categories-normal-part {
//         max-width: 1500px;
//         @media @laptop1366 {
//             top: -20px;
//             position: relative;
//             width: 96%;
//         }
//         @media only screen and (min-width: 992px) and (max-width: 1199px) {
//             top: -20px;
//             position: relative;
//             width: 96%;
//         }
//         .categories-product{
//             display: grid;
//             margin-left: auto;
//             margin-right: auto;
//             width: 100%;
//             grid-template-columns: repeat(4,1fr);
//             grid-gap: 20px;

//             @media @laptop1366,@laptopL {
//                 grid-template-columns: repeat(3,1fr);

//             }
//             @media @tablet,@small-big {
//                 grid-template-columns: repeat(2,1fr);

//             }
//             @media @small {
//                 grid-template-columns: repeat(1,1fr);

//             }
//             @media @laptop1366 {
//                 top: 50px;
//     position: relative;
//             }
//             @media only screen and (min-width: 992px) and (max-width: 1199px) {
//                 top: 50px;
//                 position: relative;
//             }
//         }
//         .product-box {
//             float: left;
//             margin-right: 0;
//             margin-bottom: 0;
//             min-width: unset;
//             width: 100%;
          
//             .product-cart-image-box {
//                 img {
//                     max-height: 236px;
//                     object-fit: cover;
//                     @media @laptop1280 {
//                         max-height: 200px;
//                     }
                 
//                 }
//             }
//             @media @laptop1280 {
//                 min-height: 450px;
//             }
//         }
//         h1 {
//             text-transform: capitalize;

//             @media @laptop1366 {
//                 font-size: 1.5rem;
//             }
//         }
       
//         .breadcrumb {
//             display: flex;
//             // background: @bgcolor;
//             padding: 10px 0;
//             ol {
//                 display: flex;
//                 padding: 0;
//                 @media @small,@small-big{
//                     width: 100%;
//                     flex-direction: row;
//                     flex-wrap: nowrap;
//                     overflow: scroll;
//                     overflow-y: hidden;
//                     border-radius: 10px 10px 0 0;
//                 }
//                 li {
//                     list-style: none;
//                     @media @small, @small-big {
//                         min-width: fit-content;
//                     }
//                     &:first-child {
//                         &:after {
//                             content: ' › ';
//                             padding-right: 10px;
//                         }
//                     }
//                 }
//             }
//             a {
//                 padding-right: 10px;
//                 text-decoration: none;
//                 text-transform: capitalize;
//                 &:hover {
//                     color: @yesil !important;
//                     font-weight: 600;
//                 }
//             }
//             span {
//                 padding-right: 10px;
//             }
//         }
      
//     }
//     .category-discription-seo-box {
//         float: left;
//         max-width: 1500px;
//         margin: 20px auto;
//         margin-top: 40px;
//         width: 100%;
//     }
//     .pastirma-tanitim {
        
       
        
//         a {
//             transition: all 0.5s;
//             cursor: pointer;
//             padding: 10px 50px;
//             background: @anarenk2;
//             border-color: @anarenk2;
//             color: white !important;
//             border-radius: 5px;
//           }

//           a span {
//             cursor: pointer;
//             display: inline-block;
//             position: relative;
//             transition: 0.5s;
//           }

//           a span:after {
//             content: '\00bb';
//             position: absolute;
//             opacity: 0;
//             top: 0;
//             right: -20px;
//             transition: 0.5s;
//           }

//           a:hover span {
//             padding-right: 25px;
//           }

//           a:hover span:after {
//             opacity: 1;
//             right: 0;
//           }
//     }
// }

// .breadcrumb {
//     display: flex;
//     // background: @bgcolor;
//     padding: 10px 0;

//     a {
//         padding-right: 10px;
//         text-decoration: none;
//         text-transform: capitalize;
//         &:hover {
//             color: @yesil !important;
//             font-weight: 600;
//         }
//     }
//     span {
//         padding-right: 10px;
//     }
// }
// .lezzetler-category {
//     .breadcrumb {
//         padding: 10px 0;
//     }
//     .modal-body {
//         iframe {
//             height: 500px;
//         }
//     }
//     .lezzetler-card-box  {
//         display: grid;
//         grid-template-columns: repeat(4,1fr);
//         grid-gap: 20px;
//         @media @tablet {
//             grid-template-columns: repeat(3,1fr);
//             grid-gap: 15px; 
//         }
//         @media @small-big {
//             grid-template-columns: repeat(2,1fr);
//             grid-gap: 15px; 
//         }
//         @media @small {
//             grid-template-columns: repeat(1,1fr);
//             grid-gap: 5px;

//         }
//         .card {
//             @media @small {
//                 margin-left: 0;
//             }
//         }
//     }
// }
// .header-full-part .header-category-list ul li:nth-child(7) {
//     background: url(https://www.sahin724.com/images/cat-bg-mobil.webp?v1) no-repeat left center;
//     background-size: contain; 
//     a{
//         color: white !important;
//         padding-left: 10px;
//     } 
// }
// .offcanvas-start .offcanvas-body ul a:nth-child(7) li {
//     color: red;
//     font-weight: bold;
// }


// .categories-full-part .categories-normal-part {
//     max-width: 1500px;
//     background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
//     background-size: contain;
//     padding-top: 31px;
//     @media @tablet {
//         background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
//     background-size: contain;
//     padding-top: 31px;
//     }
//     @media @small-big {
//         background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
//         background-size: contain;
//         padding-top: 22px;
//     }
//     @media @small {
//         background: url(https://www.sahin724.com/images/cat-bg-mobil.webp) no-repeat 12px 9px;
//         background-size: 350px;
//         padding-top: 8px;
//     }
// }
// .categories-full-part .categories-normal-part h1 { 
//     color: white;
//     margin-left: 14px;
//     @media @tablet {
//         color: white;
//         top: -10px;
//         position: relative;
//         font-size: 16px;
//     }  
//     @media @small-big {
//         color: white;
//         top: -10px;
//         position: relative;
//     }  
//     @media @small {
//         color: white;
//         margin: 12px 15px;
//         font-size: 18px;
//     }  
//     @media @laptop1366 {
//         padding-top: 10px;
//         font-size: 18px;
//     }
//     @media only screen and (min-width: 992px) and (max-width: 1199px) {
//         font-size: 13px;
//     }
// }
// .categories-full-part .categories-normal-part .breadcrumb { 
//     color: white; 
//     margin-left: 14px;
//     top: -5px;
//     position: relative;

//     @media @tablet {
//         position: relative;
//     top: -23px;
//     font-size: 12px;
//     height: 40px;
//     }
//     @media @small-big {
//         position: relative;
//     top: -23px;
//     font-size: 12px;
//     height: 40px;
//     }
//     @media @small {  
//             font-size: 12px;
//             margin: 0px 15px;
//             top: -12px;
//             position: relative;
//     }
//     @media @laptop1366 {
//         font-size: 11px;
//     }
//     @media only screen and (min-width: 992px) and (max-width: 1199px) {
//         font-size: 11px;
//     }
// }

// .categories-full-part .categories-normal-part .breadcrumb a { 
//     color: white !important;
// }
.categories-full-part {
    float: left;
    width: 100%;

    .categories-normal-part {
        max-width: 1500px;

        @media screen and (min-width: @laptop1366) {
            top: -20px;
            position: relative;
            width: 96%;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
            top: -20px;
            position: relative;
            width: 96%;
        }
        div.subConMenu {
            @media only screen and (max-width: 479px){
                top:-10px;
                position: relative;
            }
        }
        
        .cat-sub-categories {
            display: flex;
            list-style-type: none;
            gap: 15px;
            width: 100%;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            align-items: center;
            padding: 0px !important;
            margin: 40px 0px 50px 0px;
            flex-wrap: wrap; 
            li{
                display: flex !important; 
                text-wrap: nowrap;
                text-decoration: none !important;
                border-radius: 5px;
                width: 91px;
                flex-direction: column;
                height: 105px;
                a{
                    padding: 5px 10px;
                    text-decoration: none !important;
                    color: blue !important;
                    font-size: 14px;
                    img{
                        border-radius: 6px;
                        width: 75px !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: space-between;
                        flex-wrap: nowrap;
                        text-align: center;
                        margin: auto;
                        margin-bottom: 8px;
                        box-shadow: rgb(165 142 107) -2px 3px 14px 0px, rgb(255 255 255) 1px 6px 8px 9px;
                    }
                    span {
                        text-align: center;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        text-wrap: wrap;
                        font-family: system-ui;
                        font-size: smaller;
                        color: black;

                    }
                }
            }
            @media only screen and (max-width: 479px){
                
                overflow: hidden;
                overflow-x: scroll;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                margin: 0px 0px 10px 0px !important;
                gap: 4px 6px;
                li{ 
                    border-radius: 3px;
                    a{
                        padding:5px 8px; 
                        font-size: 12px; 
                    }
                }
            }
            @media only screen and (min-width: 768px) and (max-width: 991px){
                margin: 0px 0px 25px 0px !important;
                overflow: hidden;
                overflow-x: scroll;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
            }
            @media only screen and (min-width: 992px) and (max-width: 1336px){
                margin: 0px 0px 25px 0px !important;
                overflow: hidden;
                overflow-x: scroll;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
            }
        }
        .categories-product {
            display: grid;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;

            @media @laptop1366, @laptopL {
                grid-template-columns: repeat(3, 1fr);
            }

            @media @tablet, @small-big {
                grid-template-columns: repeat(2, 1fr);
            }

            @media @small {
                grid-template-columns: repeat(1, 1fr);
            }

            @media @laptop1366 {
                top: 50px;
                position: relative;
            }

            @media only screen and (min-width: 992px) and (max-width: 1199px) {
                top: 50px;
                position: relative;
            }
            @media @laptopL {
                top:0px !important;
            }
        }

        .product-box {
            float: left;
            margin-right: 0;
            margin-bottom: 0;
            min-width: unset;
            width: 100%;

            .product-cart-image-box {
                img {
                    max-height: 236px;
                    object-fit: cover;

                    @media @laptop1280 {
                        max-height: 200px;
                    }
                }
            }

            @media @laptop1280 {
                min-height: 450px;
            }
        }

        h1 {
            text-transform: capitalize;

            @media @laptop1366 {
                font-size: 1.5rem;
            }
        }

        .breadcrumb {
            display: flex;
            padding: 10px 0;

            ol {
                display: flex;
                padding: 0;

                @media @small, @small-big {
                    width: 100%;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow: scroll;
                    overflow-y: hidden;
                    border-radius: 10px 10px 0 0;
                }

                li {
                    list-style: none;

                    @media @small, @small-big {
                        min-width: fit-content;
                    }

                    &:first-child {
                        &:after {
                            content: ' › ';
                            padding-right: 10px;
                        }
                    }
                }
            }

            a {
                padding-right: 10px;
                text-decoration: none;
                text-transform: capitalize;

                &:hover {
                    color: @yesil !important;
                    font-weight: 600;
                }
            }

            span {
                padding-right: 10px;
            }
        }
    }

    .category-discription-seo-box {
        float: left;
        max-width: 1500px;
        margin: 20px auto;
        margin-top: 40px;
        width: 100%;

        
        @media (min-width: 768px){
            margin-top: 120px !important;
        }

    }

    .pastirma-tanitim {
        a {
            transition: all 0.5s;
            cursor: pointer;
            padding: 10px 50px;
            background: @anarenk2;
            border-color: @anarenk2;
            color: white !important;
            border-radius: 5px;
        }

        a span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
        }

        a span:after {
            content: '\00bb';
            position: absolute;
            opacity: 0;
            top: 0;
            right: -20px;
            transition: 0.5s;
        }

        a:hover span {
            padding-right: 25px;
        }

        a:hover span:after {
            opacity: 1;
            right: 0;
        }
    }
}

.breadcrumb {
    display: flex;
    padding: 10px 0;

    a {
        padding-right: 10px;
        text-decoration: none;
        text-transform: capitalize;

        &:hover {
            color: @yesil !important;
            font-weight: 600;
        }
    }

    span {
        padding-right: 10px;
        
        @media @laptop1280 {
            font-size: 12px;
        }
    }
}

.lezzetler-category {
    .breadcrumb {
        padding: 10px 0;
    }

    .modal-body {
        iframe {
            height: 500px;
        }
    }

    .lezzetler-card-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;

        @media @tablet {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 15px;
        }

        @media @small-big {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 15px;
        }

        @media @small {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 5px;
        }

        .card {
            @media @small {
                margin-left: 0;
            }
        }
    }
}
.header-full-part  {
    @media @laptop1280 {
         height: 110px;;
    }
}
.header-full-part .header-category-list ul li:nth-child(7) {
    background: url(https://www.sahin724.com/images/cat-bg-mobil.webp?v1) no-repeat left center;
    background-size: contain;
    
    a {
        color: white !important;
        padding-left: 10px;
    }
}

.offcanvas-start .offcanvas-body ul a:nth-child(7) li {
    color: red;
    font-weight: bold;
}

.categories-full-part .categories-normal-part {
    max-width: 1500px;
    background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
    background-size: contain;
    padding-top: 31px;
    width: 98%;
    @media @tablet {
        background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
        background-size: contain;
        padding-top: 31px;
    }

    @media @small-big {
        background: url(https://www.sahin724.com/images/cat-back.webp?v1) no-repeat left 0px;
        background-size: contain;
        padding-top: 22px;
    }

    @media @small {
        background: url(https://www.sahin724.com/images/cat-bg-mobil.webp) no-repeat 12px 9px;
        background-size: 350px;
        padding-top: 8px;
    }
}

.categories-full-part .categories-normal-part h1 {
    color: white;
    margin-left: 14px;
    
    @media @laptop1280 {
        padding-top: 8px;
        width: 98%;
        font-size: 16px;
    }
    @media @tablet {
        color: white;
        top: -10px;
        position: relative;
        font-size: 16px;
    }

    @media @small-big {
        color: white;
        top: -10px;
        position: relative;
        
    }

    @media @small {
        color: white;
        margin: 12px 15px;
        font-size: 18px;
    }

    @media @laptop1366 {
        padding-top: 10px;
        font-size: 18px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 13px;
    }
}

.categories-full-part .categories-normal-part .breadcrumb {
    color: white;
    margin-left: 14px;
    top: -5px;
    position: relative;
    
    @media @laptop1280 {
        top:-10px; 
    }

    @media @tablet {
        position: relative;
        top: -23px;
        font-size: 12px;
        height: 40px;
    }

    @media @small-big {
        position: relative;
        top: -23px;
        font-size: 12px;
        height: 40px;
    }

    @media @small {
        font-size: 12px;
        margin: 0px 15px;
        top: -12px;
        position: relative;
    }

    @media @laptop1366 {
        font-size: 11px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 11px;
    }
}

.categories-full-part .categories-normal-part .breadcrumb a {
    color: white !important;
    @media @laptop1280 {
        font-size: 12px;
    }
}
