.sales-quiry,
.about-account {
    background-image: none;
    background: @bgcolor;

    .App {
        background-image: none;
    }
}

.sales-quiry-full-part {
    margin-bottom: 20px;

    .sales-quiry-normal-part {
        max-width: 1500px;

        h1 {
            color: #999999;
            font-size: 24px;
        }

        p {
            font-size: 14px;
            color: #999999;
        }

        .sales-quirt-grid-part {
            background: white;
            padding: 20px;
            border-radius: 10px;

            button {

                // background: @yesil;
                // border: @yesil;
                &:focus {
                    box-shadow: none;
                }

                button {

                    @media @small,
                    @small-big {
                        width: 100% !important;
                    }
                }
            }

            .address-edit-input-box {
                @media @small {
                    flex-direction: column;

                    .address-edit-input {
                        width: 100%;
                    }
                }
            }
        }

        .order-acordion-container {
            .accordion-header {
                button {
                    flex-direction: column;

                    &:after {
                        content: none;
                    }

                    &:before {
                        flex-shrink: 0;
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-left: auto;
                        content: '';
                        background-image: url('../../images//acordionIcon.svg');
                        background-repeat: no-repeat;
                        background-size: 1.25rem;
                        transition: transform 0.2s ease-in-out;
                    }

                    .order-status-box {
                        display: flex;
                        justify-content: space-between;
                        float: left;
                        width: 100%;

                        @media @small {
                            flex-direction: column;
                        }

                        .order-image-box {
                            flex-grow: 1;
                            max-width: 220px;
                            overflow: hidden;

                            ul {
                                display: flex;
                                padding: 0;

                                li {
                                    list-style: none;
                                    padding-left: 10px;

                                    &:nth-child(n) {
                                        margin-left: -15%;
                                    }

                                    &:first-child {
                                        margin-left: 0;
                                        padding-left: 0;
                                    }

                                    img {
                                        height: 64px;
                                        width: 64px;
                                        border-radius: 100px;
                                    }


                                }
                            }
                        }

                        .order-number,
                        .order-date,
                        .order-state {
                            @media @small {

                                text-align: center;
                                margin-bottom: 10px;
                            }

                            span {
                                font-size: 14px;

                                &:first-child {
                                    font-size: 16px;
                                    color: #666666;
                                    padding-bottom: 10px;
                                }

                                float: left;
                                width: 100%;
                            }
                        }

                        .order-state {
                            .span {

                                color: #1B971C;
                            }

                            .siparis-saati {
                                color: black;
                            }

                            .iptal-edildi {
                                color: #ca0000;
                            }

                        }
                    }

                    .order-button-box {
                        float: left;
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        button {
                            margin-right: 10px;
                            background: #F8F7F7;
                            border-color: #E3E3E3;
                            color: #5D5D5D;
                            font-size: 13px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &:hover,
                            &:focus {
                                border-color: #1B971C;
                                color: #1B971C;
                            }
                        }
                    }
                }

                button[aria-expanded="true"] {
                    background: white;
                    border-color: white;

                    &:focus {
                        background: white;
                        color: black;
                        border-color: white;
                    }

                    &:focus-visible {
                        border-color: white;
                    }

                    span {
                        color: black;
                    }

                    &::before {
                        -ms-transform: rotate(90deg);
                        /* IE 9 */
                        transform: rotate(90deg);
                    }
                }
            }

            .accordion-body {
                .order-product-box {
                    float: left;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    border-bottom: 1px solid gainsboro;
                    padding: 20px 0;
                    align-items: center;

                    @media @small {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    &:first-child {
                        margin-top: 20px;
                    }

                    .order-product-image {
                        flex-basis: 30%;

                        img {
                            height: 100px;
                            width: auto;
                            border-radius: 10px;

                        }
                    }

                    .indirimli-fiyat {
                        flex-basis: 30%;
                        text-decoration: line-through;
                        text-align: center;
                        color: @anarenk2;
                    }

                    .product-code {
                        flex-basis: 35%;

                        @media @small {
                            text-align: center;
                        }

                        span {
                            &:first-child {
                                color: #666666;
                            }

                            float: left;
                            width: 100%;
                        }
                    }

                    .oder-product-price {
                        text-align: center;
                        flex-basis: 35%;

                        span {
                            &:first-child {
                                float: left;
                                width: 100%;
                                color: @yesil;
                                font-size: 18px;
                            }

                            &:last-child {
                                font-size: 12px;
                                color: #b1b1b1;
                            }
                        }
                    }
                }

                .order-address {
                    float: left;
                    width: 100%;

                    @media @small,
                    @small-big {
                        display: flex;
                        flex-direction: column-reverse;
                    }

                    .adresler {
                        float: left;
                        width: 50%;
                        font-style: normal;
                        line-height: 19px;
                        word-break: break-word;
                        color: #484848;
                        font-size: 14px;

                        @media @small {
                            float: left;
                            width: 100%;
                        }

                        h4 {
                            color: #484848;
                            font-size: 20px;
                            font-weight: 600;
                            margin-bottom: 24px;
                            margin-top: 0;
                        }

                        .teslimat-adresi {
                            float: left;
                            width: 100%;
                            margin-bottom: 20px;
                            font-size: 12px;
                        }

                        .fatura-adresi {
                            float: left;
                            font-size: 12px;
                            width: 100%;
                        }
                    }

                    .odeme-bilgileri {
                        float: left;
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        @media @small,
                        @small-big {
                            float: left;
                            width: 100%;
                            align-items: flex-start;
                        }

                        h4 {
                            color: #484848;
                            font-size: 20px;
                            font-weight: 600;
                            margin-bottom: 24px;
                            margin-top: 0;
                            width: 70%;
                            text-align: left;

                            @media @small,
                            @small-big {
                                width: 100%;
                            }
                        }

                        ul {
                            width: 70%;
                            background: #EFEFEF;
                            padding: 20px;
                            border-radius: 10px;

                            @media @small,
                            @small-big {
                                width: 100%;
                            }

                            li {
                                list-style: none;
                                display: flex;
                                justify-content: space-between;
                                font-size: 15px;
                                color: #4A4A4A;

                                &:last-child {
                                    padding-top: 10px;
                                    margin-top: 10px;
                                    border-top: 1px solid gainsboro;

                                    span {
                                        &:last-child {
                                            font-weight: 500;
                                        }

                                    }
                                }

                                .kargo-indirim {
                                    text-decoration: line-through;
                                }

                                .siparis-indirimi {
                                    color: @yesil;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.tarifler-detay {
    .breadcrumb {
        padding: 10px 0;
    }

}

.tarifler-detay-full-part {
    margin-bottom: 20px;

    .tarifler-detay-normal-part {
        max-width: 1500px;
       
        .tarif-detay-grid-part {
            float: left;
            width: 100%;
            background: white;
            display: flex;
            box-shadow: 0 3px 3px gainsboro;
            justify-content: space-between;
            border-radius: 10px;
            min-height: 520px;
            margin-bottom: 40px;

            @media @small,
            @small-big,
            @tablet {
                flex-direction: column;
            }

            .tarif-video {
                width: 50%;
                padding: 20px;
                float: left;

                @media @small,
                @small-big,
                @tablet {
                    width: 100%;
                    height: 400px;
                }

                iframe {
                    border-radius: 16px;
                }
            }

            .tarif-aciklama {
                padding: 20px;
                width: 50%;
                float: left;

                @media @small,
                @small-big,
                @tablet {
                    width: 100%;
                }

                ol {
                    line-height: 28px;
                    font-size: 14px;
                }
            }
        }

        .relation-product {
            .mySwiper {
                max-height: 450px;
                margin-top: 20px;

                // overflow: visible;
                @media @small,
                @small-big {
                    overflow: hidden;
                }

                .swiper-wrapper {
                    max-height: 450px;
                }
            }

            .swiper-button-next {
                right: -60pxx;

                @media @small {
                    display: none;
                }

                &:after {
                    content: url('../../images//Next.svg');

                    @media @laptop1280,
                    @laptopL,
                    @tablet,
                    @small,
                    @small-big {
                        background-image: url('../../images//Next.svg');
                        background-size: 100% 100%;
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        content: "";
                    }
                }
            }

            .swiper-button-prev {
                left: -60px;

                @media @small {
                    display: none;
                }

                &:after {
                    content: url('../../images//prev.svg');

                    @media @laptop1280,
                    @laptopL,
                    @tablet,
                    @small,
                    @small-big {
                        background-image: url('../../images//prev.svg');
                        background-size: 100% 100%;
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        content: "";
                    }

                }
            }

            .swiper-pagination-fraction {
                bottom: -30px !important;

            }

            .swiper-pagination-bullets {
                bottom: -4px; 
            }

            .swiper-pagination-bullet-active {
                background: @anarenk2;

            }

            h2 {
                font-size: 22px;
            }

            .product-box {
                min-height: fit-content;
                float: left;
                width: 100%;
            }
        }

        .breadcrumb {
            ol {
                display: flex;
                padding: 0;

                @media @small,
                @small-big {
                    width: 100%;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow: scroll;
                    overflow-y: hidden;
                    border-radius: 10px 10px 0 0;
                }

                li {
                    list-style: none;

                    @media @small,
                    @small-big {
                        min-width: fit-content;
                    }

                    &:first-child,
                    &:nth-child(2) {
                        &:after {
                            content: ' › ';
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .tarifler-detay-loading-part {
        display: flex;
        justify-content: center;
    }
}

.not-found-page-full-part {
    background: @bgcolor;

    .not-found-page-normal-part {
        max-width: 1500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
    }
}

.sozlesme-button {
    background: none;
    border: none;
    box-shadow: none;
}

.direct-checkout {
    .App {
        background: none;
    }

    .driect-checkout-full-part {
        margin-bottom: 40px;

        @media @small,
        @small-big {
            margin-bottom: 20px;
        }

        .driect-checkout-normal-part {
            width: 95%;
            max-width: 1500px;

            @media @small,
            @small-big {
                width: 100%;
                max-width: 1500px;
                padding: 0;
                overflow: hidden;
            }

            .checkout-edit-input-box {
                justify-content: flex-start;

                button {
                    width: 40%;
                }
            }

            .checkout-edit-input {
                float: left;
                width: 33.333%;
                margin: 10px 0;

                @media @small {
                    padding: 5px;
                    margin: 5px 0;
                }

                &:first-child,
                &:nth-child(2) {
                    width: 100%;
                }

                input {
                    width: 100%;
                    padding: 10px;

                }

                select {
                    width: 100%;
                    padding: 10px;
                }
            }

            .direct-checkout-full-part {
                display: flex;
                justify-content: space-between;

                @media @small,
                @small-big {
                    flex-direction: column;
                }

                .direct-checkout-lef-part {
                    width: 100%;
                }

                .cart-index-right-part {
                    width: 100%;
                    float: left;
                    background: white;
                    margin-left: 20px;
                    padding: 20px;
                    border-radius: 10px;

                    @media @small,
                    @small-big {
                        margin-left: 0;
                        margin-top: 10px;
                    }

                    .order-product-price {
                        border: 1px dashed gainsboro;
                        padding: 10px;
                        border-radius: 10px;

                        .product-list {
                            margin-top: 10px;

                            .product {
                                display: flex;
                                justify-content: space-between;

                                p {
                                    display: flex;
                                    flex-direction: column;

                                    @media @small {
                                        max-width: 220px;
                                    }
                                }

                                .price {

                                    color: @yesil;
                                    font-weight: 600;

                                }

                                margin-bottom: 10px;
                            }

                        }
                    }

                    .total-price {
                        display: flex;
                        padding: 10px;
                        justify-content: space-between;

                        span {
                            &:last-child {
                                color: @yesil;
                                font-weight: 600;
                            }
                        }
                    }

                    .indirimler {

                        span {
                            &:last-child {
                                color: @anarenk2;
                                font-weight: 600;
                            }
                        }

                    }

                    .order-shipping-price {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;
                        margin-top: 10px;
                        border-bottom: 1px solid gainsboro;

                        span {
                            float: left;
                            width: 100%;
                        }

                        .cargo-note {
                            font-size: 14px;
                            color: #8a8a8a;
                        }

                        .cargo-price {
                            text-align: end;
                            color: @yesil;
                            font-weight: 600;
                        }
                    }

                    .kdv-total-price {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;

                        span {
                            &:last-child {
                                color: @yesil;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .checkout-edit-input-addrescheckbox {
            margin-bottom: 20px;
            margin-top: 20px;

            @media @small {
                margin-top: 10px;
            }

            >div {
                float: left;
                min-width: 200px;
                padding: 10px;
                padding-top: 0;
                display: flex;

                input {
                    border-radius: 10px !important;
                    float: left;
                    width: 30px;
                    margin: 0;
                    height: 30px;
                    background-color: white !important;
                    border: 1px solid #707070 !important;

                    @media @small,
                    @small-big {
                        width: 80px;
                        padding-right: 10px;
                    }

                    &:focus {
                        box-shadow: none !important;
                    }

                    &:checked {
                        background-color: #F6F6F6 !important;
                        border: 1px solid #F6F6F6 !important;
                        box-shadow: none !important;
                        background-blend-mode: difference;
                    }
                }

                .form-check-label {
                    color: #666666 !important;
                    font-size: 16px;
                    height: 30px;
                    display: flex;
                    margin: 0 10px;
                    align-items: center;

                    @media @small {
                        font-size: 14px;
                        height: auto;
                        margin: 0;
                        padding-left: 10px;
                        text-align: left;
                    }

                }
            }

        }

        button {
            background-color: @yesil;
            border-color: @yesil;
            min-width: 20%;
            width: auto;

            &:focus {
                box-shadow: none;
            }
        }

        .wire-transfer-edit-input-checkbox {
            @media @small {
                padding: 0;

            }

            >div {
                float: left;
                position: relative;
                min-width: 200px;
                padding-top: 0;
                width: 100%;
                display: flex;
                margin-bottom: 20px;

                @media @small {
                    padding: 0;
                    margin-bottom: 55px;
                }

                input {
                    border-radius: 20px !important;
                    width: 30px;
                    height: 30px;
                    border: 1px solid #707070 !important;
                    position: absolute;
                    width: 100%;
                    height: 160px;
                    background: transparent !important;

                    @media @small {
                        height: 170px;
                        margin-bottom: 10px;
                    }

                    &:focus {
                        box-shadow: none !important;
                    }

                    &:checked {
                        background-color: @yesil  !important;
                        border: 2px solid @yesil  !important;
                        box-shadow: none !important;
                        // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
                        background: transparent !important;
                    }
                }

                h4 {
                    font-size: 18px;

                    @media @small,
                    @small {
                        font-size: 16px;
                    }
                }

                label {
                    height: 40px;
                    display: flex;
                    float: left;
                    width: 100%;
                    height: auto;
                    align-items: flex-start;
                    color: #5B5B5B !important;
                    margin-top: 30px;
                    padding-left: 15px;
                    flex-direction: column;

                    @media @small,
                    @small {
                        font-size: 14px;
                        margin: 0;
                        padding-left: 5px;
                    }

                    @media @small {
                        padding-left: 15px;
                        padding-top: 20px;
                    }

                }
            }

        }

        .garanti-pay {
            label {
                .garanti-image {
                    display: flex;
                    align-items: center;
                }

                p {
                    font-size: 14px;
                }

                img {
                    height: 80px;
                }
            }
        }
    }

    .accordion-header {
        button {
            // flex-direction: column;
            background: white;
            align-items: flex-start;
            justify-content: space-between;
            max-width: 100%;
            width: 100%;
            flex-direction: column-reverse;

            &:after {
                flex-shrink: 0;
                width: 2rem;
                height: 2rem;
                margin-left: auto;
                content: '';
                background-image: url('../../images//acordionIcon.svg');
                background-repeat: no-repeat;
                background-size: 2rem;
                transition: transform 0.2s ease-in-out;

            }

            p {
                color: #b3b3b3;
                font-size: 14px;
            }

            img {
                height: 32px;
            }
        }

        .accordion-button:not(.collapsed)::after {
            transform: rotate(90deg);
        }
    }

    .accordion {
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
    }

    .accordion-body {
        width: 100%;

        @media @small,
        @small-big,
        @tablet {
            width: 100%;
        }
    }
}

.custom-page-full-part {
    .custom-page-normal-part {
        max-width: 1500px;
        width: 95%;

        .custom-page-image-box {
            float: left;
            width: 100%;
            margin-bottom: 20px;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }

        .custom-page-description {
            float: left;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 10px;
            justify-content: center;
            background: gainsboro;
            border-radius: 10px;

            svg {
                height: 40px;
                padding-right: 10px;
            }

            span {
                font-size: 22px;

            }
        }

        .custom-vakif-part {
            // float: left;
            // width: 100%;
            // display: grid;
            // grid-template-columns: repeat(2,1fr);
            // grid-gap: 20px;
            // margin-top: 20px;
            // @media @tablet {
            //     grid-template-columns: repeat(2,1fr);
            //     grid-gap: 15px; 
            // }
            // @media @small-big {
            //     grid-template-columns: repeat(2,1fr);
            //     grid-gap: 15px; 
            // }
            // @media @small {
            //     grid-template-columns: repeat(2,1fr);
            //     grid-gap: 5px;

            // }
            display: flex;
            align-items: center;
            float: left;
            width: 100%;
            justify-content: center;
            margin-top: 20px;

            img {
                width: auto;
                float: left;
                width: 100%;
            }
        }

        .custom-video {
            float: left;
            width: 100%;
            margin-top: 20px;

            &:last-child {
                margin: 20px 0;
            }

            iframe {
                border-radius: 10px;

            }
        }

        .custom-read-more {
            float: left;
            width: 100%;
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 20px;
            align-items: center;
            justify-items: center;
            background: white;
            padding: 12px;
            margin-bottom: 20px;
            border-radius: 10px;

            @media @tablet {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 15px;
            }

            @media @small-big {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 15px;
            }

            @media @small {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 5px;

            }

            span {
                font-size: 22px;
                font-weight: 600;
            }

            img {
                height: 100px;
            }

        }

        .kizilay-aciklama {
            float: left;
            width: 100%;

            img {
                width: 100%;
            }
        }
    }
}

.pastirma-tanitim-full-part {
    .pastirna-tanitim-normal-part {
        max-width: 1500px;
        width: 100%;

        h1 {
            font-size: 28px;
            margin-bottom: 20px;
        }

        iframe {
            border-radius: 8px;
        }
    }
}


button[type="submit"] {
    background-color: @yesil;
    border-color: @yesil;

    &:focus {
        background-color: @yesil;
        border-color: @yesil;
        box-shadow: none;
    }

    &:hover {
        background-color: @yesil;
        border-color: @yesil;
    }
}