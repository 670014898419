   .product-box {
      width: 100%;
      min-height: 480px;
      margin-right: 20px;
      margin-bottom: 20px;
      float: left;  
      box-shadow: 0 3px 12px rgba(0, 0, 0, .17);
      background: white;
      border-radius: 13px;     
      transition: all .5s ease-in-out;
      &:hover {
        box-shadow: 0px 7px 20px 0px #b7b7b7;
        transform: translate(0, -10px);
        // transition: all .5s ease-in-out;
        // transition-delay: 0.5s !important;
        @media @small {
          transform: none;
        }
      }
      @media @laptop1366 {
        width: calc(33% - 30px);
        min-height: 480px;
      }
      @media @laptop1280,@laptopL {
        width: calc(33.3333% - 20px);
        min-height: 460px;
        margin-right: 20px;
      }
      @media @laptop1280 {
        min-height: 480px;
      }
      @media @tablet {
        width: calc(33.333% - 10px);
        margin-right: 10px;
        min-height: 350px;
    }
    @media @small  {
      width: 100%;
      min-height: unset;
      margin-bottom: 20px;
      min-height: unset !important;
      transition:none;
      border: 1px solid #ededed;
      box-shadow: none;
      &:last-child{
        margin-bottom: 0;
      }
    }
    @media @small-big {
      width:calc(50% - 10px);
      min-height: 360px;
      margin-bottom: 20px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      
    }
      a {
        float: left;
        width: 100%;
      }
     
      .product-cart-image-box {
        float: left;
        width: 100%;
        span {
          width: 100%;
        }
        img{
            width: 100%;
            border-radius: 13px 13px 0 0 ; 
            min-height: 180px;
            max-height: 220px;
            object-fit: cover;
           
        }
      }
    
      .product-cart-desc {
        float: left;
        width: 100%;
        background: white;
        border-radius: 0 0 10px 10px;
        text-align: left;
        display: flex;
        flex-direction: column;
        height: auto;
        border-radius: 20px;
        padding: 15px 0;
        @media @laptop1366 {
           min-height: 214px;
        }
        @media @small {
          height: auto;
        }
        @media @laptopL {
          height: 240px;
        }
        @media @laptop1280 {
          height: auto;
        }
        A {
          text-decoration: none;
        }
        h3 {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: normal;
          min-height: 48px;
          padding: 10px 25px 0 25px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // -webkit-box-orient: vertical;
          // // -webkit-line-clamp: 2;
          // height: 62px;
          // min-height: 62px;
          color: #6b6b6b;
          @media @tablet,@small,@small-big{
            padding: 10px;
            font-size: 18px;
          }
          @media @laptop1280 {
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              min-height: fit-content;
          }
          @media @small {
            min-height: unset;
            height: auto;
            padding-bottom: 2px;
            overflow: initial;
          }
        }
        .product-price {
          // min-height: 55px;
          padding: 0 10px;
          margin-top: auto;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          @media @tablet, @small,@small-big {
            min-height: 30px;
            padding: 0 10px;
          }
          @media @small {
            min-height: unset;
            height: auto;
            margin-top: 0;
          }
          .eski-fiyat {
            color: #D15A5E;
            font-size: 20px;
            margin-bottom: 0px;
            text-decoration: line-through;
            padding-left: 10px;
           
          }
          .fiyat {
            color: @yesil;
            font-size: 24px;
            margin-bottom: 0;
            font-weight: 800;
            padding-left: 10px;
            @media @tablet {
              font-size: 20px;
            }
          }
         
        
        }

        .product-count {
          float: left;
          width: 100%;
          padding: 0px 25px 10px;
          font-size: 13px;
          @media @tablet, @small,@small-big {
            font-size: 13px;
            padding: 10px;
            padding-top: 0;
          }
          span {
            float: left;
            color: #a2a2a2;
          }
        }
        
      }
      .product-add-to-cart {
        display: flex;
        justify-content: space-between; 
        width: 90%;
        margin: auto auto;
        // margin-top: auto;
        @media @small {
          width: 98%;
          padding: 10px 5px;
        }
        .add-to-cart {
          border-radius: 10px;
          width: 45%;
          @media @laptopL,@laptop1280 {
            width: 45%;
          }
          @media @tablet,@small,@small-big {
            width: 48%;
          }
          @media @small {
            width: 49%;
          }
          button {
            background: @yesil;
            border-color: @yesil;
            height: 50px;
            border-radius: 10px;
            width: 100%;
            @media @tablet {
              font-size: 13px;
              height: 100%;
            }
            @media @small-big {
              font-size: 13px;
            }
          }
        }
        .numbers-row {
          background: #F9F6F5;
          width: 50%;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          @media @laptopL,@laptop1280 {
            width: 52%;
          }
          @media @tablet {
            width: 50%;
            font-size: 13px;
            height: 40px;
          }
          @media @small {
            width: 49%;
          }
          .dec {
            float: left;
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-right: 1px solid #f3f3f3;
            height: 50px;
            background: #F9F6F5;
            border-radius: 10px 0 0 10px;
            @media @tablet {
              height: 40px;
            }
          }
          .inc {
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            border: none;
            border-left: 1px solid #f3f3f3;
            height: 50px;
            background: #F9F6F5;
            border-radius: 0 10px 10px 0px;
            @media @tablet {
              height: 40px;
            }
          }
          span {
            color: #343132;
            text-align: center;
            font-size: 13px;
          }
          .input-are{
            max-width:40%;
            float: left;
            height: 40px;
            .productQuantityTextBox{
              float: left;
              width: 100%;
              height: 26px;
              background: #F9F6F5;
              text-align: center;
              border: none;
            }
            span {
              float: left;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      
    }
  
    .category {
      .product-box {
            min-height: fit-content;
            @media @laptop1280 {
              min-height: 430px;
            }
            @media @small-big,@tablet,@laptopL {
              min-height: auto;
            }
            
      }
      .product-cart-desc {
        height: auto;
        @media @small {
          height: auto;
        }
      }
    }
.product-add-to-cart-modal {
  .modal-dialog {
    @media @small , @small-big,@tablet{
      margin: 0;
      float: left;
      width: 100%;
    }
  }
  .modal-body {
      display: flex;
      justify-content: space-between;
      background: #f7f7f7;
      .product-cart-image-box {
        display: flex;
        align-items: center;
        img {
          height: 120px;
          border-radius: 10px;
          @media @small {
            height: 70px;
         }

        }
        h3 {
          padding-left: 10px;
          margin-bottom: 0;
          font-size: 22px;
          font-weight: normal;
        }
        svg {
          height: 80px;
        
        }
      }
      .product-name-unit {
        span {
          padding-left: 5px;
          color: #8c8c8c;
        }
      }
      .product-price {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @media @tablet {
          height: auto;
        }
        @media @small {
          min-width: 120px;
        }
        .eski-fiyat {
          color: #D15A5E;
          font-size: 20px;
          margin-bottom: 0px;
          text-decoration: line-through;

        }
        .fiyat {
          color: @yesil;
          font-size: 24px;
          margin-bottom: 0;
          font-weight: 800;
        }
      }
    .siparis-tekrarla {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .order-list-modal {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .product-price {
          min-width: 150px;
        }
        .cart-image{
          min-width: 200px;
          object-fit: contain;
        }
      }
    }
  }
  .modal-content {
    width: 750px;
    margin: 0 auto;
    @media @small,@small-big {
      width: 100%;
    }
  }
  .modal-header {
    flex-direction: column-reverse;
    svg {
      @media @small,@small-big {
        height: 80px;
      }
      @media @small {
        height: 60px;
      }
    }
    button {
      background-color: #9c9c9c;
    }
  }
  .modal-footer {
    justify-content: center;
    .link-box {
      display: flex;
      flex-direction: column;
      button {
        background: none;
        border: none;
        box-shadow: none;
        color: black;
        text-decoration: underline;
      }
      a {
        background: @yesil;
        padding: 10px 40px;
        border-radius: 5px;
        color: white !important;
        text-decoration: none;
        text-align: center;
      }
    }
  }
}