.product-detail , .category{
    .App {
        height: auto;
        float: left;
        width: 100%;
        background-repeat: repeat;
    }
    .modal-body {
        iframe {
            min-height: 500px;
            width: 100%;
        }
    }
}

.product-detail-full-part {
    float: left;
    width: 100%;
    .product-slider-normal-part  {
        float: left;
        max-width: 1500px;
        @media @tablet,@laptopL,@small,@small-big {
            max-width: unset;
            width: 98%;
        }
        .product-detail-left-box {
            @media @tablet,@laptopL {
                max-width: unset;
                width: 60%;
                padding: 0;
                max-height: 390px;
            }
            @media @small,@small-big {
                width: 100%;
                padding: 0px;
                max-height: 300px;
            }
        }
        .product-slider-box{
            float: left;
            max-width: 100%;
            @media @tablet,@laptopL {
                max-width: unset;
                width: 100%;
            }
            @media @small,@small-big {
                max-width: unset;
                width: 100%;
            }
            .mySwiper2 {
                height: 500px;
                @media @tablet,@laptopL,@small,@small-big {
                    height: auto;
                }
                @media @small,@small-big {
                    overflow: visible;
                }
                img {
                    width: 100%;
                    border-radius: 20px;
                    height: 100%;
                    @media @tablet,@laptopL {
                        object-fit: contain;
                    }
                }
                
            }
            .mySwiper {
                .swiper-wrapper {
                    height: 130px;
                }
            }
            .swiper-button-next {
                right: 30px;
                @media @small {
                    display: none;
                }
                &:after {
                    content: url('../../images//Next.svg');
                    @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                        background-image: url('../../images//Next.svg');
                        background-size: 100% 100%;
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        content: "";
                    }
                }
            }
            .swiper-button-prev {
                left: 30px;
                @media @small {
                    display: none;
                }
                &:after {
                    content: url('../../images//prev.svg') ;
                    @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                        background-image: url('../../images//prev.svg');
                        background-size: 100% 100%;
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        content: "";
                    }
                 
                }
            }
            .swiper-pagination-fraction {
                bottom: -30px !important;
              
            }
            .swiper-pagination-bullet-active {
                background: @anarenk2;

            }
            .swiper-container-thumbs {
                margin: 20px 0;
                @media @tablet,@laptopL {
                    display: none;
                }
                .swiper-slide {
                    height: 120px;
                    img {
                        max-height: 120px;
                        border-radius: 10px;
                   
                        @media @laptop1366 { 
                            max-height: 100px;
                        }
                        @media only screen and (min-width: 992px) and (max-width: 1199px) {
                            max-height: 100px;
                        }
                    }
                }
            }
        }
        .product-detail-right-box {
            float: left;
            padding-left: 50px;
            @media @tablet,@laptopL {
                max-width: unset;
                width: 40%;
                padding-left: 20px;
            }
            @media @small,@small-big {
                width: 100%;
                padding: 20px;
            }
            .product-detail-desc-box{
                float: left;
                width: 100%;
                @media @small {
                    display: flex;
                    flex-direction: column-reverse;

                }
                h1 {
                    float: left;
                    width: 70%;
                    @media @small {
                        font-size: 18px;
                    }
                    @media @tablet,@laptopL {
                        font-size: 18px;

                    }
                    @media @laptop1280,@laptopL {
                        font-size: 24px;

                    }

                    @media @laptop1366 {
                        font-size: 24px;
                    }
                    @media only screen and (min-width: 992px) and (max-width: 1199px) {
                        font-size: 24px;
                    }
                }
                .product-detail-rating {
                    float: left;
                    width: 30%;
                    border-left: 2px solid gainsboro;
                    padding-left: 10px;
                    @media @small {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                    }
                    p {
                        float: left;
                        width: 100%;
                        margin-bottom: 0;
                        color: #999999;
                    }
                    > div {
                        float: left;
                        width: 100%;
                        color: #999999;
                        @media @small {
                            text-align: end;
                        }
                        .react-rater-star {
                            color: #FEB53E;
                        }
                        .reviews {
                            float: left;
                            width: 100%;
                            font-size: 12px;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .product-detail-price-and-save {
                float: left;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                  .numbers-row {
                    background: #F9F6F5;
                    width: 45%;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 10px;
                    box-shadow: 0 0 4px #42424252;
                    .dec {
                      float: left;
                      width: 30%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border: none;
                      border-right: 1px solid #f3f3f3;
                      height: 50px;
                      background: #F9F6F5;
                      border-radius: 10px 0 0 10px;
                      box-shadow: 0 0 4px #42424252;
                    }
                    .inc {
                      float: left;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 30%;
                      border: none;
                      border-left: 1px solid #f3f3f3;
                      height: 50px;
                      background: #F9F6F5;
                      border-radius: 0 10px 10px 0px;
                      box-shadow: 0 0 4px #42424252;
                    }
                    span {
                      color: #343132;
                      @media @small {
                        font-size:12px;
                    }
                    }
                    .input-are{
                      max-width:40%;
                      float: left;
                      height: 40px;
                      .productQuantityTextBox{
                        float: left;
                        width: 100%;
                        height: 26px;
                        background: #F9F6F5;
                        text-align: center;
                        border: none;
                      }
                      span {
                        float: left;
                        width: 100%;
                        text-align: center;
                      }
                    }
                  }
                  .product-price {
                    @media @small,@small-big {
                        text-align: center;
                    }
                    .eski-fiyat {
                      color: #D15A5E;
                      font-size: 20px;
                      margin-bottom: 0px;
                      text-decoration: line-through;
                    }
                    .fiyat {
                      color: @yesil;
                      font-size: 32px;
                      margin-bottom: 0;
                      @media @small,@small-big {
                          font-size: 26px;
                      }
                    }
                   
                  
                  }
                  .save-buton {
                      display: flex;
                      flex-direction: column;
                      background: none;
                      color: black;
                      border: none;
                      align-items: center;
                      img {
                          height: 42px;
                      }
                      span {
                          float: left;
                          width: 100%;
                      }
                  }
                 
            }
            .add-to-cart {
                width: 100%;
                float: left;
                margin: 20px 0;
                button {
                  background: @yesil;
                  border-color: @yesil;
                  height: 50px;
                  width: 100%;
                }
            }
            .campaign-box {
                background: #A0A0A0;
                display: flex;
                align-items: center;
                height: 65px;
                justify-content: center;
                border-radius: 10px;
                margin-top: 20px;
                padding: 20px;
                img {
                    padding-right: 20px;
                }
                p {
                    margin-bottom: 0;
                    height: auto;
                    font-size: 22px;
                    color: white;
                }
            }

            .product-detail-cargo-area {
                float: left;
                width: 100%;
                .custom-cargo {
                    float: left;
                    width: calc(50% - 20px);
                    display: flex;
                    align-items: center;
                    //background: red;
                    margin: 10px;
                    height: 50px;
                    @media @tablet,@laptopL {
                        width: 100%;
                        margin:0px;
                        span{font-size:12px;}
                    }
                    @media @small {
                        width: 100%;
                    }
                    img {
                        padding-right: 10px; 
                        @media @tablet,@laptopL {
                            width: 30px; 
                        }
                    }
                  
                }
            }
            .product-detail-sort-desc-box {
                float: left;
                margin-top: 20px;
                @media @small {
                    margin-top: 5px;
                    font-size: 15px;

                }
            }
        }
        .breadcrumb {
            z-index: 100;
            padding: 0 15px;
            ol {
                padding: 0;
                display: flex;
                @media @small,@small-big{
                    width: 100%;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow: scroll;
                    overflow-y: hidden;
                    border-radius: 10px 10px 0 0;
                }
                li {
                    list-style: none;
                    @media @small, @small-big {
                        min-width: fit-content;
                    }
                    &:first-child , &:nth-child(2){
                        &:after {
                            content: ' › ';
                            padding-right: 10px;
                        }
                    }

                }
            }
        }
        .lezzetler-card-box  {
            margin-top: 50px;
            @media @small {
                margin-top: 20px;
            }
            h2 {
                margin-bottom: 20px;
                font-size: 22px;
                padding-left: 10px;
                span {
                    text-transform: capitalize;
                }
                @media @tablet,@laptopL {
                    font-size: 16px;
                }
            }
            .swiper-container {
                padding: 10px;
            }
            .swiper-button-next {
                right: 60px;
            }
        }
        .product-detail-tabs {
            margin-top: 30px;
            background: #F7F7F7;
            position: relative; 
            display: inline-block;
            padding-bottom: 30px;

            @media @small {
                padding-top: 3px 
            }
            .tab-container {
                display: grid;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                grid-template-columns: repeat(6, 1fr);
                li {
                   
                }
              
                button {
                    padding: 10px 30px;
                    width: 100%;
                    @media @tablet,@laptopL {
                        padding: 10px 5px;

                    }
                    &.active {
                        background: #555F69;
                        color: white !important;
                        span {
                            @media @tablet,@laptopL {
                                font-size: 9px;
                                padding-top: 3px;;
                             }
                            span {
                                &:after {
                                    content: "";
                                    border: 20px solid white;
                                    border-width: 0 3px 3px 0;
                                    display: inline-block;
                                    padding: 3px;
                                    transform: rotate(-135deg);
                                    -webkit-transform: rotate(-135deg);
                                    position: relative;
                                    top: 12px;
                                }
                            }
                           
                        }
                        svg {
                            path {
                                fill: white !important;
                            }
                        }
                    }
                    span {
                        float: left;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        @media @tablet,@laptopL {
                            font-size: 9px;
                            padding-top: 3px;;
                         }
                        svg {
                            height: 32px;
                            @media @tablet,@laptopL {
                               height: 15px;
                            }
                        }
                       
                    }
                }
                
                
            }
            iframe {
                border-radius: 10px;
               
            }
            .accordion-header {
                svg {
                    height: 40px;
                    width: 40px;
                }
                button {
                    // flex-direction: column;
                    background: white;
                    align-items: flex-start;
                    justify-content: space-between;
                    max-width: 100%;
                    width: 100%;
                    flex-direction: row;
                    &:after {
                        flex-shrink: 0;
                        width: 2rem;
                        height: 2rem;
                        margin-left: auto;
                        content: '';
                        background-image: url('../../images//acordionIcon.svg');
                        background-repeat: no-repeat;
                        background-size: 2rem;
                        transition: transform 0.2s ease-in-out;
                       
                    } 
                    &:focus {
                        box-shadow: none;
                    }
                }
                .accordion-button:not(.collapsed)::after {
                    transform: rotate(90deg);
                }
                span {
                    span {
                        padding-left: 20px;
                    }
                }
            }
            .tab-content, .accordion-body {
                float: left;
                width: 100%;
                background: white;
                padding: 20px;
                
                .active{
                    display: inline-table;
    position: relative;
    width: 100%;
    text-align: left;
                }

                div.unconditionally{
                    @media @tablet,@laptopL {
                        width: 100%;
                        float: left;
                        position: relative;
                        display: inline-block;
                        div{
                            width: 100%;  
                            position: relative;
                            display: inline-block;}
                      }
                }
                .neden-sahin {
                    float: left;
                    width: 100%;
                    display: flex;
                    @media @small {
                        flex-direction: column;
                    }
                    .video-part {
                        float: left;
                        width: 50%;
                        padding: 10px;
                        @media @tablet,@laptopL {
                            width: 100%;
                          }
                        @media @small,@small-big {
                            width: 100%;
                            margin-bottom: 20px;
                        }
                        iframe {
                            width: 100%;
                            border-radius: 10px;
                        }
                    }
                    .aciklama-part  {
                        float: left;
                        width: 50%;
                        padding-left: 10px;
                        @media @small,@small-big {
                            width: 100%;
                        }
                    }
                }
                .customer-box {
                    float: left;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 0;
                    @media @small, @small-big {
                         display: block;
                    }
                    .customer-part {
                        margin-top: auto;
                        @media @small, @small-big {
                            float: left;
                            width: 33.33%;
                            height: auto;
                            min-height: 310px;
                        }
                        @media @small  {
                            width: 50%;
                            min-height: 250px;
                        }
                    }
                   
                    span {
                        float: left;
                        width: 100%;
                        font-weight: 600;
                        font-size: 40px;
                        span {
                            float: left;
                            width: auto;
                        }
                    }
                    label {
                        font-size: 22px;
                        font-weight: 400;
                    }
                    p {
                        color: #333333;
                    }
                }
                .comment-grid-part {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    height: 100%;
                    justify-content: center;
                    padding: 20px;
                    align-items: center;
                    flex-direction: column;
                    @media @small {
                        flex-direction: column;
                    }
                    .list-group {
                        height: 100%;
                        float: left;
                        width: 100%;
                        @media @small {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }
                    form {
                        float: left;
                        width: 50%;
                        background: white;
                        border-radius: 5px;
                        padding: 10px;
                        border: 1px solid gainsboro;
                        margin-left: 10px;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        @media @small {
                            width: 100%;
                            margin-left: 0;
                        }
                       label {
                           margin-left: 20px;
                       }
                        button {
                            margin-top: 10px;
                            background: @yesil;
                            border-color: @yesil;
                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                    svg {
                        height: 50px;
                        width: 100%;
                        path {
                            fill: @yesil;
                        }
                    }
                }
                .comment-desc {
                    span {
                        padding-left: 10px;
                    }
                }
                .comment-date {
                    display: flex;
                    justify-content: space-between;
                    color: rgb(141, 141, 141);
                    font-size: 14px;
                }
                .unconditionally {
                    display: flex;
                    > div {
                        padding-right: 10px;
                    }
                    iframe {
                        min-width: 600px;
                        border-radius: 10px;
                    }
                }
                .manti, .sucuk , .pastirma{
                    iframe {
                        width: calc(50% - 10px) !important;
                        margin-right: 10px;
                        float: left;
                        @media @small {
                            width: 100% !important;
                        }
                    }
                }
            }
            
        }
        h2 {
            margin-top: 10px;
        }
        .recipes-full-part {
            //margin: 30px 0;
            .recipes-normal-part {
                @media @big-definition-monitor {
                    max-width: 1500px !important;
                }
            }

        }
    }
}
.react-rater-star.is-active {
    color: #feb53e !important;
}

.react-rater-star.will-be-active{
    color: #feb53e !important;
}

.recipes-normal-part {
    @media @big-definition-monitor {
        max-width: 1500px !important;
    }
}