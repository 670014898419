.login-or-sginup, .reset-password {
    .App {
        background-image: none;
    }
}

.login-or-sginup-full-part {
    float: left;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    @media @small {
        padding: 5px;
    }
    .login-or-sginup-normal-part  {
        max-width: 1500px;
        display: flex;
        @media @small {
            max-width: unset;
            flex-direction: column;
            width: 100%;
        }
        @media @small,@small-big {
            flex-direction: column;
            width: 100%;
        }
        .login-tab-container {
            width: 100%;
            background: #ffffff;
            margin-bottom: 0 !important;
            display: flex;
            justify-content: space-between;
            position: relative;
            @media @small,@small-big,@tablet {
                width: 100%;
            }
            li {
                float: left;
                width: 50%;
                text-align: center;
                border-radius: 10px;
                &:first-child {
                    display: none;
                }
                &:last-child {
                    z-index: 9999;
                    font-size: 13px;
                    position: absolute;
                    bottom: -210px;
                    right: 0;
                    width: auto;
                    @media @small {
                        bottom: -190px;
                    }
                    button {
                        border: none;
                        box-shadow: none;
                        background: none;
                        text-decoration: underline;
                    }
                }
                button {
                    float: left;
                    width: 100%;
                    padding: 15px;
                    color: black;
                }

            }
        }
        .login-tab-container {
            width: 90% !important;
            margin:  10px auto;
            border-bottom: none;
            li {
               
                &:nth-child(2) {
                    background-color: #e5e5e5 !important;
                    margin: 5px 0 !important;
                    width: 50% !important;
                    border-radius: 8px 0 0 8px!important;
                    padding: 3px;
                }
                &:nth-child(3) {
                    background-color: #e5e5e5 !important;
                    margin: 5px 0 !important;
                    width: 50% !important;
                    border-radius: 0 8px 8px 0!important;
                    padding: 3px;
                }
              
                button {
                    background: none !important;
                    border-color: #e5e5e5 !important;
                    border-radius: 8px;
                    color: #444444 !important;
                    &.active {
                        background: white !important;
                        color: #444444 !important;
                    }
                }
            }
        }
        .tab-content {
            float: left;
            width: 95%;
            margin: 0 2.5%;
            padding: 20px;
            background: white;
            @media @small,@small-big,@tablet {
                width: 100%;
                padding: 10px;
            }
            .alert {
                display: flex;
                align-items: center;
                padding: 5px;
                p {
                    margin-bottom: 0;
                }
                button {
                    padding: 5px;
                    margin-top: 5px;

                }
            }
            form {
                .form-control {
                    height: 50px;
                }
                button {
                    background: @yesil;
                    border-color: @yesil;
                    height: 50px;
                    width: 100%;
                    margin-top: 50px;
                    
                  }
                  a {
                      margin: 10px 0;
                      float: right;
                  }
                  .form-check {
                      float: left;
                  }
                  .sginup-camps {
                      background: none;
                      width: auto;
                      float: left;
                      color: black;
                      display: block;
                      border: none;
                      padding: 0;
                      padding-left: 10px;
                      height: auto;
                      &:hover, &:focus, &:focus-visible {
                          border: none;

                      }
                  }
                  .policy-checkbox {
                      float: left;
                      width: 100%;
                      > div {
                          float: left;
                          width: auto;
                      }
                  }
                  .campaign-input {
                      display: flex;
                      input {    
                        border-radius: 10px !important;
                        width: 30px;
                        height: 30px;
                        background-color: white !important;
                        border: 1px solid #707070 !important;
                        &:focus {
                            box-shadow: none !important;
                        }
                        &:checked {
                            background-color: #F6F6F6 !important;
                            border: 1px solid #F6F6F6  !important;
                            box-shadow: none !important;
                            background-blend-mode: difference;
                        }
                    }
                      button {
                          margin-top: 0;
                          text-align: left;
                          @media @small {
                              text-align: left;
                          }
                      }
                  }
                
            }
            .forgot-password {
                .back-button {
                    border: none;
                    margin-bottom: 20px;
                    background: whitesmoke !important;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px !important;
                    min-width: auto !important;
                    height: 50px !important;
                    &:hover {
                        background: #ececec
                    }
                    &:focus {
                        box-shadow: none;
                        border: none;
                        background: none;
                    }
                }
                button {
                    margin-top: 10px;
                }
            }
            .social-medya-login {
                float: left;
                width: 100%;
                display: flex;
                  margin-left: calc(-1 * ((100% - 100%) / 2));
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  font-weight: 500;
                  font-size: 22px;
                  color: #B7B7B7;
                  letter-spacing: 0.09px;
                  font-size: 14px;
                  margin: 20px 0;
                  &:before {
                      content: "";
                      border-top: 1px solid #B7B7B7;
                      margin: 0 20px 0 0;
                      flex: 1 0 20px;
                  }
                  &:after {
                      content: "";
                      border-top: 1px solid #B7B7B7;
                      flex: 1 0 20px;
                      margin: 0 0 0 20px;
                  }
            }
        }
        .login-input-box {
            .email-input, .name-input, .password-input, .campaign-input , .surname-input{
                margin-bottom: 20px;
            }
            .password-input {
                
            }
        }
        .login-promotion-area {
            width: 50%;
            padding: 0 10px;
            @media @small,@small-big {
                width: 100%;
                margin-top: 20px;
            }
            h1 {
                font-size: 28px;
            }
            p {
                margin-bottom: 0;
            }
            iframe {
                height: 75%;
            }
        }
        .tab-container-part {
            width: 50%;
            background: white;
            padding: 30px;
            border-radius: 8px;
            @media @small,@small-big {
                width: 100%;
                padding: 0;
            }
        }
    }
 
}  
 .modal-dialog {
    max-width: 1300px !important;
}

.forgot-password {
    .login-or-sginup-normal-part {
        li {
            display: none;
           
        }
    }
}
.sginup {
    .login-or-sginup-normal-part {
        li {
            &:last-child {
                display: none;

            }
           
        }
    }
    .tab-content {
        button {
            margin-top: 0 !important;
        }
    }
}

.forgot-password-full-part { 
    float: left;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    .forgot-password-normal-part {
        max-width: 1500px;
        .forgot-password-grid-part {
            max-width: 500px;
            background: white;
            padding: 20px;
            h3 {
                margin-bottom: 20px;
            }
            form {
                input {
                    margin-bottom: 20px;
                }
                button {
                    background: @yesil;
                    border-color: @yesil;
                    box-shadow: none;
                    width: 100%;
                }
            }
        }
    }
}
.cantrat-modal {
    .modal-content {
        width: 98%;
    }
    .modal-body {
        @media @small,@small-big {
            max-height: 500px;   
            overflow: auto;
        }
    }
}

.login-failed-alert {
    button {
        background-color: unset;
        width: auto;
        height: auto;
        margin-top: 5px;
    }
}