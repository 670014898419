.cart {
    .App {
        background-image: none;
        height: 100vh;
    }
    .globalClass_5c43, jdiv {
        display: none !important;
    }
}
.cart-index-full-part {
    float: left;
    width: 100%;
    .cart-index-normal-part {
        max-width: 1500px;
        @media @big-definition-monitor {
            max-width: fit-content;
            width: 95%;
        }
        >.row {
            @media @small,@small-big,@tablet {
                flex-direction: column;

            }

        }
        .cart-index-left-part {
            @media @small, @small-big ,@tablet{
                width: 100%;
                margin-bottom: 20px;
            }
            .cart-product-list {
                display: flex;
                background: white;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                border-radius: 10px;
                margin-bottom: 10px;
                border: 1px solid gainsboro;
                @media @small ,@small-big{
                    display: block;
                    float: left;
                    margin-bottom: 10px;
                }
                @media @small {
                    position: relative;
                }
                .product-image-box {
                    @media @laptop1920 {
                        width: 20%;
                        flex-basis: 20%;
                        max-width: 20%;
                    }
                    img {
                        height: 120px;
                        border-radius: 10px;
                        @media @small,@small-big {
                           width: 30%;
                           float: left;
                           object-fit: contain;
                           height: auto;
                        }
                        @media @laptop1920 {
                            height: 100%;
                            border-radius: 10px;
                            max-height: 120px;
                            object-fit: cover;
                        }
                    }
                }
                .remove-button {
                    @media @small {
                        position: absolute;
                        top: 8px;
                        right: 0px;
                        img {
                            width: 14px;
                            object-fit: fill;
                        }
                    }
                   
                }
                .number-row-and-unit {
    
                    text-align: center;
                    @media @small,@small-big {
                        width: 70%;
                        float: left;
                    }
                   span {
                       &:first-child {
                           margin: 10px 0;
                           color: #a89f9f ;
                       }
                   }
                   .numbers-row {
                    background: #F9F6F5;
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 10px;
                    @media @small {
                        height: 42px;
                    }
                    .dec {
                      float: left;
                      width: 30%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border: none;
                      border-right: 1px solid #f3f3f3;
                      height: 50px;
                      background: #F9F6F5;
                      border-radius: 10px 0 0 10px;
                      &:focus {
                          box-shadow: none;
                      }
                      @media @small {
                        height: 42px;
                    }
                    }
                    .inc {
                      float: left;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 30%;
                      border: none;
                      border-left: 1px solid #f3f3f3;
                      height: 50px;
                      background: #F9F6F5;
                      border-radius: 0 10px 10px 0px;
                      &:focus {
                        box-shadow: none;
                    }
                      @media @small {
                        height: 42px;
                    }
                    }
                    span {
                      color: #343132;
                      font-size: 14px;
                    }
                    .input-are{
                      max-width:40%;
                      float: left;
                      height: 40px;
                      .productQuantityTextBox{
                        float: left;
                        width: 100%;
                        height: 26px;
                        background: #F9F6F5;
                        text-align: center;
                        border: none;
                      }
                      span {
                        float: left;
                        width: 100%;
                        text-align: center;
                      }
                    }
                  }
                }
    
                .product-price-box {
                    text-align: center;
                    @media @small ,@small-big{
                        width: 30%;
                        float: left;
                        min-height: 65px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                    }
                    @media @small {
                        min-height: 40px;
                    }
                    span {
                        float: left;
                        width: 100%;
                        font-size: 15px;
                        @media @small {
                            color: @yesil;
                            font-weight: 600;;
                        }
                        &:nth-child(2) {
                            color: @yesil;
                            font-weight: 600;
                            font-size: 18px;
                        }
                        // &:last-child {
                        //     color: #a89f9f;
                        //     font-size: 13px;
                        // }
                    }
                    .product-discount {
                        text-decoration: line-through;
                        font-size: 15px;
                    }
                }
                .product-desc-box {
                    width: 30%;
                    @media @small ,@small-big{
                        width: 70%;
                        float: left;
                        object-fit: contain;
                        height: auto;
                        min-height: 70px;
                        font-size: 14px;
                        padding-left: 10px;
                     }
                     @media @small-big {
                         min-height: 100px;
                     }
                     @media @small {
                         width: 55%;
                     }
                    span {
                        color: #a89f9f;
                        font-weight: 600;
                    }
                    h5 {
                        font-size: 1rem;
                        @media @small,@small-big {
                            font-size: 16px;
                        }
                    }
                }
                button {
                    background: none;
                    border: none;
                    @media @small,@small-big {
                        float: left;
                        width: 100%;
                        text-align: end;
                    }
                }
            }
            h1 {
                color: #a89f9f;
                font-size: 32px;
                font-weight: 400;
                @media @small {
                    font-size: 18px;
                }
            }
           
        }
        .cart-index-right-part {
            background: white;
            margin-left: 15px;
            width: calc(33.333% - 15px);
            display: flex;
            flex-direction: column;
            padding: 20px;
            height: 100%;
            border-radius: 10px;
            @media @small, @small-big ,@tablet{
                width: 100%;
                margin-bottom: 20px;
                margin-left: 0;
            }
            .order-product-price {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;
                overflow: hidden;
                border-bottom: 1px solid gainsboro;
                border-top: 1px solid gainsboro;
            }
            .order-shipping-price {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;
                overflow: hidden;
                border-bottom: 1px solid gainsboro;
                span {
                    span {
                        &:first-child {
                            color: #646464;
                        }
                     
                        &:last-child, .cargo-note {     
                            display: block;
                            font-size: 13px;
                        }
                        
                    }
                }
                .cargo-bedava {
                    padding-left: 10px;
                    color: @yesil;
                    font-weight: 500;
                }
                .cargo-note {
                    display: block;
                    font-size: 13px;
                    
                }
                .cargo-mesaj {
                    color: #e34541;
                    font-weight: 500;
                }
                .cargo-price {
                    width: 20%;
                    text-align: end;
                }
                
            }
            .kdv-total-price {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;
                overflow: hidden;
            }
            .fixed-area {
                display: flex;
                background: #f7f7f7;
                width: 100%;
                align-items: center;
                left: 0;
                padding: 0 10px;
                position: fixed;
                border: 1px solid gainsboro;
                justify-content: space-between;
                bottom: 0px;
                border-radius: 10px 10px 0 0;
                z-index: 9;
                a {
                    margin-top: 0;
                }
                span {
                    padding-right: 10px;
                    &:last-child {
                        color: @yesil;
                        font-family: 500;
                    }
                }
            }
            a {
                padding: 10px;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                border: 1px solid black;
                border-radius: 5px;
            }
            .order-chcekout-btn {
                background: @yesil;
                border-color: @yesil;
                color: white !important;
            }
            h2 {
                font-size: 26px;
                @media @small,@small-big {
                    font-size: 22px;
                }
            }
            .indirimler {
                span {
                    &:last-child {
                        color: @anarenk2;
                    }
                }
            }
        }
        .video-area {
            margin: 20px 0;
            display: flex;
            padding: 0;
            overflow: hidden;
            @media @small,@small-big {
                flex-direction: column;
            }
            @media @tablet {
                flex-direction: column-reverse;
            }
            iframe {
                border-radius: 8px;
                max-height: 500px;
                @media @small,@small-big {
                    width: 100%;
                    height: auto;
                }

                @media @tablet {
                    margin-bottom: 20px;
                }
                @media @laptopL {
                    max-height: 395px;

                }
            }
            img {
                margin-right: 10px;
                height: auto;
                border-radius: 8px;
                margin-top: 0 !important;
                @media @laptopL {
                    width: 60% !important;
                    object-fit: contain;
                }
            }
           
            img {
                margin-top: 20px;
                width: 100%;
            }
        }
        h1 {
            font-size: 26px;
            @media @small,@small-big {
                font-size: 22px;
            }
        }
    }
    .no-cart-index-normal-part{
        max-width: 1500px;
        width: 95%;
        height: 21.4vh;
        @media @big-definition-monitor {
            max-width: fit-content;
            width: 95%;
        }
        .no-cart-svg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            svg {
                height: 45px;
                path {
                    width: 100%;
                    fill: #e13f41;
                }
            }
        }
    }
}

.campaign-full-part {
    margin-bottom: 20px;
    @media @small {
        padding: 0 15px;
    }
    .campaign-normal-part  {
        max-width: 1500px;
        padding: 10px;
        width: 95%;
        margin: 20px auto;
        height: auto;
        min-height: 420px;
        @media @big-definition-monitor {
            max-width: fit-content;
            width: 95%;
        }
        .campaing-desc-part {
            display: flex;
            justify-content: space-between;
            max-width: 1500px;  
            margin: 40px 0;
            @media @small ,@small-big{
                flex-direction: column;
            }
            @media @big-definition-monitor {
                max-width: fit-content;
                width: 95%;
            }
            .campaing-message-part {
                color: #707070;
                p {
                    margin-bottom: 0;
                    &:first-child {
                        font-weight: 600;
                        font-size: 18   px;
                    }
                }

            }
            .campaing-total-price-part {
                background: @yesil;
                display: flex;
                align-items: center;
                padding: 0 20px;
                color: white;
                border-radius: 5px;
                font-weight: 600;
                @media @small,@small-big {
                    padding: 15px;
                    margin-top: 20px;
                }
            }
        }
        .campaign-mesaj {
            color: white;
            padding-left: 0px;
        }
        .swiper-container {
            width: 100%;
            .swiper-wrapper{
                height: 300px;
                @media @small {
                    height: 270px;
                }
            }
            .swiper-button-next,.swiper-button-prev,.swiper-pagination   {
                display: block;
            }
            .swiper-button-next:after, .swiper-button-prev:after{
                font-size: 24px !important;
            }
        }
        .free-product-cart {
           width: 100%;
           background: white;
           border-radius: 10px;
           min-height: 250px;
           @media @small {
               min-height: 250px;
               max-width: 270px;
           }
           img {
               max-height: 170px;
               width: 100%;
               border-radius: 10px 10px 0 0;
               object-fit: contain;
                border-bottom: 1px solid gainsboro;
                @media @small {
                    max-height: 190px;
                }
           }
           .free-product-name {
                display: flex;
                justify-content: space-between;
                margin: 5px 0;
                padding: 10px;
                align-items: center;
                @media @small {
                    flex-direction: column;
                    align-items: flex-start;
                }
                span {
                    &:last-child {
                        font-weight: 600;
                        min-width: 60px;
                        color: @yesil;
                    }
                }
           }
           .product-add-to-cart {
            display: flex;
            justify-content: flex-end; 
            width: 100%;
            margin: 10px auto;
            margin-top: auto;
            position: absolute;
            top: 10px;
            right: 10px;
            .add-to-cart {
          
              width: 50%;
              button {
                background: @yesil;
                border-color: @yesil;
                height: 50px;
                width: 100%;
              }
            }
            .numbers-row {
              background: #F9F6F5;
              width: 40px;
              display: flex;
              align-items: center;
              border-radius: 10px;
              flex-direction: column-reverse;
              height: auto;
              background: #5d3ebc;
              border-radius: 15px;
            
              .dec {
                float: left;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-right: 1px solid #f3f3f3;
                height: 35px;
                background: #F9F6F5;
                border-radius: 0px 0px 10px 10px;
              }
              .inc {
                float: left;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                border: none;
                border-left: 1px solid #f3f3f3;
                height: 35px;
                background: #F9F6F5;
                border-radius: 10px 10px 0px 0px;
              }
              span {
                color: white;
                padding: 5px;
              }
              .input-are{
                max-width:40%;
                float: left;
                height: 40px;
                .productQuantityTextBox{
                  float: left;
                  width: 100%;
                  height: 26px;
                  background: #F9F6F5;
                  text-align: center;
                  border: none;
                }
                span {
                  float: left;
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }
        }
        .add-to-cart {
            display: flex;
            justify-content: space-between; 
            align-items: center;
            .numbers-row {
                display: flex;
            }

            button {
                background: @yesil;
                border-color: @yesil;
            }
        }
    }
    .campaign-message-normal-part {
        max-width: 1500px;
        padding: 15px;
        background: gainsboro;
        display: flex;
        align-items: center;
        border-radius: 5px;
        @media @big-definition-monitor {
            max-width: fit-content;
            width: 95%;
        }
        .campaign-mesaj {
            margin-left: 20px;
            span {
                padding-left: 0px;
            }
        }
    }
}

.cart-index-header-full-part{
    .cart-index-header-normal-part {
        max-width: 1500px;
        @media @big-definition-monitor {
            max-width: fit-content;
            width: 95%;
        }
        a {
            @media @small {
                float: left;
                width: 100%;
                text-align: center;
            }
            img {
                height: 151px;
                padding: 10px;
                padding-left: 0;
                @media @small {
                    height: 100px;
                }
            }
        }
       
    }
    .cart-index-loading-normal-part {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }
}

.yilbasi-tatili {
    background: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    color:#e34541;
}

.campaing {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    @media @small {
        flex-direction: column;
        height: auto;
        > div {
            margin: 10px auto;
        }
    }
    @media @tablet,@small-big {
        flex-direction: column;
    }

    .free-product {
        flex-direction: row;
        display: flex;
        height: 220px;
       
        @media @small,@small-big {
            flex-direction: column;
            height: auto;
        }
    }
    .free-product-cart {
        background: red;
        width: 310px !important;
        border-radius: 0 !important;
        border: 1px solid gainsboro;
        &:first-child {
            border-right: none;
        }
    }
}