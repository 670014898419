
.contact-full-part {
    float: left;
    width: 100%;
    
    .contact-normal-part {
        max-width: 1500px;
        .tab-content {
            float: left;
            width: 100%;
            .accordion-item {
                margin: 20px 0;
                border: none;
                .accordion-header {
                    button {

                        border: 1px solid gainsboro;
                        border-radius: 10px;
                    }
                }
            }
        }
        .contact-left-box {
            background: white;
            border-radius: 10px;
            padding: 20px;
            margin-right: 10px;
            max-width: calc(83.3% - 10px);
        }
        .contact-tabs-box {
            margin-bottom: 0 !important;
            float: left;
            button {
                color: #9c9c9c;
                border: none;
               
            }
            button[aria-selected="true"] {
                color: black;
                border: none;
                &:focus{
                    box-shadow: none;
                }
                &:focus-visible {
                    box-shadow: none;
                }
            }
           
        }
        button[aria-expanded="true"] {
            background: #F6F6F6;
            border: none;
        }
        .contact-right-box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            background: #23BE72;
            color: white;
            border-radius: 10px;
            padding: 10px;
            max-height: 200px;
            a {
                color: white !important;
            }
            h1 {
                font-size: 24px;
            }
        }
    }
}


.static-page-full-part {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    .static-page-normal-part {
        max-width: 1500px;
        margin: 20px auto;

        .static-page-left-box {
            height: 100%;
        }
        .tab-content {
            float: left;
            width: 80%;
            background: white;
            display: flex;
            height: 100%;
            border-radius: 10px;
            border: 1px solid #DFDFDF;
            padding: 20px;
            .tab-pane {
                float: le;
                width: 100%;
            }
            h3 {
                font-size: 26px;
            }

        }
        .contact-tabs-box {
            margin-bottom: 0 !important;
            float: left;
            width: calc(20% - 20px);
            margin-right: 20px;
            height: 100%;
            border-radius: 10px;
            background: white;
            border: 1px solid #DFDFDF;
            flex-direction: column;
            li {
                float: left;
                width: 100%;
            }
            button {
                color: #9c9c9c;
                border: none;
                text-align: left;
                @media @small, @small-big {
                    // &:after {
                    //     content: '>';
                    //     padding-left: 10px;
                    // }
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 14px;
                    }
                    &:after {
                        background-image:url('../../images//acordionIconYesil.svg') ;
                        width: 36px;
                        height: 25px;
                        background-size: contain;
                        content: '';
                        background-repeat: no-repeat;
                        background-position: right;
                    }
                }
                
            }
            button[aria-selected="true"] {
                color: black;
                border: none;
             
                &:focus{
                    box-shadow: none;
                }
                &:focus-visible {
                    box-shadow: none;
                }
            }
        }
    }
}
.static-pages {
    background: #F7F7F7;
    .App {
        background-image: none;
    }
}