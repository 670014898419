* {
    box-sizing: border-box;
}
body {
    background-color: #F7F7F7 !important;
    font-family: Rothwood-Book !important;
    font-display: swap;
}
textarea.form-control{margin: 20px;}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border: 1px solid #EEEEEE;
}
.form-control, input,select {
  background-color: #F6F6F6 !important;
  border: 1px solid #EEEEEE !important;
  color: #666666 !important;
  font-size: 15px !important;
}

button {
    &:focus {
        box-shadow: none !important;
    }
}

a {
    color: black !important;
    text-decoration: none;
}

.App {
    background-image: url('../../images/background.webp');
    height: auto;
    float: left;
    width: 100%;
}
.index {
    .App {

        height: 110vh;
        background-repeat: no-repeat;
    }
    .product-box {
        min-height: fit-content;
    }
}
.carousel-control-next-icon {
    //background: url(../images/Next.svg) !important;
    width: 60px !important;
    height: 60px !important;
    background-repeat: no-repeat !important;
}
.carousel-control-prev-icon {
    //background: url(../images/Next.svg) !important;
    width: 60px !important;
    height: 60px !important;
    background-repeat: no-repeat !important;
}
.sr-only {
    display: none;
}
.carousel-caption {
    float: left;
    position:  relative !important;
}

.home-slider-container {
    display: flex;
    justify-content: flex-end;
    @media @small,@small-big {
        justify-content: center;
    }
    .home-slider-normal-part {
        max-width: 1700px;
        float: right;
        @media @laptop1920 {
            max-width: 1630px;
        }
        @media @laptop1366,@big-definition-monitor {
            max-width: 96vw;
            margin-top: 20px;
            padding: 10px;
        }
        @media @laptop1280,@laptopL {
            max-width: 96vw;
            padding: 10px;
        }
        @media @laptop1280 {
            max-width: 96vw;
            padding: 10px;
        }
        @media @tablet {
            max-width: 98vw;
            padding: 10px;
        }
        @media @small {
            max-width: 98vw;
            padding: 10px;
        }
        @media @small-big {
            max-width: 98vw;
            padding: 10px;
        }
        .swiper-button-next {
            right: 200px;
            @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                right: 100px;
                width: auto;
            }
            @media @small,@small-big {
                right: 5px;
                display: none;
            }
            &:after {
                content: url('../../images//Next.svg');
                @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                    background-image: url('../../images//Next.svg');
                    background-size: 100% 100%;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    content: "";
                }
            }
        }
        .swiper-button-prev {
            left: -70px;
            @media @laptop1366 {
                left: -45px;
            }
            @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                left: -50px;
                width: auto;
            }
            @media @tablet,@small,@small-big {
                left: -45px;
            }
            @media @small,@small-big{
                left: -10px;
                display: none;
            }
            &:after {
                content: url('../../images//prev.svg') ;
                @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                    background-image: url('../../images//prev.svg');
                    background-size: 100% 100%;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    content: "";
                }

            }
        }
        .swiper-pagination-bullet-active {
            background: @anarenk2;
        }
        .swiper-container {
            overflow: visible;
        }
        .swiper-pagination {
            bottom: -30px;
        }
    }
    a {
        &:hover {
            .hemen-al span {
                padding-right: 25px;
              }

              .hemen-al span:after {
                opacity: 1;
                right: 0;
              }
        }
    }
    .slider-image {
        float: right;
        width: 70%;
        text-align: end;
        @media @small,@small-big {
            width: 100%;
            margin: 0 auto;

        }
        img {
            max-width: 100%;
            border-radius: 40px 0 0 40px;
            @media @small,@small-big {
                border-radius: 20px;
            }
        }
    }
    .slider-description {
        float: left;
        width: 30%;
        padding-right: 12px;
        @media @small,@small-big {
            width: 100%;
            margin-top: 30px;

        }
        .slider-border {
            float: left;
            width: 100%;
            @media @small {
                margin-bottom: 10px;

            }
            span {
                width: 40px;
                height: 2px;
                background: red;
                float: left;

            }
        }
        p {
            font-family: PaulSlab-Regular;
            @media @tablet {
                font-size: 14px;
            }
            @media @small,@small-big {
                font-size: 14px;
            }
        }
        h2 {
            font-size: 50px;
            font-family: PaulSlab-bold;
            font-style: normal;
            text-transform: capitalize;
            @media @laptopL,@laptop1280 {
                font-size: 26px;
            }
            @media @tablet {
                font-size: 22px;

            }
            @media @small,@small-big {
                font-size: 18px;
            }
        }
        h3{
            font-family: Rothwood-Book;
        }
        .hemen-al {

            padding: 10px 50px;
            background: @anarenk2;
            border-color: @anarenk2;
            @media @laptopL,@laptop1280 {
                padding: 10px 20px;

            }
            @media @tablet {
                padding: 5px 10px;
            }
            @media @small,@small-big {
                background: @yesil;
                border: @yesil;
                border-radius: 10px;
                width: 95%;
            }
        }
        .hemen-al {
            transition: all 0.5s;
            cursor: pointer;
          }

          .hemen-al span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
          }

          .hemen-al span:after {
            content: '\00bb';
            position: absolute;
            opacity: 0;
            top: 0;
            right: -20px;
            transition: 0.5s;
          }

          .hemen-al:hover span {
            padding-right: 25px;
          }

          .hemen-al:hover span:after {
            opacity: 1;
            right: 0;
          }

    }




}
.slider-description {
    h3 {
        font-size: 12px;
    }
}
.home-product-normal-part.container {
    overflow: hidden;
    max-width: 1500px;
    margin: 40px auto;
    @media @big-definition-monitor {
        max-width: 96vw;
        margin-top: 20px;
        padding: 10px;
    }
    @media @laptop1366 {
        max-width: 1300px;
    }
    @media @laptopL {
        max-width: 980px;
    }
    @media @laptop1280 {
        max-width: initial;
        width: 95%;
    }
    @media @tablet {
        max-width: 95%;
    }
    @media @small, @small-big {
        max-width: 90%;
        margin: 40px auto;
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    h2 {
        font-family: Rothwood-Bold !important;
        padding-left: 10px;
    }
    p {
        padding-left: 10px;

    }
    .product-box {
        max-width: 345px;
        width: 100%;
    }
    .home-product-box {
        float: left;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 15px;
        padding: 0 8px 16px 8px;
        margin-top: 20px;
        @media @tablet ,@laptopL{
            grid-template-columns: repeat(3,1fr);
            grid-gap: 15px; 
        }
        @media @small-big {
            grid-template-columns: repeat(2,1fr);
            grid-gap: 15px; 
        }
        @media @big-definition-monitor {
            grid-template-columns: repeat(6,1fr);
            grid-gap: 15px; 
        }
        @media @small {
            grid-template-columns: repeat(1,1fr);
            grid-gap: 15px;
            padding: 0;
            margin-top: 5px;

        }
        .product-box  {
            width: 100%;
            max-width: fit-content;
            margin: 0;
        }

    }
}

.category-full-part {
    float: left;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .category-normal-part {
        padding: 0;
        overflow: hidden;
        max-width: 88vw;
        @media @laptop1920 {
             max-width: 92vw;
        }
        @media @big-definition-monitor {
            max-width: 92vw;
        }
        @media @laptopL {
            max-width: 94vw;
        }
        @media @laptop1280 {
            max-width: 94vw;
        }
        @media @tablet {
            max-width: 94vw;
        }
        @media @laptop1366 {
            max-width: 96vw;

        }
        @media @small,@small-big {
            max-width: 92vw;
        }
        a {
            float: left;
            width: 100%;
            transition: all .5s ease-in-out;
            border-radius: 20px;
            background: transparent;
            position: relative;
            &:hover {
              box-shadow: 0px 7px 20px 0px #b7b7b7;
              transform: translate(0, -10px);
              // transition: all .5s ease-in-out;
              // transition-delay: 0.5s !important;
            }
        }
        .category-desc {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 5px;
            color: white;
            text-align: center;
            border-radius: 0 0 20px 20px;
            // background-color: #00000029;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.78) 0%, rgba(0,212,255,0) 100%, rgba(9,9,121,0) 100%);
            @media @laptop1920 {
                bottom: 0;
           }
           @media @tablet {
                bottom: 0;
            }
            span {
                float: left;
                width: 100%;
                font-size: 12px;
                @media @tablet {
                    font-size: 8px;
                }
            }
            h4 {
                @media @tablet, @small,@small-big {
                    font-size: 14px;
                }
                @media @small,@small-big {
                    font-size: 14px;
                }
            }

        }
        img {
            width: 100%;
            min-height: 332px;
            border-radius: 20px;
            @media @laptop1920 {
                min-height: 260px;;
           }
           @media @laptopL, @laptop1280 {
               min-height: 190px;;
           }
           @media @laptop1366 {
               height: 332px;
           }
           @media @tablet {
            min-height: 218px;
            }
            @media @tablet {
                min-height: 160px;
            }
            @media @small,@small-big {
                min-height: 150px;
            }
        }
        .swiper-container {
           height: 440px;
           display: flex;
           @media @laptopL, @laptop1280 {
            height: 300px;
            }
            @media @tablet {
                height: 240px;
            }
            @media @small,@small-big {
                height: 280px;
            }
            @media @small {
                height: 320px;
            }
        }

        .swiper-button-prev  {
            top: 40px;
            right: 420px;
            left: auto;
            cursor: pointer;
            @media @tablet {
                right: 225px;
            }
            @media @small,@small-big {
                display: none;
            }
            @media @laptop1280 {
                top: 30px;
            }
        }
        .swiper-button-next  {
            top: 40px;
            right: 260px;
            cursor: pointer;
            @media @tablet {
                right: 50px;
            }
            @media @small,@small-big {
                display: none;
            }
            @media @laptop1280 {
                top: 30px;
            }
        }
        .swiper-button-next {
            &:after {
                content: url('../../images//categoryNext.svg');
            }
        }
        .swiper-button-prev {
            &:after {
                content: url('../../images//categoryPrev.svg');
            }
        }
        .swiper-pagination-bullet-active {
            background: @anarenk2;
        }
        .swiper-pagination {
            width: 120px;
            top: 25px;
            right: 295px;
            left: auto;
            height: 30px;
            @media @tablet {
                right: 90px;
            }
            @media @small,@small-big {
                display: none;
            }
            @media @laptop1280 {
                top: 15px;
            }
        }
        .swiper-slide {
            height: 335px;
            margin-top: auto;
            @media @laptopL, @laptop1280,@tablet,@small,@small-big {
                height: auto;
            }
        }
       .category-title {
           position: absolute;
           font-family: Rothwood-Book;

           @media @small {
               padding: 10px;
           }
           @media @laptopL,@laptop1280 {
               position: relative;
           }
       }
    }

}

.rebate-full-part {
    float: left;
    width: 100%;
    background-image: url('../../images/deneme.webp');
    margin: 50px 0;
    height: 250px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    @media @laptop1366 {
        margin: 0;
        background-size: contain;
        margin-bottom: 50px;
    }
    @media @laptop1366 {
        margin-top: 50px;
    }
    @media @small,@small-big {
        height: auto;
    }
    .rebate-normal-part {
        max-width: 1500px;
        @media @laptop1366 {
            max-width: 1300px;
        }
        @media @laptop1280 {
            max-width: 1100px;
        }
        @media @laptopL {
            max-width: 980px;
        }
        @media @tablet {
            max-width: 95%;
        }
        @media @small,@small-big {
            max-width: 95%;
        }
        @media @big-definition-monitor {
            max-width: 92vw;
        }
        .rebate-grid-part {
            >.row {
                @media @small,@small-big {

                justify-content: center;
                flex-direction: column-reverse;
                }
            }
            .rebate-video {
                z-index: 2;
                @media @laptopL,@laptop1280 {
                    width: 50%;
                }
                @media @laptop1280 {
                    display: flex;
                    justify-content: flex-end;
                }
                button {
                    background: none;
                    border: none;
                    &:focus {
                        box-shadow: none;
                    }
                }
                img {
                    max-width: 450px;
                   @media @tablet {
                       max-width: 360px;
                   }
                   @media @small,@small-big {
                       max-width: 100%;
                       position: relative;
                       bottom: 20px;
                   }
                }
            }
            .rebate-customer {
                // background-image: url('../../images/hepmutlu.png');
                @media @laptopL,@laptop1280 {
                    display: none;
                }
            }
            .rebate-desc {
                padding: 0;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media @laptop1366 {
                    max-width: 41%;
                }
                @media @laptopL,@laptop1280 ,@tablet, @small,@small-big{
                    padding: 10px;
                }
                h2 {
                    background: #b7081b;
                    color: white;
                    padding: 10px;
                    border-radius: 10px;
                    max-width: fit-content;
                    @media @tablet {
                        font-size: 18px;
                    }
                }
                p{
                    margin-bottom: 0;
                    @media @tablet {
                        font-size: 14px;
                    }

                }
                span {
                    font-weight: 500;
                    @media @tablet {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.recipes-full-part {
    float: left;
    width: 100%;
    padding: 20px;
    @media @small {
        padding-bottom: 0;
    }
    .recipes-normal-part {
        max-width: 1500px;
        @media @laptop1366 {
            max-width: 1300px;
        }
        @media @laptopL {
            max-width: 980px;
        }
        @media @laptop1280 {
            max-width: 1100px;
        }
        @media @tablet {
            max-width: 95%;
        }
        @media @small,@small-big {
            max-width: 100%;
        }

        @media @big-definition-monitor {
            max-width: 92vw;
        }
        .breadcrumb {
            ol {
                display: flex;
                padding: 0;
                @media @small,@small-big{
                    width: 100%;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow: scroll;
                    overflow-y: hidden;
                    border-radius: 10px 10px 0 0;
                }
                li {
                    list-style: none;
                    @media @small, @small-big {
                        min-width: fit-content;
                    }
                    &:first-child {
                        &:after {
                            content: ' › ';
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

    }
}
.recipes-grid-box {
    display: flex;
    justify-content: space-between;
    width: 1500px;
    margin: 0 auto;
    padding: 0 12px;
    @media @laptop1366 {
        max-width: 1300px;
    }
    @media @laptopL {
        max-width: 980px;
    }
    @media @laptop1280 {
        max-width: 1100px;
    }
    @media @tablet,@small,@small-big {
        max-width: 95%;
    }

    @media @big-definition-monitor {
        width: 92vw;
    }
    @media @small {
        flex-direction: column;
    }
    .recipes-title {

    }
    a {
        background: #E13F3F;
        color: white !important;
        padding: 14px;
        height: 50px;
        border-radius: 10px;
        text-decoration: none;
        @media @small,@small-big {
            display: flex;
            align-items: center;
            height: 85px;
        }
        @media @small {
            height: auto;
            text-align: center;
            display: block;
        }
    }
}
.cargo-area-full-part {
    float: left;
    width: 100%;
    margin-top: 50px;
    background: #E8E8E8;
    @media @small,@small-big {
        margin-top: 20px;
    }
    .cargo-area-normal-part {
        max-width: 1500px;
        padding: 20px;
        @media @laptop1366 {
            max-width: 1300px;
        }
        @media @laptopL {
            max-width: 980px;
        }
        @media @laptop1280 {
            max-width: 1100px;
        }
        @media @tablet {
            max-width: 700px;
        }
        @media @small,@small-big {
            max-width: 95%;
        }

        @media @big-definition-monitor {
            max-width: 92vw;
        }
        .cargo-grid-part{
            > .row {
                @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                }

            }
            .cargo-desc  {
                padding: 20px;
                @media @laptop1280,@laptopL,@tablet{
                   width: auto;
                }
                @media @small,@small-big {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
                img {
                    float: left;
                    height: 60px;
                    padding: 10px;

                }
                p {
                    margin-bottom: 0;
                }
            }
            .cargo-bank {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                @media @laptop1280,@laptopL,@tablet {
                    width: auto;
                 }
                 img {
                     max-height: 70px;
                    @media @small,@small-big {
                        width: 100%;
                        margin-top: 10px;
                    }
                 }
            }
        }
    }
}

.video-area-full-part {
    float: left;
    width: 100%;
    margin-top: 50px;
    @media @small {
        margin-top: 20px;
    }
    .video-area-normal-part {
        max-width: 1500px;
        @media @laptop1366 {
            max-width: 1300px;
        }
        @media @laptopL {
            max-width: 980px;
        }
        @media @laptop1280 {
            max-width: 1100px;
        }
        @media @tablet {
            max-width: 700px;
        }
        @media @small,@small-big {
            max-width: 95%;
        }

        @media @big-definition-monitor {
            max-width: 92vw;
        }
        .video-area-grid-part{
            .video-area-left-box {
                @media @small-big {
                    margin-bottom: 10px;
                }
                @media @small {
                    margin-bottom: 0;
                }
                img {
                    max-width: 100%;
                    border-radius: 20px;
                    transition: all 0.5s ease-in-out;
                    &:hover {
                        box-shadow: 6px 14px 32px 0 #b7b7b7;
                        transform: translate(0,-10px);
                        transition-delay: .2s!important;
                    }
                }
                button {
                    background: none;
                    border: none;
                    &:focus {
                        box-shadow: none;
                    }
                    @media @tablet,@laptopL {
                        padding: 0;
                    }
                }
                div {
                    border-radius: 20px;
                    &:first-child{
                        margin-bottom: 30px;
                        @media @small,@small-big ,@tablet , @laptopL{
                            margin-bottom: 10px;
                        }
                        @media @small {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .video-area-right-box {
                img {
                    max-width: 100%;
                    border-radius: 20px;
                    transition: all 0.5s ease-in-out;
                    &:hover {
                        box-shadow: 6px 14px 32px 0 #b7b7b7;
                        transform: translate(0,-10px);
                        transition-delay: .2s!important;
                    }
                }
                button {
                    background: none;
                    border: none;
                    @media @tablet,@laptopL {
                        padding: 0;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
                div {
                    border-radius: 20px;
                    margin-bottom: 30px;
                    @media @small,@small-big,@tablet,@laptopL{
                        margin-bottom: 10px;
                    }
                }
                a {
                    text-decoration: none;
                    color: white;
                   
                }
                .youtube-link-box {
                    margin-bottom: 0;
                    background: #313131;
                    display: flex;
                    justify-content: space-between;
                    height: 152px;
                    align-items: center;
                    color: white;
                    padding: 0 20px;
                    @media @tablet ,@laptopL{
                        padding: 0 10px;
                        height: 100px;
                    }
                    @media @small {
                        height: 100px;
                    }
                  
                    img {
                        border-radius: 0;
                        @media @tablet {
                            height: 30px;
                        }
                        @media @small-big {
                            height: 20%;
                        }
                        @media @small {
                            height: 15%;
                        }
                    }
                    span {
                        width: 230px;
                        font-size: 26px;
                        @media @tablet, @laptopL {
                            font-size: 18px;
                            padding-left: 20px;
                        }
                        @media @small-big {
                            font-size: 16px;
                            padding-left: 10px;
                        }
                        @media @small {
                            font-size: 13px;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.home-comment-full-part {
    float: left;
    width: 100%;
    background-image: url('../../images/sizdenGelenler.webp');
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
    @media @tablet {
        height: 300px;
    }
    @media @small {
        height: 250px;
    }
    .home-comment-normal-part{
        max-width: 800px;
       text-align: center;
       @media @tablet {
           max-width: 600px;
       }
       @media @small-big {
           max-width: 500px;
       }
       @media @small {
        max-width: fit-content;
        width: 95%;
        max-width: 320px;
       }
       .comment-box {
           width: 80%;
           float: left;
           @media @small-big {
               width: 100%;
               padding: 10px;
           }
           p,h6,span {
            float: left;
            width: 100%;
       }
        @media @tablet {
            float: left;
            width: 70%;
           p,h6,span {
                float: left;
                width: 100%;
           }
        }

       }
        .swiper-container {
            background-image: url('../../images/yorumSllider.webp');
            height: 320px;
            position: relative;
            top: 50%;
            box-shadow: 0 5px 10px 0 rgba(167 ,167, 167, 0.8);

            @media @tablet {
                height: 200px;
            }
            @media @small-big,@small {
                height: 230px;
            }
            @media @small {
                height: 310px;
                top: 10%;
            }
            .swiper-slide  {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .swiper-button-next {
                right: 20px;
                @media @small-big,@small {
                    display:none;
                }
                &:after {
                    content: url('../../images//categoryNext.svg');
                }
            }
            .swiper-button-prev {
                left: 20px;
                @media @small-big,@small {
                    display:none;
                }
                &:after {
                    content: url('../../images//categoryPrev.svg');
                }
            }
            .swiper-pagination-bullet-active {
                background: @anarenk2;
            }
        }
        h6 {
            float: left;
            width: 750px;
        }
        .comment-border{
            width: 500px;
            height: 2px;
            float: left;
            text-align: center;
            background: black;
        }
        p {
            float: left;
            width: 650px;
        }
    }
}

.home-discription-full-part {
    float: left;
    width: 100%;
    margin-top: 120px;
    @media @tablet {
        margin-top: 40px;
    }
    @media @small,@small-big {
       margin-top: 10px;
    }
    @media @small {
        margin-top: 100px;
        margin-bottom: 40px;
    }
    .home-discription-normal-part {
        max-width: 1500px;
        @media @laptop1366 {
            max-width: 1300px;
        }
        @media @laptopL {
            max-width: 980px;
        }
        @media @laptop1280 {
            max-width: 1100px;
        }
        @media @tablet,@small,@small-big {
            max-width: 95%;
        }

        @media @big-definition-monitor {
            max-width: 92vw;
        }
        .home-discription-grid-part {
            position: relative;
            top: 55px;
            @media @tablet {
                top: 30px;
            }
            @media @small,@small-big {
               top: 0;
             }
            >.row {
                justify-content: center;
               @media @small , @small-big{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    grid-gap: 10px;
               }
               @media @tablet,@laptopL {
                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                    grid-gap: 10px;
                }
            }
            .home-discription-box {
                background: white;
                margin-left: 15px;
                padding: 20px;
                border-radius: 10px;
                max-width: calc(25% - 15px);
                @media @tablet {
                    width: calc(50% - 15px);
                    max-width: calc(50% - 15px);
                    margin-bottom: 20px;
                }
                // @media @small-big {
                //     width: 95%;
                //     max-width: 95%;
                //     margin-bottom: 20px;
                //     margin-left: 0;
                // }
                @media @small, @small-big ,@tablet,@laptopL{
                    width: 100%;
                    max-width: unset;
                    padding: 5px;
                    margin: 0;
                    font-size: 14px;
                }

                // &:first-child {
                //     width: 25%;
                //     margin-left: 0;
                //     @media @tablet {
                //         width: 50%;
                //         max-width: 50%;

                //     }
                //     @media @small-big {
                //         width: 95%;
                //         max-width: 95%;
                //     }
                //     @media @small , @small-big{
                //         width: 100%;
                //         max-width: unset;
                //     }
                // }
                &:nth-child(3) {
                    // @media @tablet {
                    //     width: 50%;
                    //     max-width: 50%;
                    //     margin-left: 0;
                    // }
                }
                .home-discription-icon-box {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                    @media @small {
                        flex-direction: column-reverse;
                    }
                  img {
                    height: 50px;
                      @media @small {
                          height: 32px;
                      }
                  }

                  h6 {
                      color: #E13F41;
                  }
                }
                span {
                    float: left;
                    width: 100%;
                }
            }
        }

        .home-discription-seo-box  {
            margin: 10px 0;
            padding: 0;
            h1 {
                font-size: 20px;
            }
        }
    }
}

.footer-full-part {
    float: left;
    width: 100%;

    background-image: url('../../images/background.webp');
    .footer-normal-part {
        max-width: 1500px;
        margin-top: 120px;
        @media @laptop1366 {
            max-width: 1300px;
        }
        @media @laptopL {
            max-width: 980px;
        }
        @media @laptop1280 {
            max-width: 1100px;
        }
        @media @tablet , @small,@small-big{
            max-width: 95%;
        }
        @media @small-big,@small {
            max-width: 100%;
            margin-top: 0;
        }
        @media @tablet {
            margin-top: 50px;
        }

        @media @big-definition-monitor {
            max-width: 1500px;
        }
        .footer-category-box {
            padding: 0;
           @media @small-big,@small {
               display: none;
           }
            img {
                height: 130px;
            }
            ul {
                float: left;
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0;

                li {
                    float: left;
                    list-style: none;
                    @media @tablet {
                        font-size: 13px;
                    }
                    a {
                        text-decoration: none;
                    }

                }
            }
            p {
                float: left;
                width: 100%;
                margin-bottom: 0;
                @media @tablet {
                    font-size: 13px;
                }
                &:first-child {

                }
            }
        }
        .footer-link-box {
            display: flex;
            padding: 0;
            overflow: hidden;
            justify-content: space-between;
            @media @small,@small-big {
                display: none;
            }
            ul {
                max-width: 25%;
                padding: 0;
                overflow: hidden;
                margin-top: 40px;
                h5 {
                    color: #E23F3F;
                    @media @tablet {
                        font-size: 16px;
                    }
                }
                li {
                    list-style: none;
                    a{
                        text-decoration: none;
                    }
                    @media @tablet {
                        font-size: 13px;
                    }
                }
                span {
                    float: left;
                    width: 100%;
                    @media @tablet {
                        font-size: 13px;
                    }
                }
            }
            .footer-sociial-box {
                li {
                    margin-top: 20px;

                    float: left;
                    padding-right: 20px;

                    @media @tablet {
                        padding-right: 10px;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    img {
                        @media @tablet {
                            height: 30px;
                        }
                    }
                }

            }
            .social-icon {
                display: grid;
                grid-template-columns: repeat(4,1fr);
                grid-gap: 10px;
                @media @small {
                    margin-top: 10px;
                }
            }
        }
        .mobile-footer-grid-box {
            .accordion {
                background-color: initial;
                .accordion-item {
                    background: none;
                    border: none;
                    background-color: initial;

                    .accordion-button:not(.collapsed) {
                        color: black;
                        box-shadow: none;
                        background: none;
                        border-bottom: 1px solid gainsboro;
                    }

                    .accordion-button {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row-reverse;
                        &:after {
                            content: none;
                        }
                        &:before {
                            flex-shrink: 0;
                            width: 1.25rem;
                            height: 1.25rem;
                            margin-left: auto;
                            content: '';
                            background-image: url('../../images//acordionIcon.svg');
                            background-repeat: no-repeat;
                            background-size: 1.25rem;
                            transition: transform 0.2s ease-in-out;
                            background-size: 32px 32px;
                            width: 32px;
                            height: 32px;
                        }
                        &:focus {
                            box-shadow: none;
                        }
                    }
                    button[aria-expanded="true"] {

                        background-color: initial;
                        &:focus {
                            background-color: initial;
                             color: black;
                             background-color: initial;
                            }
                        &:focus-visible {
                            background-color: initial;
                        }
                        span {
                            color: black;
                        }
                      &::before {
                         -ms-transform: rotate(90deg); /* IE 9 */
                         transform: rotate(90deg);
                      }
                    }
                    button {
                        background-color: initial;
                    }
                    li {
                        list-style: none;
                        text-align: left;
                        line-height: 28px;
                        @media @small,@small-big {
                            line-height: 40px;
                        }
                    }
                    .accordion-body {
                        background-color: white;
                        border-radius: 0 0 20px 20px;
                        padding: 10px 0;
                         a{
                             text-decoration: none;
                         }
                    }
                    .accordion-header {
                        border-bottom: 1px solid gainsboro;

                    }

                }
            }
            .footer-sociial-box {
                padding: 10px;
                width: 100%;
                h5 {
                    font-size: 16px;
                }
                span {
                    font-size: 14px;
                }
                li {
                    margin-top: 10px;
                    list-style: none;
                    float: left;
                    span {
                        @media @small, @small-big {
                            float: left;
                            width: 100%;
                        }
                    }

                }
                .social-icon {
                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                    grid-gap: 0;
                }
            }
            p {
                float: left;
                width: 100%;
                margin-top: 20px;
                padding: 0 10px;
                margin-bottom: 0;
            }
        }
        .footer-mazaka {
            text-align: center;
            padding: 10px;
        }
    }
}

.site-loading {
    display: flex;
    justify-content: center;
    img {
        height: 36px;
    }
}
.mySwiper { 
    .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            margin-top: 10px !important;
        }
    } 
}
.lezzetler-card-box {
    float: left;
    width: 100%;
    .card {
        float: left;
        width: 100%;
        margin-right: 29px;
        box-shadow: 0 2px 15px 0 rgba(177, 177, 177);
        border-radius: 13px;
        margin-bottom: 15px;
        margin-left: 10px;
        height: 100%;
        max-height: 420px;
        @media @small,@small-big {
            width: 100%;
            margin-bottom: 10px;
            font-size: 15px;
            max-height: 380px;
        }
        @media @small-big {
            max-height: 480px;
        }
        @media @tablet {
            max-height: 320px;
        }
        &:last-child {
            margin-right: 0;
        }
        button {
            padding: 0;
            background: none;
            border: none;
            max-height: 330px;
            height: 295px;
            &:focus {
                box-shadow: none;
            }
            @media @laptop1366 {
                height: 300px;
                display: flex;
            }
            @media @laptopL,@laptop1280 {
                height: 210px;
            }
            @media @tablet {
                height: auto;
            }
            @media @small,@small-big {
                height: auto;
            }
            img {
                border-radius: 13px 13px 0 0;
                height: 100%;
                @media @small,@small-big,@tablet {
                    min-height: 162px;
                }
            }
        }
        button:focus:not(:focus-visible) {
            outline: 0;
            border: none !important;
        }

        .card-body {
            a {
                text-decoration: none;


                    @media @small,@small-big {
                        font-size: 16px;
                    }
                    @media @tablet {
                        font-size: 14px;
                    }
                .card-title {
                    font-size: 16px;
                    @media @tablet {
                        font-size: 16px;
                    }
                }
                .card-text {
                     overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    font-size: 14px;
                    height: 62px;
                    min-height: 62px;
                }
            }

        }
    }
    .mySwiper {
        // overflow: inherit;
        padding: 0;
        @media @small,@small-big {
            overflow: hidden;
        }
        .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                margin-top: 10px !important;
            }
        }
        .swiper-pagination-bullets {
            bottom: 0;
        }
        .swiper-wrapper  {
            padding: 20px 0;
        }
    }
    .swiper-slide {
        height: 100%;
    }
    .swiper-button-next {
        right: 60pxx;
        @media @small {
            display: none;
        }
        &:after {
            content: url('../../images//Next.svg');
            @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                background-image: url('../../images//Next.svg');
                background-size: 100% 100%;
                display: inline-block;
                width: 100%;
                height: 100%;
                content: "";

            }
            @media @laptop1280 {
                background-size: 26px 50px;
                background-repeat: no-repeat;
            }
        }
    }
    .swiper-button-prev {
        left: 60px;
        @media @small {
            display: none;
        }
        @media @tablet {
            left: -20px;
        }
        &:after {
            content: url('../../images//prev.svg') ;
            @media @laptop1280,@laptopL,@tablet,@small,@small-big {
                background-image: url('../../images//prev.svg');
                background-size: 100% 100%;
                display: inline-block;
                width: 100%;
                height: 100%;
                content: "";

            }
            @media @laptop1280 {
                background-size: 26px 50px;
                background-repeat: no-repeat;

            }

        }
    }
    .swiper-pagination-bullet-active {
        background: @anarenk2;
    }


}

.validation-error {
    padding: 5px;
    font-size: 13px;
    color: #dc3545;
}

.modal.show[aria-modal="true"] {
    background: #0000008a;
}
#popover-basic {
    background: white;
}
.delete-address-modal.show {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.album-img {
    float: left;
    width: 100%;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.tarifler-modal {
    iframe {
        min-height: 500px;
    }
}
.modal.show {
    z-index: 9999999;
}

.globalClass_5c43 , jdiv {
    bottom: 0;
    top: auto;
}